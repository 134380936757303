import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ISubject } from '../models/subject';

export default class SubjectStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable subjects: ISubject[] = [];

  @action loadSubjects = async() => {
      this.loading = true;
      try {
          var cs = await agent.Subjects.list();
          runInAction(() => {
              this.subjects = cs;
              this.loading = false;
          });
          return cs;
      } catch (error){
          runInAction(() => {
              this.loading = false;
          });
      }
  };
  
  @action newSubject = async(subject: ISubject) => {
    try {
      await agent.Subjects.createNewSubject(subject);
      if(this.subjects) {
        this.subjects.push(subject);
      }
      this.rootStore.modalStore.closeModal();         
    } catch(error){
      runInAction(() => {
        throw error;
      })    
    }
  }
}