import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Table, Col } from 'react-bootstrap'
import LessonResourceModal from './LessonResourceModal';

interface LessonParams {
  lessonId: string;
}
const initialValues = {
  courseName: '',
  courseCode: '',
  date: new Date()
};

const AdminLessonContentForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);  
  const {lessonContent, loadLessonContent} = rootStore.lessonStore;
  const { openModal} = rootStore.modalStore;    
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const loadValues = async() => {
      var lesson = await loadLessonContent(match.params.lessonId);
      if(lesson) {
        setValues({
          courseName: lesson.courseName,
          courseCode: lesson.courseCode,
          date: new Date(lesson.date)
        });
      }
    }
    if(match.params.lessonId){
      loadValues();
    }
  }, [loadLessonContent, match.params.lessonId])

  return (
    <Container className="content">
      <div style={{display: 'flex'}}>
      <h2 className= "mr-auto active-title" >Course: {values.courseName}</h2>
      <Link to={`/a-classes/${values.courseCode}`}>
          <Button >Back to Course</Button>
        </Link> 
      </div>
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Lesson #
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.seqNo}
          </h3>
        </Col>
      </Row> 
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Topic
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.topic}
          </h3>
        </Col>
      </Row> 
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Date
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
          {values.date?.toISOString().substr(0, 10)}
          </h3>
        </Col>
      </Row>
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Time Period
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.timePeriod}
          </h3>
        </Col>
      </Row>
      <Row>
 
      </Row>

      <div className='mt-3' style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color:'darkcyan'}}>Resources</h3>
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {lessonContent?.lessonResources?.map((lessonResource, index) =>
            <tr key={lessonResource.id}>
            <td>{index+1}</td>
            <td>
              {lessonResource.url && (
                <a href={lessonResource.url} target="_blank" rel="noopener noreferrer">{lessonResource.title}</a>
              )}
              {!lessonResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${lessonResource.title}`, <LessonResourceModal content={lessonResource.content}/>, 'modal-pane-1')}>
                  {lessonResource.title}
                </span>
              )}
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>          
    </Container>
  )
}

export default AdminLessonContentForm
