import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Card} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser'

const Announcements = () => {
  const rootStore = useContext(RootStoreContext);
  const {announcements, getActiveAnnouncements} = rootStore.announcementStore; 

  useEffect(() => {
    const loadValues = async() => {
      await getActiveAnnouncements();
    }
    loadValues();
  }, [getActiveAnnouncements])

  return (
    <div>
      {announcements.slice().sort((a, b) => a.date < b.date ? 1 : -1)
      .map((announcement, idx) => 
        <Card className="announcement-card" key={idx}>
          <Card.Header>
            <Card.Body>
              <Card.Title className="active-title">
                {announcement.title}            
              </Card.Title>
              {parse(announcement.description)}
            </Card.Body>
          </Card.Header>
        </Card>      
      )}
    </div>
  )
}

export default observer(Announcements)