import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import {CourseQuestionInfo, IQAInfo, QuestionInfo} from '../models/qa';

export default class QAStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable qaInfo: IQAInfo | null = null;
  @observable currentQuestion : QuestionInfo | null = null;
  @observable questions : CourseQuestionInfo[] = [];
  @observable taQuestions: CourseQuestionInfo[] = [];

  @action loadQATeacherInfo = async(courseCode: string) => {
    this.loading = true;
    try {
      var qi = await agent.QA.teacherInfo(courseCode);
      runInAction(() => {
        this.loading =false;
        this.qaInfo = qi;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }
  }

  @action loadQAStudentInfo = async(courseCode: string) => {
    this.loading = true;
    try {
      var qi = await agent.QA.studentInfo(courseCode);
      runInAction(() => {
        this.loading =false;
        this.qaInfo = qi;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }
  }  

  @action loadQuestion = async(questionId: string) => {
    this.loading = true;
    try {
      var qst = await agent.QA.questionInfo(questionId);
      runInAction(() => {
        this.loading =false;
        this.currentQuestion = qst;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }
  }

  @action addQuestion = async(courseCode: string, topic: string, isPrivate: boolean, message: string) => {
    this.loading = true;
    try {
      var question = await agent.QA.addQuestion(courseCode, topic, isPrivate, message);
      runInAction(() => {
        this.loading = false;
        if(this.qaInfo && this.qaInfo.courseCode === courseCode) {
          this.qaInfo.questions.push(question);
        }
        this.rootStore.modalStore.closeModal();
      })
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });     
    }
  }

  @action addAnswer = async(questionId: string, message: string) => {
    this.loading = true;
    try {
      var answer = await agent.QA.addAnswer(questionId, message);
      runInAction(() => {
        this.loading = false;
        if(this.currentQuestion && this.currentQuestion.questionId === questionId) {
            this.currentQuestion.answers.push(answer);
        }
        this.rootStore.modalStore.closeModal();        
      })
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });     
    }
  }

  @action updateAnswer = async(answerId: string, message: string) => {
    this.loading = true;
    try {
      await agent.QA.updateAnswer(answerId, message);
      runInAction(() => {
        this.loading = false;
        if(this.currentQuestion) {
            this.currentQuestion.answers = this.currentQuestion.answers.map(ans => 
              ans.answerId === answerId ? {...ans, message: message} : ans);
        }
        this.rootStore.modalStore.closeModal();        
      })
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });     
    }
  }

  @action getQuestions = async() => {
    this.loading = true;
    try {
      var qs = await agent.QA.getQuestions();
      runInAction(() => {
        this.questions = qs;
        this.loading =false;
      });  
      return qs;          
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }
  } 
  
  @action getTAQuestions = async() => {
    this.loading = true;
    try {
      var qs = await agent.QA.getTAQuestions();
      runInAction(() => {
        this.taQuestions = qs;
        this.loading =false;
      });  
      return qs;          
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }  
  }
}