import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetAnalysisUrlModal = ({assignmentId, analysisUrl}) => {
  const rootStore = useContext(RootStoreContext);
  const {setAnalysisUrl} = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;
  const [newAnalysisUrl, setNewAnalysisUrl] = useState(analysisUrl);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);  

  const handleAnalysisUrlChange = (event) => {
    const {value} = event.target;
    setNewAnalysisUrl(value);
    if(value !== analysisUrl) {
      setIsChanged(true);
    }
    else {
      setIsChanged(false);   
    }
  }; 

  const handleSubmit = (event) => {
    setSubmitting(true);
    setAnalysisUrl(assignmentId, newAnalysisUrl);
    setSubmitting(false);
    closeModal();
  }  

  return (
    <Container>
      <Form>              
        <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Analysis Url
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="url"
              value = {newAnalysisUrl}
              onChange={handleAnalysisUrlChange}
            />
          </Col>
        </Row>  
        <Row className="d-flex justify-content-center pb-3 mt-2">
        {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
        {submitting && <Button variant="secondary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"/>
            Submitting...</Button>}
        </Row>          
      </Form>
    </Container>       
  )
}

export default SetAnalysisUrlModal