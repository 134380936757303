import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetTopicItem = ({topicId, topicItemId, title, url}) => {
  const rootStore = useContext(RootStoreContext);
  const {setTopicItem} = rootStore.topicStore;
  const [newTitle, setNewTitle] = useState(title);
  const [newUrl, setNewUrl] = useState(url);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);  

  const handleTitleChange = (event) => {
    const {value} = event.target;
    setNewTitle(value);
    if(newTitle !== title || newUrl !== url) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }

  const handleUrlChange = (event) => {
    const {value} = event.target;
    setNewUrl(value);
    if(newTitle !== title || newUrl !== url) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    setTopicItem(topicId, topicItemId, newTitle, newUrl);
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Title
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="title"
                value = {newTitle}
                onChange={handleTitleChange}
              />
            </Col>
          </Row>  
          <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Url
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="url"
                value = {newUrl}
                onChange={handleUrlChange}
              />
            </Col>
          </Row>           
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetTopicItem
