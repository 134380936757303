import React, {useState, useContext} from 'react'
import { Container, Row,  Col, Form, Button, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const AddLesson = ({courseCode, timePeriod}) => {
  const currentDate = Date.now();
  const rootStore = useContext(RootStoreContext); 
  const {addCourseLesson} = rootStore.courseStore;
  const [topic, setTopic] = useState('');
  const [date, setDate] = useState(new Date(currentDate).toISOString().substr(0, 10));
  const [newTimePeriod, setNewTimePeriod] = useState(timePeriod);
  const [submitting, setSubmitting] = useState(false); 
  
  const handleDateChange = (event) => {
    const {value} = event.target;
    setDate(value);
  };
  const handleTimePeriodChange = (event) => {
    const {value} = event.target; 
    setNewTimePeriod(value);  
  }
  const handleTopicChange = (event) => {
    const {value} = event.target; 
    setTopic(value);  
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitting(true);
    addCourseLesson(courseCode, topic, date, newTimePeriod)
    .finally(() => {
      setSubmitting(false);
    })
  };

  return (
    <Container >
      <Form>
        <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Topic
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              name="topic"
              value = {topic}
              onChange={handleTopicChange}
            />
          </Col>
        </Row>        
        <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Date
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="date"
              name="Date"
              value = {date}
              onChange={handleDateChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Time Period
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              name="timePeriod"
              value = {newTimePeriod}
              onChange={handleTimePeriodChange}
            />
          </Col>
        </Row>   
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {!submitting && <Button variant="primary" onClick={handleSubmit}>Add</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
  
    </Container>
  )
}

export default AddLesson
