import React, { useContext, useState, useEffect } from 'react';
import {Container, Row, Col, Button, Form, Spinner} from 'react-bootstrap';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RouteComponentProps, Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  PythonProblemValues
} from '../../../app/models/pythonProblem';
import { observer } from 'mobx-react-lite';

interface DetailParams {
  id: string;
}

const PythonProblemForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    maxSeqNo,
    createPythonProblem,
    editPythonProblem,
    loadPythonProblem
  } = rootStore.pythonProblemStore;
  const [pythonProblem, setPythonProblem] = useState(new PythonProblemValues());
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState: any) => {
    setEditorState(editState);
  };

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadPythonProblem(match.params.id)
        .then(pythonProblem => {
          setPythonProblem(new PythonProblemValues(pythonProblem));
          const blocksFromHTML = htmlToDraft(pythonProblem!.description);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(state));
        })
        .finally(() => setLoading(false));
    }
  }, [loadPythonProblem, match.params.id]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setPythonProblem({ ...pythonProblem, [name]: value });
  };

  const handleFinalFormSubmit = (values: any) => {
    var currentContent = editorState.getCurrentContent();
    var markup = draftToHtml(convertToRaw(currentContent));
    if (pythonProblem.id) {
      var ePythonProblem = {
        id: pythonProblem.id!,
        seqNo: pythonProblem.seqNo!,
        title: values.title,
        description: markup,
        def: values.def,
        dateCreated: pythonProblem.dateCreated!,
        pythonTestCases: [],
        pythonUserAnswers: []
      };
      editPythonProblem(ePythonProblem);
    } else {
      // new python problem
      var newPythonProblem = {
        id: uuid(),
        seqNo: maxSeqNo + 1,
        title: values.title,
        description: markup,
        def: values.def,
        dateCreated: new Date(),
        pythonTestCases: [],
        pythonUserAnswers: []
      };
      createPythonProblem(newPythonProblem);
    }
  };

  return (
    <Container style={{ marginTop: '1rem' }}>
      <Row>
        <Col lg={10}>
          <h2>
            Problem: {pythonProblem ? pythonProblem.seqNo : 'NEW'}{' '}
          </h2>
        </Col>
        {pythonProblem.id && (
          <Col lg={2}>
            <Link to={`/pythontestcases/${pythonProblem.id}`}>              
              <Button variant="primary" className="float-right">
                Test Cases
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Col lg={12}>
        <Container>
              <Form>
                <Row className="form-field, pt-3">
                  <Form.Control required
                  type="text"
                  name="title"
                  value={pythonProblem.title}
                  onChange={handleChange}
                  placeholder="Title"/>
                </Row>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbar-class"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  onEditorStateChange={onEditorStateChange}
                />
                <Row className="form-field, pt-3">
                  <Form.Control required
                  type="text"
                  name="def"
                  value={pythonProblem.def}
                  onChange={handleChange}
                  placeholder="function def"/>
                </Row>                
                <Row className="d-flex justify-content-center pt-3 pb-3">
                {!loading && <Button variant="primary" onClick={handleFinalFormSubmit}>Submit</Button> }
                {loading && <Button variant="secondary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"/>
                    Submitting...</Button>}
                </Row>
              </Form>
        </Container>
      </Col>
    </Container>
  );
};

export default observer(PythonProblemForm);
