import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { IStudent } from '../models/student';

export default class StudentStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable students: IStudent[] = [];

  @action loadStudents = async() => {
      this.loading = true;
      try {
          var cs = await agent.Students.list();
          runInAction(() => {
              this.students = cs;
              this.loading = false;
          });
          return cs;
      } catch (error){
          runInAction(() => {
              this.loading = false;
          });
      }
  };
  
}