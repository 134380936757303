import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetTopicItemSeqNo = ({topicId, topicItemId, seqNo, seqNoLink}) => {
  const rootStore = useContext(RootStoreContext);
  const {setTopicItemSeqNo} = rootStore.topicStore;
  const [newSeqNo, setNewSeqNo] = useState(seqNo);
  const [newSeqNoLink, setNewSeqNoLink] = useState(seqNoLink);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);  

  const handleSeqNoChange = (event) => {
    const {value} = event.target;
    setNewSeqNo(value);
    if(newSeqNo !== seqNo || newSeqNoLink !== seqNoLink) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }
  const handleSeqNoLinkChange = (event) => {
    const {value} = event.target;
    setNewSeqNoLink(value);
    if(newSeqNo !== seqNo || newSeqNoLink !== seqNoLink) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    setTopicItemSeqNo(topicId, topicItemId, newSeqNo, newSeqNoLink);
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                SeqNo
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="seqNo"
                value = {newSeqNo}
                onChange={handleSeqNoChange}
              />
            </Col>
          </Row> 
          <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Url
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="url"
                value = {newSeqNoLink}
                onChange={handleSeqNoLinkChange}
              />
            </Col>
          </Row>            
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetTopicItemSeqNo
