import React, {useState, useEffect, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const initialValues = {
  title: '',
  deadline: new Date(Date.now() + 13*24*60*60*1000).toISOString().substring(0, 10),
  seqNo: 1,
  submitMode: 'Both'
};

const EditProblemForm = ({lessonId, problem}) => {
  const rootStore = useContext(RootStoreContext);
  const {addProblem, updateProblem} = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;
  const [values, setValues] = useState(initialValues); 
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };

  useEffect(() => {
    if(problem) {      
      setValues({
        title: problem.title,
        deadline: new Date(Date.now() + 7*24*60*60*1000).toISOString().substring(0, 10),
        seqNo: problem.seqNo,
        submitMode: problem.submitMode
      });
      const blocksFromHTML = htmlToDraft(problem.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));      
    }
  }, [problem]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    setSubmitting(true);
    if(values.title.length > 0 && currentContent && values.seqNo){
      var markup = draftToHtml(convertToRaw(currentContent));
      var url = '';      
      if(problem){
        updateProblem(problem.id, values.title, url, markup, values.seqNo.toString(), 
        values.deadline, values.submitMode)
        .finally(()=>{
          setSubmitting(false);
        });
        closeModal();          
      }
      else{
        addProblem(lessonId, values.title, url, markup, values.seqNo.toString(), 
        values.deadline, values.submitMode)
        .finally(()=>{
          setSubmitting(false);        
        });
        closeModal();          
      }
    }
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {values.title}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
               SeqNo
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="number"
                name="seqNo"
                value = {values.seqNo}
                onChange={handleChange}
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              deadline
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              required
              type="date"
              name="deadline"
              value = {values.deadline}
              onChange={handleChange}
            />
          </Col>
        </Row> 
        <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Submit Mode
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="submitMode" 
                value = {values.submitMode}
                onChange={handleChange}
                placeholder="City">
                <option>Code Only</option>
                <option>Attach Only</option>
                <option>Both</option>
              </Form.Control>
              </Col>
          </Row>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          values.title && editorState.getCurrentContent() && values.seqNo &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default EditProblemForm
