import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { IAssistant } from '../models/assistant';

export default class AssistantStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable assistants: IAssistant[] = [];

  @action loadAssistants = async() => {
    this.loading = true;
    try {    
      var cs = await agent.Assistants.list();
      runInAction(() => {
          this.assistants = cs;
          this.loading = false;
      });          
      return cs;
  } catch (error){
      runInAction(() => {
        this.loading = false;
      });
  }
  }
}