import React, {useContext, useEffect} from 'react'
import { Container, Form, Col, Row} from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";
import { RootStoreContext } from '../../app/stores/rootStore';

const StudentSubmissionsModal = ({problemId}) => {
  const rootStore = useContext(RootStoreContext);  
  const {studentSubmissions, loadStudentSubmissions, getProblem} = rootStore.assignmentStore;
  useEffect(() => {
    const loadValues = async() => {
      await loadStudentSubmissions(problemId);
      await getProblem(problemId);
    }
    loadValues();
  }, [loadStudentSubmissions, getProblem, problemId]);

  return (
    <Container>
      {studentSubmissions.map((assignment, index) => 
      <div key={index}>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              <h5>Student Name:</h5>
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              type="text"
              name="studentname"
              value = {assignment.studentName}
              readOnly
            />
          </Col>
        </Row>
        {assignment.studentRemark && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Student's Remark:
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <div className="student-remark">
            <p className="mt-2 ml-2">{assignment.studentRemark}</p>
          </div>
          </Col>
        </Row>
        )}
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Code 
              {assignment.grade === "Great" && <i className="fa fa-star fa-2x ml-3 mt-2 mb-2 text-primary"/>}           
              {assignment.grade === "Acceptable" && <i className="fa fa-check-circle fa-2x ml-3 mt-2 mb-2 text-info"/>}   
              {assignment.grade === "Problematic" && <i className="fa fa-bug fa-2x ml-3 mt-2 mb-2 text-warning"/>}
              {assignment.grade === "Unacceptable" && <span className="red-cross">X</span>}                
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Editor value={assignment.code}
              onValueChange = {() => {}}
              highlight={code => highlight(code, languages.js)}
              padding={5}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                backgroundColor: 'aliceblue'
              }}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label >
              Teacher's Remark:               
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <div className="teacher-remark">
            <p className="mt-2 ml-2">{assignment.teacherRemark}</p>
          </div>
          </Col>
        </Row>         
      </div>
        )}

    </Container>
  )
}

export default StudentSubmissionsModal
