import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table, Row, Button } from 'react-bootstrap'
import NewSubjectModal from './NewSubjectModal.jsx';

const SubjectsForm = () => {
  const rootStore = useContext(RootStoreContext);  
  const {subjects, loadSubjects } = rootStore.subjectStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    loadSubjects();
  }, [loadSubjects]);

  return (
    <Container fluid style={{ marginTop: '2em'}}>  
    <Row className="mb-2 ml-2">
        <Button variant="primary"
                onClick={() => openModal('New Subject', 
                <NewSubjectModal />, 'modal-pane-65')}
                >New Subject
        </Button>
    </Row>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Level</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {subjects &&
        subjects.sort((x, y) => x.code > y.code ? 1 : -1).map(subject =>(
          <tr key={subject.code}>
            <td>{subject.code}</td>
            <td>{subject.name}</td>
            <td>{subject.level}</td>
            <td>{subject.description}</td>
          </tr>
        ))
        }
      </tbody>
    </Table>    
    </Container>     
  )
}

export default observer(SubjectsForm)