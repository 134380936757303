import React, {useState, useEffect, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const initialValues = {
  title: '',
  date: new Date(Date.now()).toISOString().substring(0, 10),
  description: ''
};

const EditAnnouncementModal = ({announcement}) => {
  const rootStore = useContext(RootStoreContext);
  const {addAnnouncement, updateAnnouncement} = rootStore.announcementStore;
  const [values, setValues] = useState(initialValues);   
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };

  useEffect(() => {
    if(announcement) {
      setValues({
        title: announcement.title,
        date: (new Date(announcement.date)).toISOString().substring(0, 10),
        description: announcement.description
      });
      const blocksFromHTML = htmlToDraft(announcement.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));  
    }
  }, [announcement]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    setSubmitting(true);
    if(values.title.length>0 && currentContent) {
      var markup = draftToHtml(convertToRaw(currentContent));
      if(announcement) {
        updateAnnouncement(announcement.id, values.title, markup)
        .finally(()=>{
          // setSubmitting(false);
        });
      }
      else {
        addAnnouncement(values.title, values.date, markup)
        .finally(()=>{
          // setSubmitting(false);
        })
      }       
    }   
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {values.title}
              onChange={handleChange}
            />
          </Col>
        </Row>      
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              date
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              required
              type="date"
              name="date"
              value = {values.date}
              onChange={handleChange}
            />
          </Col>
        </Row> 
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          values.title && editorState.getCurrentContent() &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>         
      </Form>
    </Container>
  )
}

export default EditAnnouncementModal