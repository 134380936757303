import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const ClassInfo = ({courseInfo})  => {
  const convertLevel = (level) => {
    if(level === 1) return "Beginner";
    else if(level === 2) return "Intermediate";
    else if(level === 3) return "Advanced";
    else return "Challenging";
  };

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className={"ml-3 mr-auto text-primary"}>{courseInfo.name}</h2>
      </div>

      <Form className="mt-3">
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseInfo.subject.name} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseInfo.subject.code} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={convertLevel(courseInfo.subject.level)} readOnly>            
          </Form.Control>
          </Col>           
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Code
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              value = {courseInfo.courseCode}
              readOnly
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Session
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseInfo.session}
                readOnly
              />
              </Col>          
        </Row>         
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {new Date(courseInfo.startDate).toISOString().substring(0, 10)}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {new Date(courseInfo.endDate).toISOString().substring(0, 10)}
                readOnly
              />
              </Col>
          </Row>  
        <Row className="form-field">
            <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Day of the week
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseInfo.dayOfTheWeek || ''}
                readOnly
              />
              </Col> 
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseInfo.timePeriod || ''}
                readOnly
              />
              </Col>             
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseInfo.numOfLessons}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                type="text"
                value = {courseInfo.teacher.firstName + ' ' + courseInfo.teacher.lastName}
                readOnly
              />
              </Col>                                            
        </Row> 
        <Row>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Description
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={5} className="full-control" value={courseInfo.description} readOnly
              >                
              </Form.Control>
          </Col>   
        </Row>                                                                
      </Form>
    </div>
  );
}

export default ClassInfo