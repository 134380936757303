import { observable, computed, action, runInAction } from "mobx";
import { IUser, IUserFormValues, IUserProfile} from "../models/user";
import agent from "../api/agent";
import { RootStore } from './rootStore';
import { history } from "../..";
import { toast } from "react-toastify";
import { ICourseInfo, IParentCourseInfo } from "../models/course";

export default class UserStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
    }   
    @observable user: IUser | null = null;
    @observable fbLoading = false;
    @observable gLoading = false;

    @computed get isLoggedIn() {return !!this.user};

    @action login = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.login(values);
            runInAction('login', () => {
                this.user = user;
            });
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.modalStore.closeModal();
            history.push('/');
        } catch (error) {
            throw error;
        }
    };
    
    @action changePassword = async (oldPassword: string, password: string) => {
        try{
            const user = await agent.User.changePassword(oldPassword, password);
            runInAction('change pawword', () => {
                this.user = user;
            })
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.modalStore.closeModal();
            toast.info("password changed successfully");
            history.push('/');
        } catch (error) {
          this.rootStore.modalStore.setError("failed to change password"); 
        }
    };

    @action register = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.register(values);
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    };
    @action getUser = async () => {
        try {
            const user = await agent.User.current();
            runInAction(() => {
                this.user = user;
            });           
        } catch (error) {
            throw error;
        }
    };
    @action logout = () => {
        this.rootStore.commonStore.setToken(null);
        this.user = null;
        history.push('/');
    };

    @action fbLogin = async (response: any) => {
        this.fbLoading = true;
        try {
            const user = await agent.User.fbLogin(response.accessToken);
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.modalStore.closeModal();
            this.fbLoading = false; 
        } catch (error) {
            this.fbLoading = false;
            throw error;
        }
    };

    @action gLogin = async (response: any) => {
        this.gLoading = true;
        try {
            const user = await agent.User.gLogin(response.accessToken);
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.modalStore.closeModal();
            this.gLoading = false; 
        } catch (error) {
            this.gLoading = false;
            console.log(error);
        }
    }

    @action setUserProfile = async (userProfile: IUserProfile) => {
      try{
          const user = await agent.User.setProfile(userProfile);
          history.push(`/`);
          runInAction(() => {
            this.user = user;
          });
      } catch (error) {
          runInAction(() => {
            throw error;
          })
      }     
    } 
    @observable courses: ICourseInfo[] = [];

    @action loadStudentCourses = async() => {
      try {
          var cs = await agent.User.studentCourses();
          runInAction(() => {
              this.courses = cs;
          });
          return cs;
      } catch (error){
      }
    };

    @action loadTeacherCourses = async() => {
      try {
          var cs = await agent.User.teacherCourses();
          runInAction(() => {
              this.courses = cs;
          });
          return cs;
      } catch (error){
      }
    };   

    @action loadAssistantCourses = async() => {
      try {
          var cs = await agent.User.assistantCourses();
          runInAction(() => {
              this.courses = cs;
          });
          return cs;
      } catch (error){
      }
    }; 

    @observable parentCourses: IParentCourseInfo[] = [];

    @action loadParentCourses = async() => {
      try {
          var cs = await agent.User.parentCourses();
          runInAction(() => {
              this.parentCourses = cs;
          });
          return cs;
      } catch (error){
      }
    };        
}