import React from "react";
import { ListGroup, Row, Col, Image, Button} from "react-bootstrap";
import { IntermediateClassTree } from "./intermediate/IntermediateClassTree";

const RegularIntermediate = () => {
    return (        
        <ListGroup variant="flush" className='courseClass'>

            {IntermediateClassTree.map((classList) =>
            <ListGroup.Item key={classList.courseId}>
            <Row>
                <Col md={4}>
                    <Image src={classList.image} className='courseIcon' fluid ></Image>                 
                </Col>

                <Col md={8}>
                    <h4> {classList.courseName} </h4>
                    <div>
                        <i> {classList.Recommendation}</i> 
                        <br/><br/>
                        {classList.Preview}
                    </div>   
                    <div className='courseButton'>
                        <br/>   
                        <Button href='/contact' > Inquiry </Button>
                    </div>                                   
                </Col> 
            </Row>        
            </ListGroup.Item>
            )}                                
        </ListGroup>    
    )
}

export default RegularIntermediate