import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import {
  PythonTestCase,
  PythonProblemValues
} from '../../app/models/pythonProblem';
import {Container, Button, Card, Row, Col, Form} from 'react-bootstrap';
import Parser from 'html-react-parser';
import uuid from 'uuid';

interface DetailParams {
  probId: string;
  caseId: string;
}

export const PythonTestCaseForm: React.FC<RouteComponentProps<
  DetailParams
>> = ({ match, history }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadPythonProblem,
    editPythonTestCase,
    createPythonTestCase
  } = rootStore.pythonProblemStore;
  const [pythonProblem, setPythonProblem] = useState(new PythonProblemValues());
  const [testCase, setTestCase] = useState(new PythonTestCase());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.probId) {
      setLoading(true);
      loadPythonProblem(match.params.probId)
        .then(problem => {
          setPythonProblem(new PythonProblemValues(problem));
          if (match.params.caseId) {
            let oneCase = problem!.pythonTestCases.find(
              x => x.id === match.params.caseId
            );
            if (oneCase) {
              setTestCase(oneCase);
            }
          }
        })
        .finally(() => setLoading(false));
    }
  }, [loadPythonProblem, match.params.probId, match.params.caseId]);

  const handleFinalFormSubmit = (values: any) => {
    if (testCase.id) {
      // edit
      var aTestCase = {
        id: testCase.id!,
        input: values.input,
        output: values.output,
        testCode: values.testCode,
        resultType: values.resultType,
        pythonProblemId: pythonProblem.id!
      };
      editPythonTestCase(aTestCase);
    } else {
      var newTestCase = {
        id: uuid(),
        input: values.input,
        output: values.output,
        testCode: values.testCode,
        resultType: values.resultType,
        pythonProblemId: pythonProblem.id!
      };
      createPythonTestCase(newTestCase);
    }
  };

  return (
    <Container style={{ marginTop: '1rem' }}>
      <Card>
        <Card.Header>Problem # {pythonProblem?.seqNo}</Card.Header>
          <Row>
            <Col lg={12}>
              <h3>{pythonProblem?.title}</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {pythonProblem &&
                pythonProblem.description &&
                Parser(pythonProblem!.description)}
            </Col>
          </Row>
      </Card>

      <Card>
        {testCase.id ? (
          <Card.Header>Test case</Card.Header>
        ) : (
          <Card.Header>New test case</Card.Header>
        )}
            <Form>
              <Form.Control type="text" name="input" placeholder="Input" value={testCase.input}/>
              <Form.Control type="text" name="output" placeholder="Output" value={testCase.output}/>
              <Form.Control as="textarea" name="testcode" placeholder="Test Code" value = {testCase.testCode}
                 rows = {3}/>
              <Form.Label>Result Type</Form.Label>                 
              <Form.Control as="select" name="resultType">
                <option value="String">String</option>
                <option value="Int">Int</option>
                <option value="Float">Float</option>
                <option value="Boolean">Boolean</option>
                <option value="ListOfString">List of String</option>
                <option value="ListOfInt">List of Int</option>
              </Form.Control>
              {!loading && 
              <Button type="submit" className="float-right" onSubmit={handleFinalFormSubmit}>Submit</Button>
              }
            </Form>
      </Card>
    </Container>
  );
};
