import React, { useContext, useState, useEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Modal} from 'react-bootstrap';

const ModalContainer = () => {
  const rootStore = useContext(RootStoreContext);
  const { 
    modal: { open, title, body, modal_class, error},
    closeModal
  } = rootStore.modalStore;
  
  const [show, setShow] = useState(false);
  const handleClose = () => {
    closeModal();
  }

  useEffect(() => {
    setShow(open);
  }, [open]);
  
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName={modal_class}>
      <Modal.Header closeButton>
        <Modal.Title text-align="center">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {body}
      </Modal.Body>
      {error && (
        <Modal.Footer className="text-danger">
        {error}
        </Modal.Footer>
      )}      
    </Modal>
  )
};

export default observer(ModalContainer);
