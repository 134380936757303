import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import { Director, InstructorsLeft } from "./StaffListTree";
import "./staff.css";
export const Staff = ()=> {
    return (
        <div className='staff-page'>
        
            <div className='staff-body'>
            <Row>
                <Col md={7}>
                    {Director.map ((june) =>                                 
                    <div className='staffBody-june' key="june">                              
                        <div className='center'>
                            <Container>
                            <p>
                                <Image src={june.image} className='staffIcon-big' rounded/>
                            </p>
                                <h3> {june.staffName} </h3>
                                <h5><i> {june.staffTitle} </i></h5>  
                            <p className="description">
                                {june.description}                          
                            </p>
                            </Container>
                        </div>
                    </div>)} 
                </Col>

                <Col md={5}>
                    <div className='staff-heading'>
                        <h1> ABAITC Staff</h1>
                        <div className='black-line'/>
                        <br/>
                        <h2> Proud to display the talented<br/>
                            teaching staff who direct <br/>
                            and curate each<br/>
                            course.
                        </h2>
                        
                    </div>
                </Col>
            </Row>
            </div>        
            
            <div className='staffBreak'/>
            
            <div className='generalBody'>              
                <Row > 
                    {InstructorsLeft.map((staffList) => (             
                    <Col md={6} key={staffList.staffId}>
                        <Container>
                        <p>
                            <Image src={staffList.image} className='staffIcon-big' rounded/>
                        </p>
                            <h3> {staffList.staffName} </h3>
                            <h5><i> {staffList.staffTitle} </i></h5>  
                        <p className="description">                            
                            {staffList.description}                          
                        </p>
                        </Container>
                    </Col>
                    ))}

                </Row>
            </div>
            
        </div>
    )
}

