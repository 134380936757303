import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Table, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import AssignmentProblemModal from './AssignmentProblemModal';
import moment from 'moment';
import StudentSubmissionsModal from '../lesson/StudentSubmissionsModal';
import AssignmentResourceModal from './AssignmentResourceModal';

interface LessonParams {
  lessonId: string;
}

const AssistantAssignmentForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {assignmentContent, loadAssignmentContent } = rootStore.assignmentStore;
  const { openModal} = rootStore.modalStore;
   
  useEffect(() => {
    const loadValues = async() => {
      await loadAssignmentContent(match.params.lessonId);   
    }
    if(match.params.lessonId){
      loadValues();
    }
  }, [loadAssignmentContent, match.params.lessonId]);


  return (
    <Container className="mt-3">
      <Row>
        <h3 className="mr-auto"><span style={{color: 'red'}}>Assignment {assignmentContent?.seqNo} </span> of Course: {assignmentContent?.courseName}</h3>
        <Link to={`/h-classes/${assignmentContent?.courseCode}`}>
          <Button >Back to Course</Button>
        </Link>  
      </Row>
      <Row>
        <h4 className="text-info">Date: {assignmentContent && (new Date(assignmentContent.date)).toISOString().substr(0, 10)}</h4>
      </Row>
      <Row>
        <h4 className="text-info mr-auto">TimePeriod: {assignmentContent?.timePeriod}</h4>    
      </Row>  

      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Deadline</th>
            <th>Submission</th>
          </tr>
        </thead>
        <tbody>
          {assignmentContent?.assignments?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map(assignment =>
          <tr key={assignment.id}>
            <td>{assignment.seqNo}</td>
            <td>
              {assignment.url && (
                <a href={assignment.url} target="_blank" rel="noopener noreferrer">{assignment.title}</a>     
              )}
              {!assignment.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`problem ${assignment.title}`, <AssignmentProblemModal title={assignment.title} description={assignment.description}/>, 'modal-pane-1')}>
                  {assignment.title}    
                </span>                        
              )}
            </td>
            <td>
              {moment(assignment.deadline).format('YYYY-MM-DD')}
            </td>
            <td>
              {assignment.numOfStudentAssignments > 0 && (
                <span className="clickable text-primary"
                onClick={() => openModal(`student submissions of ${assignment.title}`, <StudentSubmissionsModal problemId={assignment.id}/>, 'modal-pane-1')}>
                  {assignment.numOfStudentAssignments} submissions
                </span>
              )} 
            </td>
          </tr>
            )}
        </tbody>
      </Table> 
      <div className='mt-5' style={{display: 'flex'}}>
        <h4 className='mr-auto' style={{color:'darkcyan'}}>Resources</h4>
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {assignmentContent?.resources?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map(assignmentResource =>
            <tr key={assignmentResource.id}>
            <td>{assignmentResource.seqNo}          
            </td>
            <td>
              {assignmentResource.url && (
                <a href={assignmentResource.url} target="_blank" rel="noopener noreferrer">{assignmentResource.title}</a>
              )}
              {!assignmentResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${assignmentResource.title}`, <AssignmentResourceModal content={assignmentResource.content}/>, 'modal-pane-1')}>
                  {assignmentResource.title}
                </span>
              )}
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>                  
    </Container>
  )
}

export default AssistantAssignmentForm
