import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetAssignmentDeadline = ({assignmentId, deadline}) => {
  const rootStore = useContext(RootStoreContext);
  const {setDeadline} = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;
  const [newDeadline, setNewDeadline] = useState(new Date(deadline).toISOString().substring(0, 10));
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);  

  const handleDeadlineChange = (event) => {
    const {value} = event.target;
    setNewDeadline(value);
    if(value && value !== deadline) {
      setIsChanged(true);
    }
    else {
      setIsChanged(false);   
    }
  }; 

  const handleSubmit = (event) => {
    setSubmitting(true);
    setDeadline(assignmentId, newDeadline);
    setSubmitting(false);
    closeModal();
  }  

  return (
    <Container>
      <Form>              
        <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Deadline
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="date"
              name="Deadline"
              value = {newDeadline}
              onChange={handleDeadlineChange}
            />
          </Col>
        </Row>  
        <Row className="d-flex justify-content-center pb-3 mt-2">
        {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
        {submitting && <Button variant="secondary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"/>
            Submitting...</Button>}
        </Row>          
      </Form>
    </Container>       
  )
}

export default SetAssignmentDeadline