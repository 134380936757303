import React, {useState, useContext, useEffect} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetTopicModal = ({topicId, name, 
  hasConcepts, hasAnalysis, hasCode, isSeparatePage, isWorkbook, submitMode}) => {
  const rootStore = useContext(RootStoreContext);
  const {setTopic} = rootStore.topicStore;
  const [newName, setNewName] = useState(name);
  const [newHasConcepts, setNewHasConcepts] = useState(hasConcepts);
  const [newHasAnalysis, setNewHasAnalysis] = useState(hasAnalysis);
  const [newHasCode, setNewHasCode] = useState(hasCode);
  const [newIsSeparatePage, setNewIsSeparatePage] = useState(isSeparatePage);   
  const [newIsWorkbook, setNewIsWorkbook] = useState(isWorkbook);  
  const [newSubmitMode, setNewSubmitMode] = useState(submitMode);   
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if(newName !== name || newHasConcepts !== hasConcepts
      || newHasAnalysis !== hasAnalysis || newHasCode !== hasCode
      || newIsSeparatePage !== isSeparatePage || newIsWorkbook !== isWorkbook
      || newSubmitMode !== submitMode) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }, [name, hasConcepts, hasAnalysis, hasCode, isSeparatePage, isWorkbook, submitMode,
    newName, newHasConcepts, newHasAnalysis, newHasCode, newIsSeparatePage, 
    newIsWorkbook, newSubmitMode]);
  
  const handleNameChange = (event) => {
    const {value} = event.target;
    setNewName(value);
  }

  const handleHasConceptsChange = (event) => {
    const {checked} = event.target;
    setNewHasConcepts(checked);
  }

  const handleHasAnalysisChange = (event) => {
    const {checked} = event.target;
    setNewHasAnalysis(checked);
  }

  const handleHasCodeChange = (event) => {
    const {checked} = event.target;
    setNewHasCode(checked);
  }

  const handleIsSeparatePageChange = (event) => {
    const {checked} = event.target;
    setNewIsSeparatePage(checked);
  }

  const handleIsWorkbookChange = (event) => {
    const {checked} = event.target;   
    setNewIsWorkbook(checked);
  }

  const handleSubmitModeChange = (event) => {
    const {value} = event.target;   
    setNewSubmitMode(value);
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    setTopic(topicId, newName, newHasConcepts, newHasAnalysis, newHasCode, 
      newIsSeparatePage, newIsWorkbook, newSubmitMode);
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Topic Name
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="topicName"
                value = {newName}
                onChange={handleNameChange}
              />
            </Col>
          </Row>
          <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Concepts?" checked={newHasConcepts} 
                name="hasConcepts" onChange={handleHasConceptsChange}/>                
              </Col>              
        </Row>   
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Analysis" checked={newHasAnalysis} 
                name="hasAnalysis" onChange={handleHasAnalysisChange}/>                
              </Col>              
        </Row> 
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Code" checked={newHasCode} 
                name="hasCode" onChange={handleHasCodeChange}/>
              </Col>              
        </Row>
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Is Separate Page" checked={newIsSeparatePage} 
                name="isSeparatePage" onChange={handleIsSeparatePageChange}/>
              </Col>              
        </Row>      
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Is Workbook" checked={newIsWorkbook} 
                name="isWorkbook" onChange={handleIsWorkbookChange}/>
              </Col>              
        </Row>                                        
        <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Submit Mode
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="submitMode" 
                value = {submitMode}
                onChange={handleSubmitModeChange}
                placeholder="City">
                <option>Code Only</option>
                <option>Attach Only</option>
                <option>Both</option>
              </Form.Control>
              </Col>
          </Row> 
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetTopicModal
