import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Card} from 'react-bootstrap';

const initialValues = [];

const CheckChildModal = ({userName}) => {
  const rootStore = useContext(RootStoreContext);  
  const {getChildCourses} = rootStore.usersStore; 
  const [takenCourses, setTakenCourses] = useState(initialValues); 
  
  useEffect(() => {
    const loadValues = async() => {
      var courses = await getChildCourses(userName);
      if(courses) {
        setTakenCourses(courses);
      }
    }
    if(userName) {
      loadValues();
    }
  }, [getChildCourses, userName]);

  return (
    <Container>
      {takenCourses.slice().sort((a, b) => {
        if(a.startDate > b.startDate) return -1;
        else return 1;
      })
      .map((course, index) => 
        <Card key={index}>
          <Card.Body>
            <Card.Title className={course.active === true ? 'text-primary': 'text-secondary'}>
              student: {course.studentFullName}
            </Card.Title>
            <Card.Title className={course.active === true ? 'text-primary': 'text-secondary'}>
              course: {course.courseName}
            </Card.Title>            
            <Card.Subtitle className="text-secondary mt-2">
              Start Date: {new Date(course.startDate).toISOString().substring(0, 10)}
            </Card.Subtitle>   
            <Card.Subtitle className="text-secondary mt-2">
              End Date: {new Date(course.endDate).toISOString().substring(0, 10)}
            </Card.Subtitle>                
            <Card.Text className="text-info">
              {course.description}
            </Card.Text>   
          </Card.Body>                      
        </Card>
      )}
    </Container>
  )
}

export default CheckChildModal