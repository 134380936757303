import React, {useState, useContext, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const AttachAnalysisModal = ({topicItemId}) => {
  const rootStore = useContext(RootStoreContext);
  const {attachAnalysis} = rootStore.topicStore;
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);  

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*, application/pdf, .zip', 
    maxFiles: 1,
    onDrop: acceptedFiles => {      
      // attach file
      setFile(acceptedFiles[0]);

    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);  

  const handleSubmit = () => {
    setSubmitting(true);
    attachAnalysis(topicItemId, file);
  }  

  return (
    <Container>
      <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop one file here, or click to select one file</p>
          }
          <p>Only image/pdf/zip file is allowed</p>
        </div>
        <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Attachment
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {(file && file.name) || ''}
              readOnly
            />
          </Col>    
        </Row> 

      <Row className="d-flex justify-content-center pb-3 mt-5">
      {file && !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row> 
    </Container>
  )
}

export default AttachAnalysisModal
