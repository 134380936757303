import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table} from 'react-bootstrap'
import { useHistory} from 'react-router-dom';

const AssistantTopicsForm = () => {
  const history = useHistory();
  const rootStore = useContext(RootStoreContext); 
  const {topics, loadAssistantTopics} = rootStore.topicStore;  

  useEffect(() => {
    loadAssistantTopics();
  }, [loadAssistantTopics]);

  return (
    <Container fluid style={{ marginTop: '2em'}}> 
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Subject Name</th>
            <th>Has Concepts?</th>
            <th>Has Analysis?</th>
            <th>Has Code?</th>
            <th>Is Separate Page?</th>
            <th>Is Workbook?</th>            
          </tr>
        </thead>
        <tbody>
          {topics && 
            topics
            .map(topic => (
              <tr key={topic.id} className="clickable" onClick={(e: any) => {
                history.push(`/h-topics/${topic.id}`);
              }}>
                <td>{topic.name}</td>
                <td>{topic.subjectName}</td>
                <td>{topic.hasConcepts ? "Yes" : "No"}</td>
                <td>{topic.hasAnalysis ? "Yes" : "No"}</td>
                <td>{topic.hasCode ? "Yes" : "No"}</td>
                <td>{topic.isSeparatePage ? "Yes" : "No"}</td>
                <td>{topic.isWorkbook ? "Yes" : "No"}</td>                                                
              </tr>
            ))          
          }
        </tbody>
      </Table>
    </Container> 
  )
}

export default observer(AssistantTopicsForm)
