import React, {useState, useContext, useEffect} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const initialValues = {
  title: '',
  courseTopicId: '',
  topicItemId: '',
  deadline: new Date(Date.now() + 7*24*60*60*1000).toISOString().substring(0, 10),
  seqNo: 1,
  submitMode: 'Code Only'   
};

const PickFromWorkbookForm = ({lessonId, problem}) => {
  const rootStore = useContext(RootStoreContext);
  const { addPickProblem, updatePickProblem } = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;	  
  const [values, setValues] = useState(initialValues); 
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if(problem){
      setValues({
        title: problem.title,
        courseTopicId: problem.courseTopicId,
        topicItemId: problem.topicItemId,
        deadline: new Date(Date.now() + 13*24*60*60*1000).toISOString().substring(0, 10),
        seqNo: problem.seqNo,
        submitMode: problem.submitMode
      });
    }
  }, [problem])
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitting(true);
    if(values.title.length > 0  && values.seqNo){
      if(problem){
        updatePickProblem(problem.id, values.title, values.courseTopicId, values.topicItemId, 
          values.seqNo.toString(), values.deadline, values.submitMode)
        .finally(()=>{
          setSubmitting(false);
        });
        closeModal();
      }
      else {
        addPickProblem(lessonId, values.title, values.courseTopicId, values.topicItemId, 
          values.seqNo.toString(), values.deadline, values.submitMode)
        .finally(()=>{
          setSubmitting(false);
        });
        closeModal();        
      }
    }
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {values.title}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Topic Id
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="courseTopicId"
              value = {values.courseTopicId}
              onChange={handleChange}
            />
          </Col>
        </Row> 
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Topic Item Id
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="topicItemId"
              value = {values.topicItemId}
              onChange={handleChange}
            />
          </Col>
        </Row>         
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
               SeqNo
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="number"
                name="seqNo"
                value = {values.seqNo}
                onChange={handleChange}
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              deadline
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              required
              type="date"
              name="deadline"
              value = {values.deadline}
              onChange={handleChange}
            />
          </Col>
        </Row> 
        <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Submit Mode
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="submitMode" 
                value = {values.submitMode}
                onChange={handleChange}
                placeholder="City">
                <option>Code Only</option>
                <option>Attach Only</option>
                <option>Both</option>
              </Form.Control>
              </Col>
          </Row>
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          values.title && values.courseTopicId && values.topicItemId && values.seqNo &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default PickFromWorkbookForm
