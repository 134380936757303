import React, {useContext, useEffect} from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { Container, Form, Col, Row, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import TeacherGradeModal from './TeacherGradeModal';

interface ProblemParams {
  problemId: string;
}

const StudentSubmissions: React.FC<RouteComponentProps<ProblemParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);  
  const { openModal} = rootStore.modalStore;
  const {studentSubmissions, loadStudentSubmissions, currentProblem, getProblem} = rootStore.assignmentStore;
  // const [assignments, setAssignments] = useState(initialValues);
  useEffect(() => {
    const loadValues = async() => {
      await loadStudentSubmissions(match.params.problemId);
      await getProblem(match.params.problemId);
    }
    loadValues();
  }, [loadStudentSubmissions, getProblem, match.params.problemId]);

  return (
    <Container>
      <Row className="mt-3">
        <h3 className="mr-auto"><span style={{color: 'teal'}}>submissions of problem</span> {currentProblem?.title} </h3>
        <Link to={`/lesson/assignment/${currentProblem?.lessonId}`}>
          <Button >Back to Assignment</Button>
        </Link>  
      </Row>

      {studentSubmissions.map((assignment, index) => 
      <div key={index}>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              <h5>Student Name:</h5>
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              type="text"
              name="remark"
              value = {assignment.studentName}
              readOnly
            />
          </Col>
        </Row>
        {assignment.studentRemark && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Student's Remark:
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <div className="student-remark">
            <p className="mt-2 ml-2">{assignment.studentRemark}</p>
          </div>
          </Col>
        </Row>
        )}
        {assignment.code && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Code 
              {assignment.grade === "Great" && <i className="fa fa-star fa-2x ml-3 mt-2 mb-2 text-primary"/>}           
              {assignment.grade === "Acceptable" && <i className="fa fa-check-circle fa-2x ml-3 mt-2 mb-2 text-info"/>}   
              {assignment.grade === "Problematic" && <i className="fa fa-bug fa-2x ml-3 mt-2 mb-2 text-warning"/>}
              {assignment.grade === "Unacceptable" && <span className="red-cross">X</span>}               
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Editor value={assignment.code}
              onValueChange = {() => {}}
              highlight={code => highlight(code, languages.js)}
              padding={5}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                backgroundColor: 'aliceblue'
              }}
            />
          </Col>
        </Row>
        )}
        {assignment.attachment && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Attachment
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <a href={assignment.attachmentUrl} target="_blank" rel="noopener noreferrer">{assignment.attachment}</a>            
          </Col>
        </Row>
        )}        
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label >
              Teacher's Remark:
              <i className="fa fa-edit ml-2 text-info clickable" 
                  onClick={() => openModal(`Remark for ${assignment.studentName}'s solution`, 
                  <TeacherGradeModal problemId={assignment.id}
                  userName={assignment.userName} 
                  grade = {assignment.grade}
                  teacherRemark={assignment.teacherRemark}
                  />, 'modal-pane-4')}>          
                </i>               
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <div className="teacher-remark">
            <p className="mt-2 ml-2">{assignment.teacherRemark}</p>
          </div>
          </Col>
        </Row>         
      </div>
        )}

    </Container>
  )
}

export default observer(StudentSubmissions)
