import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ITopic, ITopicItem } from '../models/topic';
import { history } from "../..";

export default class TopicStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable topics: ITopic[] = [];
  @observable currentTopic: ITopic | null = null;
  @observable topicItems: ITopicItem[] = [];

  @action loadTopics = async() => {
    this.loading = true;
    try {
      var ts = await agent.Topic.list();
      runInAction(() => {
        this.loading = false;
        this.topics = ts;
      })
    } catch (error){
      runInAction(() => {
          this.loading = false;
      });
    }
  }

  @action loadAssistantTopics = async() => {
    this.loading = true;
    try {
      var ts = await agent.Topic.assistantlist();
      runInAction(() => {
        this.loading = false;
        this.topics = ts;
      })
    } catch (error){
      runInAction(() => {
          this.loading = false;
      });
    }
  }

  @action addTopic = async(name: string, subjectCode: string, 
    hasConcepts: boolean, hasAnalysis: boolean, hasCode: boolean, 
    isSeparatePage: boolean, isWorkbook: boolean, submitMode: string) => {
    try {   
      await agent.Topic.addTopic(name, subjectCode, hasConcepts, hasAnalysis, hasCode, 
        isSeparatePage, isWorkbook, submitMode);  
      history.push('/topics');
    } catch(error){
      runInAction(() => {    
        throw error;          
      })    
    }
  }

  @action setTopic = async(topicId: string, name: string, 
    hasConcepts: boolean, hasAnalysis: boolean, hasCode: boolean, 
    isSeparatePage: boolean, isWorkbook: boolean, submitMode: string) => {
    try {   
      await agent.Topic.setTopic(topicId, name, hasConcepts, hasAnalysis, hasCode, 
        isSeparatePage, isWorkbook, submitMode);  
      this.rootStore.modalStore.closeModal();    
    } catch(error){
      runInAction(() => {    
        throw error;          
      })    
    }
  }

  @action getTopic = async(topicId: string) => {
    this.loading = true;        
    try {  
      var topic = await agent.Topic.getTopic(topicId);
      runInAction(() => {
        this.loading = false;
        this.currentTopic = topic;
      })
      return topic;
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action addTopicItem = async(topicId: string, category: string, seqNo: string, seqNoLink: string,
    title: string, content: string, url: string) => {
    this.loading = true;    
    try {
      var topic = await agent.Topic.addTopicItem(topicId, category, seqNo, seqNoLink, title, content, url);    
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          this.currentTopic.topicItems.push(topic);
        }
        this.rootStore.modalStore.closeModal();        
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }

  @action setTopicItemCategory = async(topicId: string, topicItemId: string, category: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItemCategory(topicId, topicItemId, category);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.category = category;
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }

  @action setTopicItemSeqNo = async(topicId: string, topicItemId: string, seqNo: string, seqNoLink: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItemSeqNo(topicId, topicItemId, seqNo, seqNoLink);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.seqNo = seqNo;
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }

  @action setTopicItemConcepts = async(topicId: string, topicItemId: string, concepts: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItemConcepts(topicId, topicItemId, concepts);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.concepts = concepts;
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  } 
  
  @action setTopicItem = async(topicId: string, topicItemId: string, title: string, url: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItem(topicId, topicItemId, title, url);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.title = title;
            topicItem.url = url;
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }  

  @action submitCode= async(topicItemId: string, code: string) => 
  {
    this.loading = true;
    try{
      await agent.Topic.submitCode(topicItemId, code);
      if(this.currentTopic){
        var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
        if(topicItem){
          topicItem.code = code;
        }
      }
      this.rootStore.modalStore.closeModal();  
    } finally {
      this.loading = false;
    }
  }

  @action attachAnalysis= async(topicItemId: string, file: Blob) => 
  {
    this.loading = true;
    try{
      var attach = await agent.Topic.attachAnalysis(topicItemId, file);
      if(this.currentTopic){
        var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
        if(topicItem){
          topicItem.analysisCloudinaryId = attach.data.analysisCloudinaryId;
          topicItem.analysisUrl = attach.data.analysisUrl;
          topicItem.analysisContent = '';
        }
      }
      this.rootStore.modalStore.closeModal();  
    } finally {
      this.loading = false;
    }
  }  

  @action setTopicItemAnalysisLink = async(topicId: string, topicItemId: string, url: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItemAnalysisLink(topicId, topicItemId, url);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.analysisCloudinaryId = '';
            topicItem.analysisUrl = url;
            topicItem.analysisContent = '';
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }     
  }

  @action setTopicItemAnalysisContent = async(topicId: string, topicItemId: string, content: string) => {
    this.loading = true;
    try {
      await agent.Topic.setTopicItemAnalysisContent(topicId, topicItemId, content);
      runInAction(() => {
        this.loading = false;
        if(this.currentTopic && this.currentTopic.id === topicId) {
          var topicItem = this.currentTopic.topicItems.find(x=>x.id === topicItemId);
          if(topicItem) {
            topicItem.analysisCloudinaryId = '';
            topicItem.analysisUrl = '';
            topicItem.analysisContent = content;
          }
        }
        this.rootStore.modalStore.closeModal();          
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  } 

  @action loadTopicItems = async(topicId: string) => {
    this.loading = true;
    try {
      var items = await agent.Topic.getTopicItems(topicId);
      runInAction(() => {
        this.loading = false;
        this.topicItems = items;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }
};