import { RootStore } from "./rootStore";
import { observable, runInAction, action } from "mobx";
import {INumberPuzzle, IUserRank} from '../../app/models/numberPuzzle';
import agent from "../api/agent";

export default class NumberPuzzleStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable numberPuzzle? : INumberPuzzle;
    @observable userRanks: IUserRank[] = [];
    @observable loading = false;
    @observable submitting = false;

    @action getUserRanks = async () => {
        this.loading = true;
        try {
            const userRanks = await agent.NumberPuzzles.ranks();
            runInAction(() => {
                this.userRanks = userRanks;
            })
            this.loading = false;
            return userRanks;
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
    
    @action loadNumberPuzzle = async () => {

        this.loading = true;
        try {
            const numberPuzzle = await agent.NumberPuzzles.info();
            runInAction(() => {
                this.numberPuzzle = numberPuzzle;
            })
            this.loading = false;
            return numberPuzzle;
        }catch (error) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action solvePuzzle = async (expression: string) => {
        this.submitting = true;
        try {
        let result = await agent.NumberPuzzles.solve(this.numberPuzzle!.id, expression);
        if (result.success) {
            this.rootStore.modalStore.openModal('Success', result.message);
            window.location.reload();       
        } else {
            this.rootStore.modalStore.openModal('failed', result.message);
        }
        } catch(error) {
            console.log(error);
            runInAction(() => {
              this.submitting = false;
            });          
        }
    }
}