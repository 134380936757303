import React from "react";
import { Tabs, Tab, Col } from 'react-bootstrap';
import Beginner from "./Beginner";
import Intermediate from "./Intermediate";
import Advanced from "./Advanced";
import Challenging from "./Challenging";
import './course.css';

export const Courses = () => {
    return (
        <div className='coursePage'>              
          <Col md={6}>            
              <h1> Course List </h1>
          </Col>
          <Col md={6}>
            <h3>Notice</h3>
            <div>                                                        
                <ul>
                    <li>Each student is expected to have their own laptop or PC. Ipads and Chromebooks are <b>NOT </b>
                        recommended.</li>
                    <li>For in-person classes, parents should pack a lunch/snack for their child.</li>
                </ul>                              
            </div>  
          </Col>
          <div className='black-line'/>         
          <Tabs id="course-selection" fill justify >        
              <Tab eventKey="first" title="Beginner">
                <Beginner /> 
              </Tab>              
              <Tab eventKey="second" title="Intermediate">
                <Intermediate />                          
              </Tab>
              <Tab eventKey="third" title="Advanced">
                <Advanced />
              </Tab>

              <Tab eventKey="fourth" title="Challenging">
                <Challenging />
              </Tab>
          </Tabs> 
        </div> 

    )
}