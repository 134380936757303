import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col, Form, Table } from 'react-bootstrap'
import { useHistory} from 'react-router-dom';

const AssistantClassesForm = () => {
  const history = useHistory();
  const rootStore = useContext(RootStoreContext);    
  const {courses, loadAssistantCourses} = rootStore.userStore;
  const [searchActive, setSearchActive] = useState(true);

  useEffect(() => {
    loadAssistantCourses();
  }, [loadAssistantCourses])

  const handleActiveSearch = (event: any) => {
    setSearchActive(event.target.checked);
  };

  return (
    <Container fluid style={{ marginTop: '2em'}}>
      <Row className="mb-3">
        <Col lg={1} md={1} sm={1}>
            <Form.Check type="checkbox" label="Active Only" defaultChecked={true}
            name="active" onChange={handleActiveSearch}/>
          </Col>
      </Row>  
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Level</th>
            <th>Sart Date</th>
            <th>End Date</th>
            <th>Scheduled Time</th>
            <th>Teacher</th>
            <th># of Students</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {courses && 
          courses.filter(course=>{
            if(searchActive){
              return course.active;
            } else{
              return true;
            }      
          }).map(course => (
            <tr key={course.courseCode} className="clickable" onClick={(e: any) => {
              history.push(`/h-classes/${course.courseCode}`); }
            }>
              <td>{course.courseCode}</td>
              <td>{course.courseName}</td>
              <td>{course.subject!.level}</td>
              <td>{new Date(course.startDate).toISOString().substring(0, 10)}</td>
              <td>{new Date(course.endDate).toISOString().substring(0, 10)}</td>
              <td>{course.dayOfTheWeek + ' ' + course.timePeriod}</td>
              <td>{course.teacher!.firstName + ' ' + course.teacher!.lastName}</td>
              <td>{course.students.length}</td> 
              <td>{course.active ? 'Yes' : 'No'}</td>                                                          
            </tr>
          ))
          }
        </tbody>
      </Table>    
    </Container>      
  )
}

export default observer(AssistantClassesForm)
