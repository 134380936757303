import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';

interface ClassParams {
  courseCode: string;
}

const initialValues = {
  subjectName: '',
  subjectCode: '',
  level: '',
  courseCode: '',
  session: '',
  courseName: '',
  active: true,
  startDate: '',
  endDate: '',
  dayOfTheWeek: '',
  timePeriod: '',
  numOfLessons: '',
  description: ''
};

const initialTeacher = {
  userName: '',
  firstName: '',
  lastName: ''
}

const verificationError = {
  emptyCourseName: false,
  invalideNumOfLessons: false
};
const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};


const EditClassForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {loadCourse, editCourse } = rootStore.coursesStore;  
  const {teachers, loadTeachers } = rootStore.teacherStore;
  const [values, setValues] = useState(initialValues); 
  const [teacher, setTeacher] = useState(initialTeacher);
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    if(name === "active"){
      setValues({...values, [name]: checked});
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const emptyCourseName = values.courseName.length === 0;
    const invalideNumOfLessons = values.numOfLessons.length === 0 || isNaN(Number(values.numOfLessons));
    setValueErrors({...valueErrors, emptyCourseName, invalideNumOfLessons});
    if(emptyCourseName ||
      invalideNumOfLessons){
    }
    else{
      var startDate = new Date(values.startDate);
      var endDate = new Date(values.endDate);
      var numOfLessons = parseInt(values.numOfLessons);
      const course = {   
        courseCode : values.courseCode,   
        active: values.active,
        courseName: values.courseName,
        startDate: startDate,
        endDate: endDate,
        dayOfTheWeek: values.dayOfTheWeek,
        timePeriod: values.timePeriod,
        numOfLessons: numOfLessons,
        description: values.description, 
        teacherUserName: teacher.userName,
      };
      setSubmitting(true);
      let isSuccess = true;
      editCourse(course).catch(error => {
        if(error.data && error.statusText){
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);   
        }
        else {
          const submitErrorInfo2 = {
            errors: [], 
            text: "Network Error"
          };
          setSubmitError(submitErrorInfo2);    
        }
        isSuccess = false;
      }).finally(() => {
        setSubmitting(false);
      });   
      if (isSuccess) {
        history.push('/a-classes');    
      }      
    }
  }; 

  useEffect(() => {
    const loadValues = async() => {
      var course = await loadCourse(match.params.courseCode);
      if(course){
        var level = 'Beginner';
        switch(course.subject!.level){
          case 2:
            level = 'Intermediate';
            break;
          case 3:
            level = 'Advanced';
            break;
          case 4:
            level = 'Challenging';
            break;
        }        
        setValues({
          subjectName: course.subject!.name,
          subjectCode: course.subject!.code,
          level: level,
          courseCode: course.courseCode,
          courseName: course.courseName,
          active: course.active,
          session: course.session.toString(),
          startDate: new Date(course.startDate).toISOString().substr(0, 10),
          endDate: new Date(course.endDate).toISOString().substr(0, 10),
          dayOfTheWeek: course.dayOfTheWeek,
          timePeriod: course.timePeriod,
          numOfLessons: course.numOfLessons.toString(),
          description: course.description
        });  
        setTeacher({
          firstName: course.teacher!.firstName,
          lastName: course.teacher!.lastName,
          userName: course.teacher!.userName
        });
        await loadTeachers();        
      }
    }
    if(match.params.courseCode){
      loadValues();
    }
  }, [loadCourse, loadTeachers, match.params.courseCode])

  const selectTeacher = (event: any) => {
    const {value} = event.target;
    const teacher = teachers.find(x=>x.userName===value);
    if(teacher){
      setTeacher({
        userName: teacher.userName,
        firstName: teacher.firstName,
        lastName: teacher.lastName
      });
    }
  }
  return (
    <Container className="content">
      <h2>Edit Course</h2>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.subjectName} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.subjectCode} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.level} readOnly>            
          </Form.Control>
          </Col> 
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Code
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control type="text" value={values.courseCode} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Session
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control type="text" value={values.session} readOnly>
              </Form.Control> 
              </Col>          
        </Row>         
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Course Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="courseName"
                value = {values.courseName}
                onChange={handleChange}
              />              
              </Col>
              <Col lg={2} md={2} sm={2}>
                <Form.Check type="checkbox" label="Active" checked={values.active} 
                name="active" onChange={handleChange}/>
              </Col>              
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="date"
                name="startDate"
                value = {values.startDate}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="date"
                name="endDate"
                value = {values.endDate}
                onChange={handleChange}
              />
              </Col>
          </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Day of the week
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="dayOfTheWeek"
                value = {values.dayOfTheWeek || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time Period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="timePeriod"
                value = {values.timePeriod || ''}
                onChange={handleChange}
              />
              </Col>             
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="numOfLessons"
                value={values.numOfLessons}
                onChange={handleChange}
              />
              </Col> 
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                required 
                as="select" 
                name="teacher" 
                value={teacher.userName}
                onChange={selectTeacher}
                >
                  {teachers.map((teacher)=> {
                    return (<option key={teacher.userName} value={teacher.userName} label={teacher.firstName + ' ' + teacher.lastName}/>)
                    })} 
              </Form.Control>
              </Col>                           
        </Row> 
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Description
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={6} className="full-control" 
              name="description"
              value={values.description}
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
        </Row>  
        <ul className="mb-4">
            {valueErrors.emptyCourseName && (
              <li className="text-danger">Empty course name</li>
            )}          
            {valueErrors.invalideNumOfLessons && (
              <li className="text-danger">invalid num of lessons</li>
            )}
        </ul>
        {submitError.text && (
			    <ErrorMessage errors={submitError.errors} text={submitError.text} />
        )}                                                      
        <Row className="d-flex justify-content-center pb-3 mt-5">
        {!submitting && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  );
}

export default observer(EditClassForm)
