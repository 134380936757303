import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Card} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser'

const Achievements = () => {
  const rootStore = useContext(RootStoreContext);
  const {achievements, getAchievements} = rootStore.achievementStore; 

  useEffect(() => {
    const loadValues = async() => {
      await getAchievements();
    }
    loadValues();
  }, [getAchievements])

  return (
    <Container>
      {achievements.slice().sort((a, b) => a.date < b.date ? 1 : -1)
      .map((achievement, idx) => 
        <Card className="mt-3 mb-2" key={idx}>
          <Card.Header>
            <Card.Body>
              <Card.Title className="active-title">
                {achievement.title}            
              </Card.Title>
              {parse(achievement.description)}
            </Card.Body>
          </Card.Header>
        </Card>      
      )}
    </Container>
  )
}

export default observer(Achievements)