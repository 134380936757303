import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';


const UpdateLesson = ({lessonId, seqNo, topic, date, timePeriod}) => {
  const rootStore = useContext(RootStoreContext);
  const {setLesson} = rootStore.lessonStore;
  const [newSeqNo, setNewSeqNo] = useState(seqNo);
  const [newTopic, setNewTopic] = useState(topic);
  const [newDate, setNewDate] = useState(new Date(date).toISOString().substring(0, 10));
  const [newTimePeriod, setNewTimePeriod] = useState(timePeriod);  
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleSeqNoChange = (event) => {
    const {value} = event.target;
    setNewSeqNo(value);
    checkFormStatus();
  }

  const handleTopicChange = (event) => {
    const {value} = event.target; 
    setNewTopic(value);  
    checkFormStatus();
  }

  const handleDateChange = (event) => {
    const {value} = event.target;
    setNewDate(value);
    checkFormStatus();
  };

  const handleTimePeriodChange = (event) => {
    const {value} = event.target; 
    setNewTimePeriod(value);  
    checkFormStatus();
  }

  const checkFormStatus = () => {
    if (newSeqNo !== seqNo || newDate !== date || newTimePeriod !== timePeriod){
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();         
    setSubmitting(true);    
    setLesson(lessonId, newSeqNo, newTopic, newDate, newTimePeriod)
    .finally(() => {
      setSubmitting(false);
    })
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
              <Form.Label className="mt-3">
                Lesson #
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="seqNo"
                value = {newSeqNo}
                onChange={handleSeqNoChange}
              />
            </Col>
          </Row>
          <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Topic
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              name="topic"
              value = {newTopic}
              onChange={handleTopicChange}
            />
          </Col>
        </Row>            
          <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Date
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="date"
              name="Date"
              value = {newDate}
              onChange={handleDateChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={{span:2, offset:2}} md={{span:2, offset:2}} sm={2}>
            <Form.Label className="mt-3">
              Time Period
            </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              name="timePeriod"
              value = {newTimePeriod}
              onChange={handleTimePeriodChange}
            />
          </Col>
        </Row>              
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default UpdateLesson
