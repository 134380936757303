import React, { useContext, useEffect, useState} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';
import {Container, Button, Row, Col, Form, Spinner} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import Parser from 'html-react-parser';
import { PythonProblemSolutionFormValues, IPythonProbleSolutionFormValues} from '../../../app/models/pythonProblem';

interface InfoParams {
  id: string;
}

const PythonProblemSolution: React.FC<RouteComponentProps<InfoParams>> = ({
  match,
  history
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadPythonProblem,
    submitting,
    solvePythonProblem
  } = rootStore.pythonProblemStore;

  const { user } = rootStore.userStore;
  const [pythonProblem, setPythonProblem] = useState(new PythonProblemSolutionFormValues());
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  
  useEffect(() => {
    if(match.params.id){
      setLoading(true);
      loadPythonProblem(match.params.id).then((pythonProblem) => {
        if (pythonProblem) {
          var answer = pythonProblem.pythonUserAnswers.find(
            x => x.userName === user!.userName
          );
          var init: IPythonProbleSolutionFormValues = {
            id: pythonProblem.id,
            seqNo: pythonProblem.seqNo,
            title: pythonProblem.title,
            description : pythonProblem.description,
            def : pythonProblem.def,
            dateCreated : pythonProblem.dateCreated,
            answer : answer?.answer,
            code : (answer ? answer.answer : pythonProblem.def)
          };
          setPythonProblem(init);
          setCode(init?.code);
        }
      }).finally(() => setLoading(false));
    }
  }, [loadPythonProblem, match.params.id, user]);

  if (loading && !pythonProblem)
    return <LoadingComponent content="loading python problem..." />;

  const handleOnChange = (event:any) => {
      setCode(event.target.value);
  };

  const handleFinalFormSubmit = (values: any) => {
    solvePythonProblem(pythonProblem!.id!, values.code);
  };

  return (
    <Container style={{ marginTop: '1rem' }}>
      <Row>
        <Col lg={4}>
          <h4>
            Problem #{pythonProblem?.seqNo}: {pythonProblem?.title}
          </h4>
            {pythonProblem &&
              pythonProblem.description &&
              Parser(pythonProblem.description)}
        </Col>
        <Col lg={8}>
              <Form>
              <Form.Group className="mb-3" controlId="code">
                <Row>
                  <Form.Label>Code</Form.Label>
                </Row>
                <Row>
                  <Form.Control as="textarea" rows={25} className="full-control" name="code" value={code} onChange={handleOnChange}>
                    {pythonProblem && pythonProblem.code}
                  </Form.Control>
                </Row>
                <Row>                
                  <Form.Text className="text-muted">
                      post your code and submit.
                  </Form.Text>  
                </Row>   
              </Form.Group>   
              {!submitting && <Button variant="primary" onClick={handleFinalFormSubmit}>Submit</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}                      
              </Form>
        </Col>
      </Row>
    </Container>

  );
};

export default observer(PythonProblemSolution);
