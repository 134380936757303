import { RootStore } from './rootStore';
import { observable, runInAction, action } from 'mobx';
import agent from '../api/agent';
import { IAnnouncement } from '../models/announcement';

export default class AnnouncementStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  
  @observable loading = false;
  @observable announcements: IAnnouncement[] = [];

  @action getAnnouncements = async() => {
    this.loading = true;
    try {
      var as = await agent.Announcement.getAnnouncements();
      runInAction(() => {
        this.announcements = as;
        this.loading = false;
      })
      return as;
    } catch(error) {
      runInAction(()=> {
        this.loading = false;
      })
    }
  }

  @action getActiveAnnouncements = async() => {
    this.loading = true;
    try {
      var as = await agent.Announcement.getActiveAnnouncements();
      runInAction(() => {
        this.announcements = as;
        this.loading = false;
      })
      return as;
    } catch(error) {
      runInAction(()=> {
        this.loading = false;
      })
    }
  }

  @action addAnnouncement = async(title: string, date: string, description: string) => {
    this.loading = true;
    try {
      var announcement = await agent.Announcement.addAnnouncement(title, date, description);
      runInAction(() => {
        this.loading = false;
        this.announcements.push(announcement);
        this.rootStore.modalStore.closeModal();
      });
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action updateAnnouncement = async(id: string, title: string, description: string) => {
    this.loading = true;
    try {    
      await agent.Announcement.updateAnnouncement(id, title, description);
      runInAction(() => {
        this.loading = false;
        this.announcements = this.announcements.map(a =>
          a.id === id ? {...a, title: title, date: a.date, description: description} : a);
        this.rootStore.modalStore.closeModal();
      }) 
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action setActive = async(id: string, active: boolean) => {
    this.loading = true;
    try {
      await agent.Announcement.setActive(id, active);
      runInAction(() => {
        this.loading = false;
        this.announcements = this.announcements.map(a=>
          a.id === id ? {...a, active: active}: a);  
          
        this.rootStore.modalStore.closeModal();
      })
    }
    catch(error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
