import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';

const initialSubject = {
  name: '',
  code: ''
};

const initialValues = {
  name: '',
  hasConcepts: false,
  hasAnalysis: false,
  hasCode: false,
  isSeparatePage: false,
  isWorkbook: false,
  submitMode: 'Code Only'
};

const verificationError = {
  subjectNotSelected: false,
  emptyTopicName: false
};

const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};

const NewTopicForm = () => {
  const rootStore = useContext(RootStoreContext);  
  const {subjects, loadSubjects} = rootStore.subjectStore;  
  const {addTopic} = rootStore.topicStore;
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState(initialSubject);
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);

  useEffect(() => {
    const loadValues = async() => {
      await loadSubjects();
    }
    loadValues();
  }, [loadSubjects]);

  const selectSubject = (event: any) => {
    const { value } = event.target;
    const subject = subjects.find(x=>x.code === value);
    if(subject) {
      setSubject({
        code: subject.code,
        name: subject.name
      });
    }
  }
  const handleValueChange = (event: any) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  
  const handleCheckedChange = (event: any) => {
    const { name, checked } = event.target;
    setValues({...values, [name]: checked});
  };
  
  const handleSubmit = (event: any) : void => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const subjectNotSelected = subject.code.length === 0;
    const emptyTopicName = values.name.length === 0;
    setValueErrors({...valueErrors, subjectNotSelected, emptyTopicName});
    if(subjectNotSelected || emptyTopicName) {}
    else {
      setSubmitting(true);
      addTopic(values.name, subject.code, values.hasConcepts, values.hasAnalysis, values.hasCode, 
        values.isSeparatePage, values.isWorkbook, values.submitMode)
      .catch(error => {
        if(error.data && error.statusText){
          console.log(error.data.errors)
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);   
          console.log(submitErrorInfo1);          
        }
        else {
          const submitErrorInfo2 = {
            errors: [], 
            text: "Network Error"
          };
          setSubmitError(submitErrorInfo2);    
        }
      }).finally(() => {
        setSubmitting(false);
      });         
    }
  };

  return (
    <Container className="content">
      <h2 style={{color: "red"}}>New Topic</h2>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3 bold-font">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            required 
            as="select" 
            name="subjectName"
            value = {subject.code}
            onChange={selectSubject}
          >
            <option disabled value="" key={-1}>select</option>
            {subjects.map((subject)=> {
                return <option key={subject.code} value={subject.code} label={subject.name}/>
              })}                      
          </Form.Control>
          </Col>
        </Row>       
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3 bold-font">
                Topic Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="name"
                value={values.name}
                onChange={handleValueChange}
              />              
              </Col>              
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Concepts?" checked={values.hasConcepts} 
                name="hasConcepts" onChange={handleCheckedChange}/>                
              </Col>              
        </Row>   
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Analysis" checked={values.hasAnalysis} 
                name="hasAnalysis" onChange={handleCheckedChange}/>                
              </Col>              
        </Row> 
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Has Code" checked={values.hasCode} 
                name="hasCode" onChange={handleCheckedChange}/>
              </Col>              
        </Row>
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Is Separate Page" checked={values.isSeparatePage} 
                name="isSeparatePage" onChange={handleCheckedChange}/>
              </Col>              
        </Row>      
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Check type="checkbox" label="Is Workbook" checked={values.isWorkbook} 
                name="isWorkbook" onChange={handleCheckedChange}/>
              </Col>              
        </Row>  
        <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Submit Mode
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="submitMode" 
                value = {values.submitMode}
                onChange={handleValueChange}
                placeholder="City">
                <option>Code Only</option>
                <option>Attach Only</option>
                <option>Both</option>
              </Form.Control>
              </Col>
            </Row>                                               
        <ul className="mb-4">
              {valueErrors.subjectNotSelected && (
                <li className="text-danger">Subject not selected</li>
              )}          
              {valueErrors.emptyTopicName && (
                <li className="text-danger">Empty topic name</li>
              )}
            </ul>
            {submitError.text && (
                <ErrorMessage errors={submitError.errors} text={submitError.text} />
        )}                                          
        <Row className="d-flex justify-content-center pb-3 mt-3">
        {!submitting && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  )
}

export default observer(NewTopicForm)
