import React, { useContext } from 'react';
import {
  Container, Row, Col
} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { IPythonProblem } from '../../../app/models/pythonProblem';
import { RootStoreContext } from '../../../app/stores/rootStore';

const PythonProblemList = () => {
  const rootStore = useContext(RootStoreContext);
  const { pythonProblems } = rootStore.pythonProblemStore;
  const { user } = rootStore.userStore;

  return (
    <Container>
      <Row>
        <Col lg={1}></Col>
        <Col lg={1}>
          <h2>#</h2>
        </Col>
        <Col lg={13}>
          <h2>Title</h2>
        </Col>
        {user && user.roles.includes('PythonAdmin') && (
          <Col lg={1} className="float-right">
            <Link to={`/newPythonproblem`}>
              <i className="fa fa-plus" style={{color:"teal"}}/>
            </Link>
          </Col>
        )}
      </Row>
      {pythonProblems.map((pythonProblem: IPythonProblem) => (
        <Row key={pythonProblem.id}>
          <Col lg={1}>
            {pythonProblem.pythonUserAnswers.some(
              x => x.userName === user!.userName
            ) && <i className="fa fa-check" style={{color:"teal"}}/>}
          </Col>
          <Col lg={1}>
            <Link to={`/pythonproblems/${pythonProblem.id}`}>
              {pythonProblem.seqNo}
            </Link>
          </Col>
          <Col lg={13}>
            <Link to={`/pythonproblems/${pythonProblem.id}`}>
              {pythonProblem.title}
            </Link>
          </Col>
          {user && user.roles.includes('PythonAdmin') && (
            <Col lg={1} className="float-right">
              <Link to={`/editPythonproblem/${pythonProblem.id}`}>
              <i className="fa fa-edit" style={{color:"teal"}}/>
              </Link>
            </Col>
          )}
        </Row>
      ))}      
    </Container>    
  );
};

export default observer(PythonProblemList);
