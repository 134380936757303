import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Table, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import EditProblemForm from './EditProblemForm';
import AssignmentProblemModal from './AssignmentProblemModal';
import { observer } from 'mobx-react-lite';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditProblemLinkForm from './EditProblemLinkForm';
import AddProblemAttachForm from './AddProblemAttachForm';
import AddAssignmentResourceText from './AddAssignmentResourceText';
import AddAssignmentResourceLink from './AddAssignmentResourceLink';
import AddAssignmentResourceAttach from './AddAssignmentResourceAttach';
import SetAssignmentResourceSeqNo from './SetAssignmentResourceSeqNo';
import AssignmentResourceModal from'./AssignmentResourceModal';
import PickFromWorkbookForm from './PickFromWorkbookForm';
import SetAssignmentSeqNo from './SetAssignmentSeqNo';
import SetAssignmentDeadline from './SetAssignmentDeadline';
import SetAnalysisUrlModal from './SetAnalysisUrlModal';

interface LessonParams {
  lessonId: string;
}

const AssignmentContentForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {assignmentContent, loadAssignmentContent, removeProblem, removeResource } = rootStore.assignmentStore;
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    const loadValues = async() => {
      await loadAssignmentContent(match.params.lessonId);   
    }
    if(match.params.lessonId){
      loadValues();
    }    
  }, [loadAssignmentContent, match.params.lessonId]);
  
  const handleRemoveAssignment = (problemId: string) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeProblem(problemId)      
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  };

  const handleRemoveResource = (id: any) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeResource(match.params.lessonId, id)        
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  }
  return (
    <div className="content"> 
      <Row>
        <h3 className="mr-auto"><span style={{color: 'red'}}>Lesson {assignmentContent?.seqNo} Assignment </span> of Course: {assignmentContent?.courseName}</h3>
        <Link to={`/t-classes/${assignmentContent?.courseCode}`}>
          <Button >Back to Class</Button>
        </Link>  
      </Row>          
      <Row>
        <h4 className="text-info">Date: {assignmentContent && (new Date(assignmentContent.date)).toISOString().substring(0, 10)}</h4>
      </Row>
      <Row>
        <h4 className="text-info mr-auto">TimePeriod: {assignmentContent?.timePeriod}</h4>  
        <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new problem (Text)", <EditProblemForm lessonId={match.params.lessonId} problem={null}/>, 'modal-pane-2')}>          
        </i> 
        <i className="fa fa-link fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new problem (Link)", <EditProblemLinkForm lessonId={match.params.lessonId} problem={null}/>, 'modal-pane-2')}>          
        </i>  
        <i className="fa fa-paperclip fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new problem (Attach)", <AddProblemAttachForm lessonId={match.params.lessonId} />, 'modal-pane-2')}>          
        </i>  
        <i className="fa fa-magic fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Pick from work book", <PickFromWorkbookForm lessonId={match.params.lessonId} problem={null}/>, 'modal-pane-2')}>          
        </i>                            
      </Row>  

      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Deadline</th>
            <th>Analysis</th>
            <th>Discuss</th>
            <th>Submissions</th>
            <th>Operation</th>
          </tr>
        </thead>
        <tbody>
          {assignmentContent?.assignments?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map(assignment =>
          <tr key={assignment.id}>
            <td>
              {assignment.seqNo}
              <i className="fa fa-edit ml-2 text-info clickable" 
                onClick={() => openModal(`Set SeqNo for ${assignment.title}`, 
                <SetAssignmentSeqNo assignmentId={assignment.id} seqNo={assignment.seqNo.toString()} />, 'modal-pane-2')} >          
              </i>             
            </td>
            <td>
              {assignment.url && (
                <a href={assignment.url} target="_blank" rel="noopener noreferrer">{assignment.title}</a>     
              )}
              {!assignment.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`problem ${assignment.title}`, 
                <AssignmentProblemModal title={assignment.title} description={assignment.description}/>, 'modal-pane-1')}>
                  {assignment.title}    
                </span>                        
              )}
            </td>
            <td>
              {new Date(assignment.deadline).toISOString().substring(0, 10)}
              <i className="fa fa-edit ml-2 text-info clickable" 
                onClick={() => openModal(`Set Deadline for ${assignment.title}`, 
                <SetAssignmentDeadline assignmentId={assignment.id} deadline={assignment.deadline} />, 'modal-pane-2')} >          
              </i>              
            </td>
            <td>
              {assignment.analysisUrl && (
                <a href={assignment.analysisUrl} target="_blank" rel="noopener noreferrer">Video</a>     
              )}
              <i className="fa fa-edit ml-2 mt-2 mb-2 text-info clickable" 
                onClick={() => openModal(`problem ${assignment.title}`, 
                <SetAnalysisUrlModal assignmentId={assignment.id} analysisUrl={assignment.analysisUrl}/>, 'modal-pane-1')}>          
              </i>             
            </td>
            <td>
              {assignment.courseTopicId && assignment.topicItemId && (
                <Link to={`/coursetopics/discuss/${assignment.courseTopicId}/${assignment.topicItemId}/${assignment.title}`}>
                <span className="clickable text-primary">Discuss</span>
              </Link>
              )}
              {(!assignment.courseTopicId || !assignment.topicItemId) && (
                <Link to={`/lesson/discuss/${assignment.id}/${assignment.title}`}>
                  <span className="clickable text-primary">Discuss</span>
                </Link>
              )}
            </td>            
            <td>
              {assignment.numOfStudentAssignments > 0 && (
                <Link to={`/lesson/problem/${assignment.id}`}>
                  <span className="clickable text-primary">{assignment.numOfStudentAssignments} submissions</span>
                </Link>
              )} 
            </td>
            <td>
            <div>
            <i className="fa fa-edit mr-3 mt-2 mb-2 text-info clickable" 
              onClick={() => openModal("Update problem (Text)", <EditProblemForm lessonId={match.params.lessonId} problem={assignment}/>, 'modal-pane-2')}>          
            </i>
            <i className="fa fa-link mr-3 mt-2 mb-2 text-info clickable" 
              onClick={() => openModal("Update problem (Link)", <EditProblemLinkForm lessonId={match.params.lessonId} problem={assignment}/>, 'modal-pane-2')}>          
            </i>            
            <i className="fa fa-trash ml-2 text-danger clickable" onClick={() => handleRemoveAssignment(assignment.id)}></i>       
            </div>
            </td>
          </tr>
            )}
        </tbody>
      </Table>  

      <div className='mt-5' style={{display: 'flex'}}>
        <h4 className='mr-auto' style={{color:'darkcyan'}}>Resources</h4>
        <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (text)", <AddAssignmentResourceText lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i>
        <i className="fa fa-link fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (link)", <AddAssignmentResourceLink lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i> 
        <i className="fa fa-paperclip fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (attachment)", <AddAssignmentResourceAttach lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i>                 
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {assignmentContent?.resources?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map(resource =>
            <tr key={resource.id}>
            <td>{resource.seqNo}
              <i className="fa fa-edit ml-2 text-info clickable" 
                onClick={() => openModal(`Set SeqNo for ${resource.title}`, 
                <SetAssignmentResourceSeqNo assignmentResourceId={resource.id} seqNo={resource.seqNo.toString()} />, 'modal-pane-2')} >          
              </i>            
            </td>
            <td>
              {resource.url && (
                <a href={resource.url} target="_blank" rel="noopener noreferrer">{resource.title}</a>
              )}
              {!resource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${resource.title}`, <AssignmentResourceModal content={resource.content}/>, 'modal-pane-1')}>
                  {resource.title}
                </span>
              )}
              <i className="fa fa-trash ml-2 text-danger clickable" onClick={() => handleRemoveResource(resource.id)}></i>
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>             
    </div>
  )
}

export default observer(AssignmentContentForm)
