import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {IAssistant} from '../../app/models/assistant';
import {Container, Row, Col, Form, Table, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SplitPane from 'react-split-pane';

import "../classes.css";

interface ClassParams {
  courseCode: string;
}
const initialAssistants: IAssistant[] = [];

const ClassAssistants: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);
    const {loadCourse, addCourseAssistant, removeCourseAssistant} = rootStore.coursesStore;
    const {assistants, loadAssistants} = rootStore.assistantStore;
    const [courseAssistants, setCourseAssistants] = useState(initialAssistants);
    const [searchTerm, setSearchTerm] = useState('');
    const [btmHeight, setBtmHeight] = useState(100);
    useEffect(() => {
      const loadCourseAssistants = async() => {
        var course = await loadCourse(match.params.courseCode);
        if(course) {
          setCourseAssistants(course.assistants);
        } 
      };
      if(match.params.courseCode){
        loadCourseAssistants();
        loadAssistants();      
      }
    }, [loadCourse, setCourseAssistants, loadAssistants, match.params.courseCode]);

    const toggleBtmHeight = (topPaneHeight: any) => {
      const maxHeight = 500;
      const padding = 25;
      const btmPaneHeight = maxHeight - topPaneHeight - padding;
      setBtmHeight(btmPaneHeight);
    }
    const handleChange = (event: any) => {
      setSearchTerm(event.target.value);
    }

    const addAssistant = (userName: any, e: any) => {
      addCourseAssistant(match.params.courseCode, userName);
      var assisant = assistants.find(x=>x.userName === userName);
      if(assisant){
        setCourseAssistants([...courseAssistants, assisant]);
      }
    };

    const removeAssistant = (userName: any, e: any) => {
      removeCourseAssistant(match.params.courseCode, userName);
      setCourseAssistants(courseAssistants.filter(x=>x.userName !== userName));
    };

  return (
    <Container fluid style={{ marginTop: '2em'}} >
      <div className="mb-3">
        <Row>
          <Col lg={2}>
          <Form.Control type="text" value={searchTerm} placeholder="Search..." onChange={handleChange}>
            </Form.Control>
          </Col>
          <Link to={`/a-classes/info/${match.params.courseCode}`} className="ml-5">
            <Button>Back to class</Button>
          </Link>
        </Row>

      </div>

      <SplitPane split="horizontal" defaultSize="50%" className="split-pane" style={{position: "relative"}} onChange={size=>toggleBtmHeight(size)}>
      <Table striped bordered hover className="mb-3 split-pane-1">
        <thead>
          <tr>
            <th style={{width: "5rem"}}>#</th>
            <th style={{width: "20rem"}}>Full Name</th>
            <th style={{width: "15rem"}}>Email</th>
            <th style={{width: "5rem"}}>Add</th>
          </tr>
        </thead>
        <tbody>
          {assistants            
            .filter(assistant => 
              !courseAssistants.some(x => x.userName === assistant.userName)
            )
            .filter(user => {
              if(searchTerm === ""){
                return true;
              } else if(user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        user.lastName.toLowerCase().includes(searchTerm.toLowerCase())){
                return true;
              } else{
                return false;
              }
            })            
            .map((assistant, index) => 
            <tr key={assistant.userName}>
            <td>{index+1}</td>
            <td>{assistant.firstName+' ' + assistant.lastName}</td>
            <td>{assistant.email}</td>
            <td><i className="fa fa-plus-circle fa-2x mr-3 text-info clickable" onClick={(e) => addAssistant(assistant.userName, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
      <Table striped bordered hover className="mb-3 split-pane-2" style={{height: {btmHeight}+"px"}}>
        <thead>
          <tr>
            <th style={{width: "5rem"}}>#</th>
            <th style={{width: "20rem"}}>Full Name</th>
            <th style={{width: "15rem"}}>Email</th>
            <th style={{width: "5rem"}}>Remove</th>
          </tr>
        </thead>
        <tbody>
          {courseAssistants
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })          
          .map((assisant, index) => 
            <tr key={assisant.userName}>
            <td>{index+1}</td>
            <td>{assisant.firstName+' ' + assisant.lastName}</td>
            <td>{assisant.email}</td>
            <td><i className="fa fa-trash fa-2x mr-3 text-warning clickable" onClick={(e) => removeAssistant(assisant.userName, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
    </SplitPane>

    </Container>
  )
}

export default observer(ClassAssistants)
