import React, { useContext, useState, useEffect} from 'react';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';

const initialValues = {
  firstName: '',
  lastName: '',
  city: 'Edmonton',
  email: '',
  password: '',
  rePassword: '',
};
const verificationError = {
  emptyField: false,
  invalidEmail: false,
  invalidPassword: false,
  rePasswordNotMatch: false
};
const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};

export const RegisterForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { register } = rootStore.userStore;
  const [values, setValues] = useState(initialValues);
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);
  useEffect(() => {}, [values, submitting]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const emptyField = values.firstName.length === 0 || 
                values.lastName.length === 0 || 
                values.city.length === 0 || 
                values.email.length === 0;
    const invalidEmail = values.email.indexOf('@') < 0 || values.email.indexOf(' ') > 0;
    const invalidPassword = values.password.length < 8;
    const rePasswordNotMatch = values.password !== values.rePassword;  
    setValueErrors({...valueErrors, emptyField, invalidEmail, invalidPassword, rePasswordNotMatch}); 
    if (emptyField || 
      invalidEmail || 
      invalidPassword || 
      rePasswordNotMatch) {
    }
    else{
      setSubmitting(true);
      register(values).catch(error => {
        if(error.data && error.statusText){
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);
        }
        else{
          const submitErrorInfo2 = {
            errors: [], 
            text: "Registration Failed"
          };
          setSubmitError(submitErrorInfo2);       
        }  
      }).finally(() => {
        setSubmitting(false);
      });     
    }
  };

  return (
    <Container className="content">
          <Form noValidate>
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Email
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="email"
                name="email"
                value = {values.email}
                onChange={handleChange}
                placeholder="Email"
              />
              </Col>
            </Row>  
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                First Name
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="firstName"
                value = {values.firstName}
                onChange={handleChange}
                placeholder="First name"
              />
              </Col>
            </Row>
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Last Name
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="lastName"
                value = {values.lastName}
                onChange={handleChange}
                placeholder="Last name"
              />
              </Col>
            </Row>  
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                City
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="city" 
                value = {values.city}
                onChange={handleChange}
                placeholder="City">
                <option>Edmonton</option>
                <option>Calgary</option>
                <option>Other</option>
              </Form.Control>
              </Col>
            </Row>    
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Password (8+ characters)
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="password"
                name="password"
                value = {values.password}
                onChange={handleChange}                
                placeholder="Password"
              />
              </Col>
            </Row> 
            <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
              Confirm password
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="password"
                name="rePassword"
                value = {values.rePassword}
                onChange={handleChange}                
                placeholder="Confirm passsword"
              />
              </Col>
            </Row>                                
            <ul className="mb-4">
              {valueErrors.emptyField && (
                <li className="text-danger">Empty Field(s)</li>
              )}
              {valueErrors.invalidEmail && (
                <li className="text-danger">Invalid Email </li>
              )}
              {valueErrors.invalidPassword && (
                <li className="text-danger">Invalid Password</li>
              )}
              {valueErrors.rePasswordNotMatch && (
               <li className="text-danger">Passwords do not match</li>
              )}
            </ul>
            {submitError.text && (
                <ErrorMessage errors={submitError.errors} text={submitError.text} />
            )}
            <Row className="d-flex justify-content-center pb-3">
            {!submitting && <Button variant="primary" onClick={handleSubmit}>Register</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
            </Row>
          </Form>
    </Container>
  );
};
