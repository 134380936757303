import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Table, Row, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { IAttendance } from '../../app/models/attendance';
import { ICourseLesson, CourseLesson } from '../../app/models/courseLesson';

interface LessonParams {
  lessonId: string;
}

const initialLesson : ICourseLesson = new CourseLesson();
const initialValues : IAttendance[] = [];

const AssistantAttendanceForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);  
  const {loadLesson, loadAttendances, setAttendance} = rootStore.lessonStore;
  const [newAttendances, setNewAttendances] = useState(initialValues);
  const [currentLesson, setCurrentLesson] = useState(initialLesson);
  const [absentCheck, setAbsentCheck] = useState(false);

  useEffect(() => {
    const loadValues = async() => {
      var attendances = await loadAttendances(match.params.lessonId);   
      if(attendances){
        setNewAttendances(attendances);
      }
      var lesson = await loadLesson(match.params.lessonId);
      if(lesson){
        setCurrentLesson(lesson);
      }
    }
    if(match.params.lessonId){
      loadValues();
    }
  }, [loadAttendances, loadLesson, match.params.lessonId, setNewAttendances]);

  const handleSetAttendanceOnTime = (userName: any) => {
    setAttendance(match.params.lessonId, userName, 1);
    if(newAttendances && userName){
      var newValues = newAttendances.map(item => 
        item.student?.userName === userName ? 
         {...item, attendingCode: 1} :
         item
      );
      setNewAttendances(newValues);
    }
  }
  const handleSetAttendanceNA = (userName: any) => {
    setAttendance(match.params.lessonId, userName, 0);
    if(newAttendances && userName){
      var newValues = newAttendances.map(item => 
        item.student?.userName === userName ? 
         {...item, attendingCode: 0} :
         item
      );
      setNewAttendances(newValues);
    }
  }  
  const handleSetAttendanceLate = (userName: any) => {
    if(newAttendances && userName){
      setAttendance(match.params.lessonId, userName, 2);
      var newValues = newAttendances.map(item => 
        item.student?.userName === userName ? 
         {...item, attendingCode: 2} :
         item
      );
      setNewAttendances(newValues);
    }
  }  
  const handleSetAttendanceNotice = (userName: any) => {
    setAttendance(match.params.lessonId, userName, 3);
    if(newAttendances && userName){
      var newValues = newAttendances.map(item => 
        item.student?.userName === userName ? 
         {...item, attendingCode: 3} :
         item
      );
      setNewAttendances(newValues);
    }
  }
  const handleSetAttendanceAbsent = (userName: any) => {
    setAttendance(match.params.lessonId, userName, 4);
    if(newAttendances && userName){
      var newValues = newAttendances.map(item => 
        item.student?.userName === userName ? 
         {...item, attendingCode: 4} :
         item
      );
      setNewAttendances(newValues);
    }
  }    
  const handleAbsentCheck = (event: any) => {
    setAbsentCheck(event.target.checked);
  }
  return (
    <Container className="mt-3">
      <Row>
        <h3><span style={{color: 'red'}}>Lesson {currentLesson?.seqNo} Attendance</span> of Course: {currentLesson?.courseName}</h3>
      </Row>
      <Row>
        <h4 className="text-info mr-auto">Date: {new Date(currentLesson?.date).toISOString().substring(0, 10)}</h4>
        <Form.Check type="checkbox" label="Absent" defaultChecked={false}
          name="absent" onChange={handleAbsentCheck}/>        
      </Row>
      <Row>
        <h4 className="text-info mr-auto">TimePeriod: {currentLesson?.timePeriod}</h4>  
        <Link to={`/h-classes/${currentLesson?.courseCode}`}>
          <Button >Back to Class</Button>
        </Link>      
      </Row>
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>City</th>
            <th>Email</th>
            <td>On Time?</td>
            <td>Roll Call</td>
            {absentCheck && 
            <td>Attended?</td>
            }            
          </tr>
        </thead>
        <tbody>
          {newAttendances?.slice().sort((a, b) => {
                      if (a.student.firstName.toLowerCase() > b.student.firstName.toLowerCase()) return 1;
                      else if(a.student?.firstName.toLowerCase() < b.student.firstName.toLowerCase()) return -1;
                      else {
                          if(a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) return 1;
                          else return -1;
                      }
                    })
          .map((attendance, index) => 
            <tr key={index} className={`${attendance.attendingCode === 1 ? "text-primary" : "text-secondary"}`}>
              <td>{index + 1}</td>
              <td>{attendance.student.firstName}   {attendance.student.lastName}</td>
              <td>{attendance.student.city}</td>
              <td>{attendance.student.email}</td>
              <td>
                {attendance.attendingCode === 1 ? 'Yes' : 'No'}
              </td>
              <td>
                {attendance.attendingCode === 1 && (
                  <i className="fa fa-minus-square fa-2x ml-2 text-danger clickable" onClick={() => handleSetAttendanceNA(attendance.student?.userName)}></i>
                )}
                {attendance.attendingCode !== 1 && (
                  <i className="fa fa-plus-square fa-2x ml-2 text-primary clickable" onClick={() => handleSetAttendanceOnTime(attendance.student?.userName)}></i>      
                )}
              </td>
              {absentCheck && 
              <td>
                {attendance.attendingCode === 0 && (
                  <Fragment>
                  <i className="fa fa-clock-o fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceLate(attendance.student?.userName)}></i>                                         
                  <i className="fa fa-flag fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceNotice(attendance.student?.userName)}></i>  
                  <i className="fa fa-times fa-2x ml-2 text-seconary clickable" onClick={() => handleSetAttendanceAbsent(attendance.student?.userName)}></i>  
                  </Fragment>                                        
                )}
                {attendance.attendingCode === 2 && (
                  <Fragment>
                  <i className="fa fa-clock-o fa-2x ml-2 text-warning"></i>                     
                  <i className="fa fa-flag fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceNotice(attendance.student?.userName)}></i>  
                  <i className="fa fa-times fa-2x ml-2 text-seconary clickable" onClick={() => handleSetAttendanceAbsent(attendance.student?.userName)}></i>  
                  </Fragment>                                        
                )}                
                {attendance.attendingCode === 3 && (
                  <Fragment>
                  <i className="fa fa-clock-o fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceLate(attendance.student?.userName)}></i>                     
                  <i className="fa fa-flag fa-2x ml-2 text-info"></i>  
                  <i className="fa fa-times fa-2x ml-2 text-seconary clickable" onClick={() => handleSetAttendanceAbsent(attendance.student?.userName)}></i>  
                  </Fragment>                                        
                )}
                {attendance.attendingCode === 4 && (
                  <Fragment>
                  <i className="fa fa-clock-o fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceLate(attendance.student?.userName)}></i>                     
                  <i className="fa fa-flag fa-2x ml-2 text-secondary clickable" onClick={() => handleSetAttendanceNotice(attendance.student?.userName)}></i>  
                  <i className="fa fa-times fa-2x ml-2 text-danger"></i>  
                  </Fragment>                                        
                )}                                
                </td>}
            </tr>
          )}
        </tbody>
      </Table>
      <div className="mb-3">
        {newAttendances.filter(x=>x.attendingCode === 1).length} of {newAttendances.length} students attended on time.
      </div>
    </Container>
  )
}

export default AssistantAttendanceForm
