import React from 'react'
import { Container, Col, Row} from 'react-bootstrap';

const ShowItemInfo = ({courseTopicId, topicItemId}) => {
  return (
    <Container className="content">
      <Row className="form-field p-3">
        <Col lg={3} md={3} sm={3}>
          Course Topic Id
        </Col>
        <Col lg={9} md={9} sm={9}>
          {courseTopicId}
        </Col>        
      </Row>
      <Row className="form-field p-3">
        <Col lg={3} md={3} sm={3}>
          Topic Item Id
        </Col>
        <Col lg={9} md={9} sm={9}>
          {topicItemId}
        </Col>        
      </Row>      
    </Container>
  )
}

export default ShowItemInfo