import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { IAssistant } from '../../app/models/assistant';
import {IStudent} from '../../app/models/student';
import { Link } from 'react-router-dom';
import {Container, Row, Col, Form, Button, Table} from 'react-bootstrap';
import "../classes.css";
import StudentReportModal from './StudentReportModal';

interface ClassParams {
  courseCode: string;
}

const initialValues = {
  subjectName: '',
  subjectCode: '',
  level: '',
  courseCode: '',
  session: '',
  courseName: '',
  startDate: '',
  endDate: '',
  dayOfTheWeek: '',
  timePeriod: '',
  teacher: '',
  numOfLessons: '',
  description: ''
};

const initialAssistants: IAssistant[] = [];
const initialStudents: IStudent[] = [];

const ClassForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal} = rootStore.modalStore;  
  const {loadCourse, toggleCourseActive} = rootStore.coursesStore;
  const [values, setValues] = useState(initialValues);
  const [active, setActive] = useState(true);
  const [assistants, setAssistants] = useState(initialAssistants);
  const [students, setStudents] = useState(initialStudents);
  useEffect(() => {
    const loadValues = async() => {
      var course = await loadCourse(match.params.courseCode);
      if(course){
        var level = 'Beginner';
        switch(course.subject!.level){
          case 2:
            level = 'Intermediate';
            break;
          case 3:
            level = 'Advanced';
            break;
          case 4:
            level = 'Challenging';
            break;
        }        
        setValues({
          subjectName: course.subject!.name,
          subjectCode: course.subject!.code,
          level: level,
          courseCode: course.courseCode,
          session: course.session.toString(),
          courseName: course.courseName,
          startDate: new Date(course.startDate).toLocaleDateString(),
          endDate: new Date(course.endDate).toLocaleDateString(),
          dayOfTheWeek: course.dayOfTheWeek,
          timePeriod: course.timePeriod,
          teacher: course.teacher!.firstName + ' ' + course.teacher!.lastName,
          numOfLessons: course.numOfLessons.toString(),
          description: course.description
        });    
        setActive(course.active);
        setAssistants(course.assistants);
        setStudents(course.students); 
      }
    }
    if(match.params.courseCode){
      loadValues();
    }
  }, [loadCourse, match.params.courseCode])

  const toggleActive = () => {
    var newActive = !active;
    toggleCourseActive(values.courseCode, newActive);
    setActive(newActive);
  };

  return (
    <Container className="content">
      <div style={{display: 'flex'}}>
        <h2 className={`ml-3 mr-auto ${active ? "active-title" : "inactive-title"}`}>Course: {values.courseName}</h2>
        <Link to={`/a-classes/${values.courseCode}`}>
          <Button variant="info" size="sm" className="mt-2 mb-2 mr-3">Class Room</Button>
        </Link>
        <Link to={`/a-classes/editclass/${values.courseCode}`}>
        <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info" ></i>
        </Link>
        {!active && 
          <Button variant="primary" size="sm" className="mt-2 mb-2"  onClick={toggleActive}>Activate</Button>
        }
        {active && 
          <Button variant="secondary" size="sm" className="mt-2 mb-2" onClick={toggleActive}>Inactivate</Button>
        }
      </div>


      <Form className="mt-3">
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.subjectName} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.subjectCode} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={values.level} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Check type="checkbox" label="Active" checked={active} 
                name="active" readOnly/>
          </Col>            
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Code
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              value = {values.courseCode}
              readOnly
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Session
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.session}
                readOnly
              />
              </Col>          
        </Row>         
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.startDate}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.endDate}
                readOnly
              />
              </Col>
          </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Day of the week
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.dayOfTheWeek || ''}
                readOnly
              />
              </Col>           
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time Period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.timePeriod || ''}
                readOnly
              />
              </Col>            
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {values.numOfLessons}
                readOnly
              />
              </Col>  
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                type="text"
                value = {values.teacher}
                readOnly
              />
              </Col>                                          
        </Row> 
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Description
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={5} className="full-control" value={values.description} readOnly
              >                
              </Form.Control>
          </Col>   
          </Row>                                                                
      </Form>
      <div className='mt-5' style={{display: 'flex'}}>
        <h3 className='mr-auto'>Assistants</h3>
        <Link to={`/a-classes/classassistants/${values.courseCode}`}>
        <i className="fa fa-edit fa-2x ml-2 text-info"></i>
        </Link>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {assistants
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })
          .map((assistant, index) => 
            <tr key={assistant.userName}>
            <td>{index+1}</td>
            <td>{assistant.firstName+' ' + assistant.lastName}</td>
            <td>{assistant.email}</td>
          </tr>
          )}      
        </tbody>
      </Table>        
      <div className='mt-5' style={{display: 'flex'}}>
        <h3 className='mr-auto'>Students</h3>
        <Link to={`/a-classes/classstudents/${values.courseCode}`}>
        <i className="fa fa-edit fa-2x ml-2 text-info"></i>
        </Link>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Age</th>
            <th>Parent</th>
            <th>Phone</th>
            <th>Wechat</th>
            <th>City</th>
            <th>Email</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody>
          {students
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })
          .map((student, index) => 
            <tr key={student.userName}>
            <td>{index+1}</td>
            <td>{student.firstName+' ' + student.lastName}</td>
            <td>{student.age}</td>
            <td>{(student.parentFirstName == null ? '' : student.parentFirstName) + ' ' 
                  + (student.parentLastName == null ? '' : student.parentLastName)}</td>
            <td>{student.phone}</td>
            <td>{student.wechat}</td>
            <td>{student.city}</td>
            <td>{student.email}</td>
            <td>
            <i className="fa fa-file fa-2x ml-2 text-primary clickable"
            onClick={() => openModal(`report of ${student.firstName} ${student.lastName}`, <StudentReportModal courseCode={values.courseCode} userName={student.userName}/>, 'modal-pane-1')}></i>              
            </td>

          </tr>
          )}       
        </tbody>
      </Table>
    </Container>
  );
}

export default observer(ClassForm)
