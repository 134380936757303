import React from 'react'
import { Jumbotron, Button } from 'react-bootstrap';

const HomeBanner = () => {
    return (
        <Jumbotron className="home-banner">
                <div className='jumboBody'>
                    <div className="white-line"></div>
                    <h1 className="jumboHeading">
                        Coding<br/>
                        Courses for<br/>
                        Kids and Teens
                    </h1>
                    <div className="white-line"></div>
                    <h2 className="jumboText">
                        <b>Making coding fun</b>
                    </h2>
                    <Button variant="primary" size="lg" href='/contact'>Get started today!</Button>
                </div>                   
        </Jumbotron>            
    )
}

export default HomeBanner
