export const convertUTCDateToLocalData = (objDate) => {
  var date = new Date(objDate);
  var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  return newDate;
}

export const convertUTCDateToLocalDataString = (objDate) => {
  var date = new Date(objDate);
  var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  return newDate.toLocaleDateString().substring(0, 10);
}

export const convertUTCDateToLocalTimeString = (objDate) => {
  var date = new Date(objDate);
  var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  return newDate.toTimeString().substring(0, 8);
}