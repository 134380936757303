import React from 'react';
import GoogleLogin from 'react-google-login/dist/google-login';
import {Button} from 'react-bootstrap';
interface IProps {
    onSuccess: (response: any) => void;
    onFailure: (response: any) => void;
    loading: boolean;
}

const GoogleLoginSegment : React.FC<IProps> = ({onSuccess, onFailure, loading}) => {
    return (
        <div>
  <GoogleLogin
    clientId="1008555678364-i0uaqssi59nhv7tn670of96jku2vvuob.apps.googleusercontent.com"
    render={(renderProps: any) => (
        !loading && <Button onClick={renderProps.onClick} type="button" color="google plus">
            <i className="fa fa-google mr-2"></i>
            Login with Google
        </Button>      
    )}
    buttonText="Login"
    isSignedIn={false}
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy={'single_host_origin'}
  />
        </div>
    )
}

export default GoogleLoginSegment
