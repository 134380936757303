import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {Container, Row, Col, Table, Button} from 'react-bootstrap';
import "../classes.css";
import ContentModal from '../../app/common/modals/ContentModal';
import AttendanceModal from '../lesson/AttendanceModal';

interface ClassParams {
  courseCode: string;
}

const initialValues = {
  courseCode: '',
  courseName: '',
  active: true
};

const AdminClassForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {courseContent, loadCourseContent} = rootStore.courseStore;
  const { openModal} = rootStore.modalStore;
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const loadValues = async() => {
      var course = await loadCourseContent(match.params.courseCode);
      if(course){     
        setValues({
          courseCode: course.courseCode,
          courseName: course.courseName,
          active: course.active
        }); 
      }
    }
    if(match.params.courseCode){
      loadValues();
    }
  }, [loadCourseContent, match.params.courseCode])


  return (
    <Container className="content">
      <div style={{display: 'flex'}}>
        <h2 className={` mr-auto ${values.active ? "active-title" : "inactive-title"}`}>Course: {values.courseName}</h2>
        <Link to={`/a-classes/info/${values.courseCode}`}>
          <Button variant="info" size="sm" className="mt-2 mb-2 mr-3">Back to Admin Course</Button>
        </Link>
      </div>
      
      <Row className="form-field" >
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Scheduled time
          </h3>
        </Col>
        <Col lg={9} md={9} sm={9}>
          <h3 style={{color: 'maroon'}}>
            {courseContent?.dayOfTheWeek} {courseContent?.timePeriod}
          </h3>
        </Col>        
      </Row>
      <Row className="form-field">        
      </Row>
      <div className="mt-3" style={{display: 'flex'}}>
          <h3 className="mr-auto" style={{color: 'magenta'}}>Notice
          </h3>
      </div>  
      <Row className="form-field" >
        <Col>
        <p className="wrap-line">{courseContent?.notice}</p>
        </Col>
      </Row>
    
      <div className='mt-3' style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color: 'darkgreen'}}>Lessons</h3>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Topic</th>            
            <th>Date</th>
            <th>Time Period</th>
            <th>Attendance</th>
            <th>Video</th>
            <th>Assignment</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.lessons?.slice().sort((a, b) => {
            if(a.date > b.date) return 1;
            else if(a.date < b.date) return -1;
            else if(a.timePeriod > b.timePeriod) return 1;
            else return -1;
          }).map((lesson, index) => 
            <tr key={lesson.id}>
            <td>
              {lesson.seqNo}          
            </td>
            <td>
              <Link to={`/lesson/a-content/${lesson.id}`}>
              {lesson.topic}
              </Link>              
            </td>             
            <td>{new Date(lesson.date).toISOString().substring(0, 10)}</td>
            <td>{lesson.timePeriod}</td>
            <td>
                <span className="clickable text-primary"
                onClick={() => openModal(`Attendance of Lesson ${lesson.seqNo}`, <AttendanceModal lessonId={lesson.id}/>, 'modal-pane-1')}>
                {lesson.numOfAttendances} students    
                </span>                                         
            </td>
            <td>
              {lesson.url && (
                <a href={lesson.url} target="_blank" rel="noopener noreferrer">video</a>
              )}
            </td>
            <td>
              {lesson.numOfAssignments > 0 && 
                <Link to={`/lesson/s-assignment/${lesson.id}`}>
                  Assignment
                </Link>
              }
            </td>
          </tr> 
          )}     
        </tbody>
      </Table>

      <div className='mt-3' style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color:'darkcyan'}}>Resources</h3>
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.courseResources?.map((courseResource, index) =>
            <tr key={courseResource.id}>
            <td>{index+1}</td>
            <td>
              {courseResource.url && (
                <a href={courseResource.url} target="_blank" rel="noopener noreferrer">{courseResource.title}</a>
              )}
              {!courseResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${courseResource.title}`, <ContentModal content={courseResource.content}/>, 'modal-pane-1')}>
                  {courseResource.title}
                </span>
              )}
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>
    </Container>
  );
}

export default observer(AdminClassForm)
