export const IntermediateClassTree = 
[
    {
        courseId: 201,
        courseName: 'Python I',
        courseLevel: 'Intermediate',
        Recommendation: 'Recommended for kids ages 9+',
        Preview: 
            "A step up from basics, Python I takes your child further by focusing on one sole language. Build a solid foundation for the future by learning how to use proper syntax and programming functions.",
        Description:
            " use a text document (?)",
        image: '/images/home/python_1.png',
        link: '/python-1'
    },
    {
        courseId: 202,
        courseName: 'Python II',
        courseLevel: 'Intermediate',
        Recommendation: 'Recommended for kids ages 9+',
        Preview: 
            "Python II delves deeper into the language. Learn how to efficiently use object-oriented programming to write more complex code. We recommend to taking Python I before taking Python II.",

        Description:
            " use a text document (?)",
        image: '/images/home/python_2.png',
        link: '/python-2'
    },
    {
        courseId: 203,
        courseName: 'Coding with Java',
        courseLevel: 'Intermediate',
        Recommendation: 'Recommended for kids ages 11+',
        Preview: 
            "Java is another object-oriented programing language; making it just as easy to learn as Python! Programmers usually have handful of languages under their belt, so join our Java class to increase your child's expertise! ",

        Description:
            " use a text document (?)",
        image: '/images/home/java_1.png',
        link: '/java-class'
    },
    {
      courseId: 205,
      courseName: 'Blender 3D',
      courseLevel: 'Intermediate-Advanced',
      Recommendation: 'Recommended for kids 10+ age',
      Preview: 
          "Create 3D computer graphics using the popular program Blender. Your child will be making game assests and animations! ",
      Description:
          " use a text document (?)",
      image: '/images/home/blender3d_1.png',
      link: '/blender'
  },
  {
      courseId: 206,
      courseName: 'Arduino',
      courseLevel: 'Intermediate-Advanced',
      Recommendation: 'Recommended for kids 10+ age',
      Preview: 
          "This course is a basic introductory course towards robot control and AIoT advanced courses. It is a basic robotics course for children with a certain programming and electrical foundation.",

      Description:
          " use a text document (?)",
      image: '/images/home/arduino_1.png',
      link: 'arduino'
  },
  {
      courseId: 207,
      courseName: 'Aircraft War: Scratch',
      courseLevel: 'Intermediate',
      Recommendation: 'Recommended for kids 9+ age',
      Preview: 
          "If your child is already familiar with Scratch, then this course will be the perfect challenge for them. Create a complex game using the simplicity of Scratch!",

      Description:
          " use a text document (?)",
      image: '/images/home/aircraft_war_1.png',
      link: '/aircraft-war-1'
  }, 
  {
      courseId: 208,
      courseName: 'Aircraft War Game: Web with Javascript',
      courseLevel: 'Intermediate-Advanced',
      Recommendation: 'Recommended for kids with coding experience.',
      Preview: 
          "Create an Aircraft War Game using html and Javascript! This version of the game will focus on web development, so we recommend taking this class with prior coding knowledge.",
      Description:
          " use a text document (?)",
      image: '/images/home/aircraft_war_2.png',
      link: '/aircraft-war-2'
  },
  {
      courseId: 209,
      courseName: 'Flappy Bird: Processing with Python',
      courseLevel: 'Intermediate-Advanced',
      Recommendation: 'Recommend completing Basic Programming course.',
      Preview: 
          "In this course, your child will use Processing with Python to recreate Flappy Bird. Again we recommend this course for those with prior coding experience.",

      Description:
          " use a text document (?)",
      image: '/images/home/processing_python_1.png',
      link: '/flappy-bird'
  }, 
  {
    courseId: 210,
    courseName: 'Making Game with Godot Game Engine',
    courseLevel: 'Intermediate-Advanced',
    Recommendation: 'Recommended for kids with coding experience.',
    Preview: 
        "Godot game engine is one of the most popular game engines for real-life games, and it's free and open-source. Students will learn how to make a full-fledged 2D platformer game like Super Mario game with Godot game engine.",
    Description:
        " use a text document (?)",
    image: '/images/home/godot_game_1.png',
    link: '/godot-game-1'
  },          
];