import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const TeacherGradeModal = ({problemId, userName, grade, teacherRemark}) => {
  const rootStore = useContext(RootStoreContext);
  const {setTeacherGrade} = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;
  const [newGrade, setNewGrade] = useState(grade);
  const [newRemark, setNewRemark] = useState(teacherRemark);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const handleChange = (event) => {
    const {name, value} = event.target;
    if(name === "grade") {
      setNewGrade(value);
      if(value !== grade || newRemark !== teacherRemark){
          setIsChanged(true);
      }
      else {
          setIsChanged(false);
      }
    }
    else {
      setNewRemark(value);
      if(newGrade !== grade || value !== teacherRemark){
          setIsChanged(true);
      }
      else {
          setIsChanged(false);
      }     
    }

  }
  const handleSubmit = (event) => {
    setSubmitting(true);
    setTeacherGrade(problemId, userName, newGrade, newRemark);
    setSubmitting(false);
    closeModal();
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Grade
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="grade" 
                value = {newGrade || ''}
                onChange={handleChange}
                placeholder="Grade">
                <option>Great</option>
                <option>Acceptable</option>
                <option>Problematic</option>
                <option>Unacceptable</option>
                <option></option>
              </Form.Control>
            </Col>
        </Row>         
        <Row className="form-field">              
              <Col lg={12} md={12} sm={12}>
              <Form.Control
                required
                as = "textarea"
                rows={5}
                className="full-control"
                name="remark"
                value = {newRemark || ''}
                onChange={handleChange}
              />
            </Col>
          </Row>  
          <Row className="d-flex justify-content-center pb-3 mt-3">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default TeacherGradeModal
