import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Row, Table, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./qa.css";
import AddQuestionModal from './AddQuestionModal';
import {convertUTCDateToLocalDataString } from '../helper/DateHelper';

interface ClassParams {
  courseCode: string;
}

const AssistantQAForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);  
    const {qaInfo, loadQATeacherInfo} = rootStore.qaStore;
    const {openModal} = rootStore.modalStore;

    useEffect(() => {
      const loadValues = async() => {
        await loadQATeacherInfo(match.params.courseCode);
      }
      if(match.params.courseCode) {
        loadValues();
      }
    }, [loadQATeacherInfo, match.params.courseCode])

  return (
    <div className="qa-page mt-3 p-3">
      <div style={{display: 'flex'}}>
        <h2 className='mr-auto active-title'>Q&A for class <span style={{color: 'magenta'}}>{qaInfo?.courseName}</span></h2>
        <Link to={`/h-classes/${match.params.courseCode}`}>
          <Button >Back to Class</Button>
        </Link>        
      </div>
      <Row> 
        <i className="fa fa-edit fa-2x ml-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("New Question", <AddQuestionModal courseCode={match.params.courseCode}/>, 'modal-pane-65')}>          
        </i>                     
      </Row>

      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>Student</th>
            <th>Date</th>
            <th>Private?</th>
            <th>Topic</th>
            <th>Last Replied</th>
            <th>Replied By</th>
            <th>Viewed</th>
          </tr>
        </thead>
        <tbody>
        {qaInfo?.questions.slice().sort((a, b) => a.date < b.date ? 1 : -1)
        .map((question, index) => 
        <tr key={index}>
          <td>{question.userFullName}</td>
          <td>{convertUTCDateToLocalDataString(question.date)}</td>
          <td>{question.isPrivate ? "Yes" : "No"}</td>
          <td>
            <Link to={`/h-class/question/${question.questionId}`}>
            {question.topic}
            </Link>
          </td>
          <td>
            {question.lastDateReplied ? convertUTCDateToLocalDataString(question.date) : ''}
          </td>
          <td>
            {question.lastReplier}
          </td>  
          <td>
            {question.viewed ? <i className="fa fa-check ml-3 mt-2 mb-2 text-info"/> : ''}
          </td>        
        </tr> 
        )}         
        </tbody>
      </Table>    
    </div>
  )
}

export default observer(AssistantQAForm)
