import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Card} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

const InquiriesForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {inquiries, getInquires, setInquiryChecked} = rootStore.inquiryStore;
  
  useEffect(() => {
    getInquires();
  }, [getInquires]);

  const checkInquiry = (id: string) => {
    setInquiryChecked(id);
  }

  return (
    <Container>
      {inquiries.slice().sort((a,b)=>{
             if(a.dateCreated > b.dateCreated) return -1;
             else return 1; 
      })
      .map((inquiry, index) => 
        <Card key={index}>
        <Card.Body>
          <Card.Title className="text-primary">{inquiry.topic}
          {!inquiry.checked &&
            <i className="fa fa-check-circle fa-2x ml-3 text-info clickable" onClick={() => checkInquiry(inquiry.id)}></i>
          }
          </Card.Title>
          {inquiry.phone && (
            <Card.Subtitle className="text-secondary">
              Phone: {inquiry.phone}
            </Card.Subtitle>
          )}
          {inquiry.email && (
            <Card.Subtitle className="text-secondary mt-2">
              Email: {inquiry.email}
            </Card.Subtitle>         
          )}
          <Card.Subtitle className="text-secondary mt-2">
            Date: {inquiry.dateCreated}
          </Card.Subtitle>
          <Card.Text className="text-info">
            {inquiry.description}
          </Card.Text>
        </Card.Body>
        </Card>
      )}

    </Container>
  )
}

export default observer(InquiriesForm)
