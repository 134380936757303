import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Container, Table} from 'react-bootstrap';
import './puzzle.css';

const NumberPuzzleRanks = () => {
  const rootStore = useContext(RootStoreContext);
  const { userRanks, getUserRanks } = rootStore.numberPuzzleStore;
  const { user } = rootStore.userStore;
  useEffect(() => {
    getUserRanks();
  }, [getUserRanks]);

  const getTitle = (number: number) => {
    if (number < 5) {
      return 'Infant';
    } else if (number < 10) {
      return 'Toddler';
    } else if (number < 20) {
      return 'Preschooler';
    } else if (number < 50) {
      return 'Elemantary Student';
    } else if (number < 100) {
      return 'Junior Student';
    } else if (number < 200) {
      return 'Senior Student';
    } else if (number < 500) {
      return 'Freshman';
    } else if (number < 1000) {
      return 'Sophomore';
    } else if (number < 2000) {
      return 'Junior';
    } else if (number < 3000) {
      return 'Senior';
    } else if (number < 4000) {
      return 'Master';
    } else if (number < 5000) {
      return 'Doctor';
    } else if (number < 6000){
      return 'Professor';      
    } else if(number < 6704) {
      return 'Grandmaster';
    }
    else {
      return 'Legend';
    }
  };

  return (
    <Container className="table-page">
      <h2 className="mt-2 mb-2 p-2 title">Number Puzzle User Ranks</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Title</th>
            <th>Puzzles solved</th>
            <th>Rank</th>
          </tr>
        </thead>
        <tbody>
          {userRanks &&
            userRanks.map(userRank => (
              <tr key={userRank.userName}
              style={{color: userRank.userName === user?.userName ? 'red' : 'black'}}
              >
                <td>{userRank.firstName + ' ' + userRank.lastName}</td>
                <td>{getTitle(userRank.number)}</td>
                <td>{userRank.number}</td>
                <td>{userRank.rank}</td>
              </tr>
            )
            )}
        </tbody>
      </Table>
    </Container>
  );
};

export default observer(NumberPuzzleRanks);
