import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Table} from 'react-bootstrap';
import CodeModal from '../../app/common/modals/CodeModal';
import ContentModal from '../../app/common/modals/ContentModal';
interface TopicParams {
  topicId: string;
}

const AssistantTopicForm: React.FC<RouteComponentProps<TopicParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {currentTopic, getTopic} = rootStore.topicStore;
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    const loadValues = async() => {
      await getTopic(match.params.topicId);
    }
    if(match.params.topicId) {
      loadValues();
    }
  }, [getTopic, match.params.topicId]);

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className='mr-auto active-title'>{currentTopic?.name}</h2>   
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>SeqNo</th>
            <th>Title</th>
            {currentTopic?.hasConcepts &&
              (<th>Concepts</th>)
            }
            {currentTopic?.hasAnalysis &&
              (<th>Analysis</th>)
            }
            {currentTopic?.hasCode &&
              (<th>Code</th>)
            }                        
          </tr>
        </thead>
        <tbody>
          {
          currentTopic?.topicItems?.sort((a, b) => {
            if (a.category > b.category) return 1;
            else if(a.category < b.category) return -1;
            else if(a.seqNo > b.seqNo) return 1;
            else return -1;
          }).map(topicItem => 
            <tr key={topicItem.id}>
              <td>{topicItem.category}              
              </td>
              <td>
              {!topicItem.seqNoLink && topicItem.seqNo}
                {topicItem.seqNoLink && (
                <a href={topicItem.seqNoLink} target="_blank" rel="noopener noreferrer">{topicItem.seqNo}</a>
                )}                              
              </td>
              <td>
              {topicItem.url && (
                <a href={topicItem.url} target="_blank" rel="noopener noreferrer">{topicItem.title}</a>
              )}             
              </td>
              {currentTopic?.hasConcepts &&
                (<td> {topicItem.concepts}                 
                </td>)
              }
              {currentTopic?.hasAnalysis &&
                (<td> 
                {topicItem.analysisUrl && (
                <a href={topicItem.analysisUrl} target="_blank" rel="noopener noreferrer">Analysis</a>
                )}
                {topicItem.analysisContent && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`Analysis of ${topicItem.title}`, <ContentModal content={topicItem.analysisContent}/>, 'modal-pane-1')}>
                    Analysis
                  </span>                
                )}                   
                </td>)
              }
              {currentTopic?.hasCode &&
                (<td>
                {topicItem.code  && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`Code for ${topicItem.title}`, 
                    <CodeModal code={topicItem.code} />, 'modal-pane-1')}>
                      Code
                    </span>           
                )}                  
                </td>)
              }                
            </tr>
          )}
        </tbody>
      </Table>  
      <div style={{display: 'flex'}}>   
      <h3 className='mr-auto text-info'>Total problems: {currentTopic?.topicItems?.length}</h3>         
      </div>                 
    </div>
  )
}

export default observer(AssistantTopicForm)
