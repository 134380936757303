import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';
import {
  IPythonProblem,
  IPythonTestCase
} from '../models/pythonProblem';
import agent from '../api/agent';
import { RootStore } from './rootStore';
import { history } from '../..';
import { toast } from 'react-toastify';

export default class PythonProblemStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable pythonProblemRegistry = new Map();
  @observable pythonProblem?: IPythonProblem;
  @observable editMode = false;
  @observable loading = false;
  @observable submitting = false;
  @observable target = -1;

  @computed get pythonProblems(): IPythonProblem[] {
    return Array.from(this.pythonProblemRegistry.values()).sort(
      (a, b) => a.seqNo - b.seqNo
    );
  }

  @computed get maxSeqNo(): number {
    if (this.pythonProblems && this.pythonProblems!.length > 0) {
      return Math.max.apply(
        Math,
        this.pythonProblems!.map(function(o) {
          return o.seqNo;
        })
      );
    } else {
      return 0;
    }
  }

  @action loadPythonProblems = async () => {
    this.loading = true;
    try {
      const pythonProblems = await agent.PythonProblems.list();
      runInAction(() => {
        pythonProblems.forEach(pythonProblem => {
          this.pythonProblemRegistry.set(pythonProblem.id, pythonProblem);
        });
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action openCreateForm = () => {
    this.editMode = true;
    this.pythonProblem = undefined;
  };

  @action loadPythonProblem = async (id: string) => {
    let pythonProblem = this.getPythonProblem(id);
    if (pythonProblem) {
      this.pythonProblem = pythonProblem;
      return toJS(pythonProblem);
    } else {
      this.loading = true;
      try {
        pythonProblem = await agent.PythonProblems.info(id);
        runInAction(() => {
          this.pythonProblem = pythonProblem;
          this.loading = false;
        });
        return pythonProblem;
      } catch (error) {
        runInAction(() => {
          this.loading = false;
        });
      }
    }
  };

  @action clearPythonProblem = () => {
    this.pythonProblem = undefined;
  };

  getPythonProblem(id: string): IPythonProblem {
    return this.pythonProblemRegistry.get(id);
  }

  @action createPythonProblem = async (pythonProblem: IPythonProblem) => {
    this.submitting = true;
    try {
      await agent.PythonProblems.create(pythonProblem);
      runInAction(() => {
        this.pythonProblemRegistry.set(pythonProblem.id, pythonProblem);
        this.pythonProblem = pythonProblem;
        this.editMode = false;
        this.submitting = false;
      });
      history.push(`/editpythonproblem/${pythonProblem.id}`);
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      toast.error('Problem creating python problem');
    }
  };

  @action createPythonTestCase = async (pythonTestCase: IPythonTestCase) => {
    this.submitting = true;

    try {
      await agent.PythonTestCases.create(pythonTestCase);
      runInAction(() => {
        this.pythonProblem!.pythonTestCases.push(pythonTestCase);
        this.submitting = false;
      });
      history.push(`/pythontestcases/${this.pythonProblem!.id}`);
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      toast.error('Problem creating python test case');
    }
  };

  @action solvePythonProblem = async (id: string, code: string) => {
    this.submitting = true;
    try {
      let result = await agent.PythonProblems.solve(id, code);

      if (result.success) {
        this.rootStore.modalStore.openModal('Success', result.message);
        var user = this.rootStore.userStore.user;
        runInAction(() => {
          this.pythonProblem?.pythonUserAnswers.push({
            firstName: user!.firstName,
            lastName: user!.lastName,
            city: user!.city,
            userName: user!.userName,
            email: user!.email,
            answer: code
          });
        });
        history.push('/pythonproblems');
      } else {
        this.rootStore.modalStore.openModal('failed', result.message);
      }
      runInAction(() => {
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  @action editPythonProblem = async (pythonProblem: IPythonProblem) => {
    this.submitting = true;
    try {
      await agent.PythonProblems.update(pythonProblem);
      runInAction(() => {
        this.pythonProblemRegistry.set(pythonProblem.id, pythonProblem);
        this.pythonProblem = pythonProblem;
        this.editMode = false;
        this.submitting = false;
      });
      history.push('/pythonproblems');      
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  @action editPythonTestCase = async (pythonTestCase: IPythonTestCase) => {
    this.submitting = true;
    try {
      await agent.PythonTestCases.update(pythonTestCase);
      runInAction(() => {
        this.pythonProblem!.pythonTestCases = this.replaceTestCase(
          this.pythonProblem!.pythonTestCases,
          pythonTestCase
        );
        this.submitting = false;
      });
      history.push(`/pythontestcases/${this.pythonProblem!.id}`);
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  @action deletePythonTestCase = async (id: string) => {
    this.submitting = true;
    try {
      await agent.PythonTestCases.delete(id);
      runInAction(() => {
        if(this.pythonProblem){
          this.pythonProblem.pythonTestCases = this.pythonProblem.pythonTestCases!.filter(x => x.id !== id);
        }
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  replaceTestCase(
    pythonTestCases: IPythonTestCase[],
    pythonTestCase: IPythonTestCase
  ): IPythonTestCase[] {
    var cases: IPythonTestCase[] = [];
    for (var i = 0; i < pythonTestCases.length; i++) {
      var oneCase = pythonTestCases[i];
      if (oneCase.id === pythonTestCase.id) {
        cases.push(pythonTestCase);
      } else {
        cases.push(oneCase);
      }
    }
    return cases;
  }

  @action selectPythonProblem = (id: string) => {
    this.pythonProblem = this.pythonProblemRegistry.get(id);
    this.editMode = false;
  };

  @action setEditMode = (editMode: boolean) => {
    this.editMode = editMode;
  };

  @action unSelectPythonProblem = () => {
    this.pythonProblem = undefined;
  };
}
