import React, {useState, useContext, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const initialValues = {
  title: '',
  deadline: new Date(Date.now() + 7*24*60*60*1000).toISOString().substring(0, 10),
  seqNo: 1,
  submitMode: 'Both'  
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const AddProblemAttachForm = ({lessonId}) => {
  const rootStore = useContext(RootStoreContext);
  const {addProblemAttach } = rootStore.assignmentStore;
  const {closeModal} = rootStore.modalStore;	  
  const [values, setValues] = useState(initialValues); 
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*, application/pdf, .zip', 
    maxFiles: 1,
    onDrop: acceptedFiles => {      
      // attach file
      setFile(acceptedFiles[0]);

    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    if(values.title.length > 0 && values.seqNo && file){
      setSubmitting(true);      
      addProblemAttach(lessonId, values.title, values.seqNo.toString(), 
      values.deadline, values.submitMode, file)
      .finally(()=>{
        setSubmitting(false);
      });
      closeModal();
    }
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {values.title}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
               SeqNo
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="number"
                name="seqNo"
                value = {values.seqNo}
                onChange={handleChange}
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              deadline
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              required
              type="date"
              name="deadline"
              value = {values.deadline}
              onChange={handleChange}
            />
          </Col>
        </Row> 
        <Row className="form-field">
              <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Submit Mode
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control 
                required 
                as="select" 
                name="submitMode" 
                value = {values.submitMode}
                onChange={handleChange}
                placeholder="Submit Mode">
                <option>Code Only</option>
                <option>Attach Only</option>
                <option>Both</option>
              </Form.Control>
              </Col>
          </Row>        
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop one file here, or click to select one file</p>
          }
          <p>Only image/pdf/zip file is allowed</p>
        </div>
        <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Attachment
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {file && file.name}
            />
          </Col>    
        </Row>       
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          values.title && values.seqNo && file &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default AddProblemAttachForm
