export const keyImages = [
    'zero_g',
    'zero_b',
    'zero_h',
    'one_g',
    'one_b',
    'one_h',
    'two_g',
    'two_b',
    'two_h',
    'three_g',
    'three_b',
    'three_h',
    'four_g',
    'four_b',
    'four_h',
    'five_g',
    'five_b',
    'five_h',
    'six_g',
    'six_b',
    'six_h',
    'seven_g',
    'seven_b',
    'seven_h',
    'eight_g',
    'eight_b',
    'eight_h',
    'nine_g',
    'nine_b',
    'nine_h'
];

export const opImages = [
    'plus_b',
    'plus_h',
    'minus_b',
    'minus_h',
    'multiply_b',
    'multiply_h',
    'divide_b',
    'divide_h',
    'lBracket_b',
    'lBracket_h',
    'rBracket_b',
    'rBracket_h',
    'bDelete_b',
    'bDelete_h',
    'clean_b',
    'clean_h'
];