import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {IStudent} from '../../app/models/student';
import {Container, Row, Col, Form, Table, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SplitPane from 'react-split-pane';

import "../classes.css";

interface ClassParams {
  courseCode: string;
}
const initialStudents: IStudent[] = [];

const ClassStudents: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);
    const {loadCourse, addCourseStudent, removeCourseStudent} = rootStore.coursesStore;
    const {students, loadStudents} = rootStore.studentStore;
    const [courseStudents, setCourseStudents] = useState(initialStudents);
    const [searchTerm, setSearchTerm] = useState('');
    const [btmHeight, setBtmHeight] = useState(100);
    useEffect(() => {
      const loadCourseStudents = async() => {
        var course = await loadCourse(match.params.courseCode);
        if(course) {
          setCourseStudents(course.students);
        } 
      };
      if(match.params.courseCode){
        loadCourseStudents();
        loadStudents();      
      }
    }, [loadCourse, setCourseStudents, loadStudents, match.params.courseCode]);

    const toggleBtmHeight = (topPaneHeight: any) => {
      const maxHeight = 500;
      const padding = 25;
      const btmPaneHeight = maxHeight - topPaneHeight - padding;
      setBtmHeight(btmPaneHeight);
    }
    const handleChange = (event: any) => {
      setSearchTerm(event.target.value);
    }

    const addStudent = (userName: any, e: any) => {
      addCourseStudent(match.params.courseCode, userName);
      var student = students.find(x=>x.userName === userName);
      if(student){
        setCourseStudents([...courseStudents, student]);
      }
    };

    const removeStudent = (userName: any, e: any) => {
      removeCourseStudent(match.params.courseCode, userName);
      setCourseStudents(courseStudents.filter(x=>x.userName !== userName));
    };

  return (
    <Container fluid style={{ marginTop: '2em'}} >
      <div className="mb-3">
        <Row>
          <Col lg={2}>
          <Form.Control type="text" value={searchTerm} placeholder="Search..." onChange={handleChange}>
            </Form.Control>
          </Col>
          <Link to={`/a-classes/info/${match.params.courseCode}`} className="ml-5">
            <Button>Back to class</Button>
          </Link>
        </Row>

      </div>

      <SplitPane split="horizontal" defaultSize="50%" className="split-pane" style={{position: "relative"}} onChange={size=>toggleBtmHeight(size)}>
      <Table striped bordered hover className="mb-3 split-pane-1">
        <thead>
          <tr>
            <th style={{width: "5rem"}}>#</th>
            <th style={{width: "20rem"}}>Full Name</th>
            <th style={{width: "5rem"}}>Age</th>
            <th style={{width: "20rem"}}>Parent</th>
            <th style={{width: "8rem"}}>Phone</th>
            <th style={{width: "15rem"}}>Wechat</th>
            <th style={{width: "15rem"}}>Email</th>
            <th style={{width: "5rem"}}>Add</th>
          </tr>
        </thead>
        <tbody>
          {students            
            .filter(student => 
              !courseStudents.some(x => x.userName === student.userName)
            )
            .filter(user => {
              if(searchTerm === ""){
                return true;
              } else if(user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        user.lastName.toLowerCase().includes(searchTerm.toLowerCase())){
                return true;
              } else{
                return false;
              }
            })            
            .map((student, index) => 
            <tr key={student.userName}>
            <td>{index+1}</td>
            <td>{student.firstName+' ' + student.lastName}</td>
            <td>{student.age}</td>
            <td>{(student.parentFirstName == null ? '' : student.parentFirstName) + ' ' 
                  + (student.parentLastName == null ? '' : student.parentLastName)}</td>
            <td>{student.phone}</td>
            <td>{student.wechat}</td>
            <td>{student.email}</td>
            <td><i className="fa fa-plus-circle fa-2x mr-3 text-info clickable" onClick={(e) => addStudent(student.userName, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
      <Table striped bordered hover className="mb-3 split-pane-2" style={{height: {btmHeight}+"px"}}>
        <thead>
          <tr>
            <th style={{width: "5rem"}}>#</th>
            <th style={{width: "20rem"}}>Full Name</th>
            <th style={{width: "5rem"}}>Age</th>
            <th style={{width: "20rem"}}>Parent</th>
            <th style={{width: "8rem"}}>Phone</th>
            <th style={{width: "15rem"}}>Wechat</th>
            <th style={{width: "15rem"}}>Email</th>
            <th style={{width: "5rem"}}>Remove</th>
          </tr>
        </thead>
        <tbody>
          {courseStudents
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })          
          .map((student, index) => 
            <tr key={student.userName}>
            <td>{index+1}</td>
            <td>{student.firstName+' ' + student.lastName}</td>
            <td>{student.age}</td>
            <td>{(student.parentFirstName == null ? '' : student.parentFirstName) + ' ' 
                  + (student.parentLastName == null ? '' : student.parentLastName)}</td>
            <td>{student.phone}</td>
            <td>{student.wechat}</td>
            <td>{student.email}</td>
            <td><i className="fa fa-trash fa-2x mr-3 text-warning clickable" onClick={(e) => removeStudent(student.userName, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
    </SplitPane>

    </Container>
  )
}

export default observer(ClassStudents)
