import React from 'react'
import parse from 'html-react-parser';
import { Container, Card } from 'react-bootstrap';

const LessonResourceModal = ({content}) => {
  return (
    <Container>

    <Card>
      <Card.Body>
        <p className='p-scroll'>
          {content && parse(content)}
        </p>
      </Card.Body>
    </Card>

    </Container>    
  )
}

export default LessonResourceModal
