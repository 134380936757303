export interface IUser {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    city: string;
    email: string;
    token: string;
    roles: string[];
}

export interface IUserFormValues {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
}

export interface IChangePasswordValues {
    oldpassword: string;
    newpassowrd: string;
    repassword: string;
}

export interface ICourseBase{
    id: string;
    name: string;
}

export interface IAppUser {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    city: string;
    email: string;
    age: string;
    grade: string;
    parentEmail: string;
    parentFirstName: string;
    parentLastName: string;
    phone: string;
    wechat: string;
    introduction: string;
    active: boolean;
    roles: string[];
    studentCourses: ICourseBase[],
    teacherCourses: ICourseBase[] 
}

export class AppUser implements IAppUser {
    id: string = '';
    userName: string = '';
    firstName: string = '';
    lastName: string = '';
    city: string = '';
    email: string = '';
    age: string = '';
    grade: string = '';
    parentEmail: string = '';
    parentFirstName: string = '';
    parentLastName: string = '';
    phone: string = '';
    wechat: string = '';
    introduction: string = '';
    active: boolean = true;
    roles: string[] = [];
    studentCourses: ICourseBase[] = [];
    teacherCourses: ICourseBase[] = [];
    constructor(init?: AppUser) {
        Object.assign(this, init);
    } 
}

export interface IUserInfo {
  id: string; 
  userName: string;
  firstName: string;
  lastName: string;
  city: string;
  age: string;
  grade: string;
  parentEmail: string;
  parentFirstName: string;
  parentLastName: string;  
  phone: string;
  wechat: string;
  introduction: string;
  active: boolean;
  roles: string[];  
}
export class UserInfo implements IUserInfo {
  id: string = '';  
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  city: string = '';
  age: string = '';
  grade: string = '';
  parentEmail: string = '';
  parentFirstName: string = '';
  parentLastName: string = '';  
  phone: string = '';
  wechat: string = '';
  introduction: string = '';
  active: boolean = true;
  roles: string[] = [];  
}

export interface IUserProfile {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  city: string;
  age: string;
  grade: string;
  parentEmail: string;
  parentFirstName: string;
  parentLastName: string;    
  phone: string;
  wechat: string;
  introduction: string;
}
export class UserProfile implements IUserProfile {
  id: string = '';
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  city: string = '';
  age: string = '';
  grade: string = '';
  parentEmail: string = '';
  parentFirstName: string = '';
  parentLastName: string = '';  
  phone: string = '';
  wechat: string = '';
  introduction: string = '';
}