import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { LoadingComponent } from '../../app/layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import {Container, Table, Row, Col, Form} from 'react-bootstrap';
import UserModal from './UserModal';
import SetParentModal from './SetParentModal';
import CoursesTakenModal from './CoursesTakenModal';
import CheckChildModal from './CheckChildModal';
import SetRolesModal from './SetRolesModal';
import "./users.css";
import ResetPasswordModal from './ResetPasswordModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const UsersForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { users, loadUsers, loading, setUserActive, removeUser } = rootStore.usersStore;
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchRole, setSearchRole] = useState('');
  const [searchActive, setSearchActive] = useState(true);
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const filterNameChange = (event: any) => {
    setSearchName(event.target.value);
  };

  const filterEmailChange = (event: any) => {
    setSearchEmail(event.target.value);
  };

  const filterRoleChange = (event: any) => {
    setSearchRole(event.target.value);
  };

  const filterActiveChange = (event: any) => {
    setSearchActive(event.target.checked);
  };

  const filteredUsers = () => {
    return users.filter(user => {
      if(searchName === ""){
        return true;
      } else if(user.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
                user.lastName.toLowerCase().includes(searchName.toLowerCase())){
        return true;
      } else{
        return false;
      }
    })
    .filter(user => {
      if(searchEmail === ""){
        return true;
      } else if(user.email.toLowerCase().includes(searchEmail.toLowerCase())){
        return true;
      } else{
        return false;
      }
    })    
    .filter(user=>{
      if(searchRole === ""){
        return true;
      } else {
        return user.roles.includes(searchRole);
      }
    })
    .filter(user=>{
      if(searchActive){
        return user.active;
      } else{
        return true;
      }
    })
    .map(user=> ({
      id: user.id,
      fullName: user.firstName + ' ' + user.lastName,
      userName: user.userName,
      email: user.email,
      age: user.age,
      parentFirstName: user.parentFirstName,
      parentLastName: user.parentLastName,
      parentFullName: (user.parentFirstName == null ? '':user.parentFirstName) 
        + ' ' + (user.parentLastName == null ? '' : user.parentLastName),
      parentEmail: user.parentEmail,
      phone: user.phone,
      wechat: user.wechat,
      city: user.city,
      roles: user.roles.toString(),
      active: user.active? 'Yes' : 'No'
    }));
  };

  const handleSetActive = (userName: string, active: boolean) => {
    setUserActive(userName, active);
  }

  const handleDeleteUser = (userName: string, fullName: string) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: `Are you sure you want to delete user ${fullName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeUser(userName)        
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  }

  if (loading) return <LoadingComponent content="Loading users..." />;
  return (
    <Container fluid style={{ marginTop: '2em' }}>
      <Row className="mb-3">
        <Col md="auto" className="search-item">
        <Form.Label >
            Name
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchName} placeholder="Search..." onChange={filterNameChange}>
          </Form.Control>
        </Col>
        <Col md="auto" className="search-item">
        <Form.Label >
            Email
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchEmail} placeholder="Search..." onChange={filterEmailChange}>
          </Form.Control>
        </Col>        
        <Col md="auto" className="search-item">
          <Form.Label className="ml-5">
            Role
          </Form.Label>
          </Col>
          <Col lg={2} md={2} sm={2}>
          <Form.Control 
            as="select" 
            name="role" 
            value={searchRole}
            onChange={filterRoleChange}
            >
              <option key={""}></option>
              <option key={"Student"}>Student</option>
              <option key={"Teacher"}>Teacher</option>    
              <option key={"Parent"}>Parent</option>    
              <option key={"Assistant"}>Assistant</option>    
              <option key={"CourseAdmin"}>CourseAdmin</option>     
              <option key={"Admin"}>Admin</option>                             
          </Form.Control>
        </Col> 
        <Col lg={2} md={2} sm={2} className="search-item">
            <Form.Check type="checkbox" label="Active Only" defaultChecked={true}
            name="active" onChange={filterActiveChange}/>
        </Col>                       
      </Row>
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Age</th>
            <th>Parent Full Name</th>
            <th>Parent Email</th>
            <th>Phone</th>
            <th>Wechat</th>
            <th>City</th>
            <th>Roles</th>
            <th>&nbsp;&nbsp;Active&nbsp;&nbsp;</th>
            <th>Operations</th>
          </tr>          
        </thead>
        <tbody>
          {filteredUsers().map(user=>
            <tr key={user.id}>
              <td>
              <span
                  className={`${user.active === "Yes" ? "clickable text-primary" : "clickable text-secondary"}`}
                  onClick={() => openModal(`Profile of ${user.fullName}`, <UserModal userName={user.userName}/>, 'modal-pane-1')}>
                    {user.fullName}    
                  </span>                 
              </td>
              <td>{user.email}                 
              </td>
              <td>{user.age}</td>
              <td>{user.parentFullName}
              {user.roles.includes("Student") && (<i className="fa fa-edit ml-2 text-info clickable icon-size" 
                  onClick={() => openModal(`Set Parent for ${user.fullName}`, 
                  <SetParentModal userName={user.userName} parentEmail={user.parentEmail} parentFirstName={user.parentFirstName} parentLastName={user.parentLastName}/>, 'modal-pane-1')}>          
                </i>
              )}             
              </td>
              <td>{user.parentEmail}</td>
              <td>{user.phone}</td>
              <td>{user.wechat}</td>
              <td>{user.city}</td>
              <td>{user.roles}
                <i className="fa fa-edit ml-2 text-info clickable icon-size" 
                  onClick={() => openModal(`Set Roles for ${user.fullName}`, <SetRolesModal userName={user.userName} roles={user.roles}/>, 'modal-pane-1')}>          
                </i>  
                {user.roles.includes("Student") && (
                  <i className="fa fa-info-circle ml-2 text-info clickable icon-size" 
                  onClick={() => openModal(`Courses taken by ${user.fullName}`, <CoursesTakenModal userName={user.userName}/>, 'modal-pane-1')}>   
                  </i>
                )}
                {user.roles.includes("Parent") && (
                  <i className="fa fa-info-circle ml-2 bg-info text-white clickable icon-size" 
                  onClick={() => openModal(`Courses taken by ${user.fullName}'s child`, <CheckChildModal userName={user.userName}/>, 'modal-pane-1')}>   
                  </i>
                )}                             
              </td>
              <td className="nowrap">{user.active}
                {user.active === "Yes" && (
                  <i className="fa fa-minus-circle ml-2 text-secondary clickable icon-size" onClick={() => handleSetActive(user.userName, false)}></i>
                )}
                {user.active !== "Yes" && (
                  <i className="fa fa-plus-circle ml-2 text-primary clickable icon-size" onClick={() => handleSetActive(user.userName, true)}></i>      
                )}              
              </td>
              <td>
                <i className="fa fa-registered ml-2 text-warning clickable icon-size" 
                  onClick={() => openModal(`Reset password for ${user.fullName}`, <ResetPasswordModal userName={user.userName}/>, 'modal-pane-1')}>   
                </i>
                <i className="fa fa-trash ml-2 text-danger clickable icon-size" 
                  onClick={() => handleDeleteUser(user.userName, user.fullName)}>   
                </i>              
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <h3 className='mr-auto text-info mb-3'>Total Users: {filteredUsers().length}</h3>       
    </Container>      
  );
};

export default observer(UsersForm);
