import React from 'react'
import { Container } from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";

const CodeModal = ({code}) => {
  return (
    <Container>  
      {code && (
        <Editor value={code}
          onValueChange = {() => {}}
          highlight={code => highlight(code, languages.js)}
          padding={5}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            backgroundColor: 'aliceblue'
          }}
        />
      )}       
    </Container>
  )
}

export default CodeModal
