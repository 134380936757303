import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ILessonContent } from '../models/lesson';

export default class LessonStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable lessonContent: ILessonContent | null = null;
  
  @action loadAttendances = async (lessonId: string) => {
    this.loading = true;
    try{
      var attendances = await agent.Lesson.getAttendances(lessonId);
      runInAction(() => {
        this.loading = false;
      });
      return attendances;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action setAttendance = async(lessonId: string, userName: string, attendingCode: number) => {
    this.loading = true;
    try{
      await agent.Lesson.setAttendance(lessonId, userName, attendingCode);
      runInAction(() => {
        this.loading = false;
      });    
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action loadLesson = async (lessonId: string) => {
    this.loading = true;
    try{
      var lesson = await agent.Lesson.getLesson(lessonId);
      runInAction(() => {
        this.loading = false;
      });
      return lesson;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action saveVideoUrl = async(lessonId: string, url: string) => {
    this.loading = true;
    try{
      await agent.Lesson.setVideoUrl(lessonId, url);
      runInAction(() => {
        this.loading = false;
        this.rootStore.modalStore.closeModal();   
      }); 
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  } 
  
  @action setLesson = async(lessonId: string, seqNo: string, topic: string, date: string, timePeriod: string) => {
    this.loading = true;
    try{
      await agent.Lesson.setLesson(lessonId, seqNo, topic, date, timePeriod);
      runInAction(() => {
        if(this.lessonContent){
          this.lessonContent.seqNo = parseInt(seqNo);
          this.lessonContent.topic = topic;
          this.lessonContent.date = new Date(date);
          this.lessonContent.timePeriod = timePeriod;
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();   
      }); 
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  }

  @action addLessonResourceText= async(lessonId: string, title: string, content: string) => {
    this.loading = true;
    try {
      var url = '';
      var lessonResource = await agent.Lesson.addResource(lessonId, title, url, content);
      runInAction(() => {
        this.loading = false;
        if(this.lessonContent && this.lessonContent.lessonId === lessonId)
        {
          this.lessonContent.lessonResources.push(lessonResource);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };  

  @action addLessonResourceLink= async(lessonId: string, title: string, url: string) => {
    this.loading = true;
    try {
      var content = '';
      var lessonResource = await agent.Lesson.addResource(lessonId, title, url, content);
      runInAction(() => {
        this.loading = false;
        if(this.lessonContent && this.lessonContent.lessonId === lessonId)
        {
          this.lessonContent.lessonResources.push(lessonResource);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action addLessonResourceAttach= async(lessonId: string, filename: string,  blob: Blob) => {
    this.loading = true;
    try {
      var lessonResource = await agent.Lesson.addResourceAttach(lessonId, filename, blob);
      runInAction(() => {
        this.loading = false;
        if(this.lessonContent && this.lessonContent.lessonId === lessonId)
        {
          this.lessonContent.lessonResources.push(lessonResource.data);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action removeResource= async(lessonId: string, resourceId: string) => {
    this.loading = true;
    try {
      await agent.Lesson.removeResource(lessonId, resourceId);
      runInAction(() => {
        if(this.lessonContent && this.lessonContent.lessonId === lessonId)
        {
          this.lessonContent.lessonResources = this.lessonContent.lessonResources.filter(x=>x.id !== resourceId);
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }; 

  @action loadLessonStudentContent = async (lessonId: string) => {
    this.loading = true;
    try {
      var lt = await agent.Lesson.lessonStudentContent(lessonId);
      runInAction(() => {
        this.lessonContent = lt;
        this.loading = false;
      });
      return lt;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };

  @action loadLessonContent = async (lessonId: string) => {
    this.loading = true;
    try {
      var lt = await agent.Lesson.lessonContent(lessonId);
      runInAction(() => {
        this.lessonContent = lt;
        this.loading = false;
      });
      return lt;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };

  @action setResourceSeqNo = async(resourceId: string, seqNo: number) => {
    this.loading = true;
    try {
      await agent.Lesson.setResourceSeqNo(resourceId, seqNo);
      runInAction(() => {
        if (this.lessonContent) {
          var resource = this.lessonContent.lessonResources.find(x=>x.id === resourceId);
          if(resource){
            resource.seqNo = seqNo;
          }
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });      
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  }  
}