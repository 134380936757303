import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetVideoModal = ({lessonId, url}) => {
  const rootStore = useContext(RootStoreContext);
  const {saveVideoUrl} = rootStore.lessonStore;
  const {courseContent} = rootStore.courseStore;
  const [newUrl, setNewUrl] = useState(url);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (event) => {
    const {value} = event.target;
    setNewUrl(value);
    if(value && value !== url) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }
  const handleSubmit = (event) => {
    setSubmitting(true);
    saveVideoUrl(lessonId, newUrl);
    if(courseContent){
      var lesson = courseContent?.lessons.find(x=>x.id === lessonId);
      if(lesson){
        lesson.url = newUrl;
      }      
    }
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                video link
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="text"
                name="timeperiod"
                value = {newUrl}
                onChange={handleChange}
              />
            </Col>
          </Row>  
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetVideoModal
