import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const HomeAbout = () => {
    return (
        <div className="home-about">
            
        <h2 className="homeCourseTitle"> <b>ABAITC</b> </h2>
        <h4> Alberta Junior AI Training Center </h4>

        <Container className="aboutinfo">
            <div className="black-line" />      
            <Row className="informationBox">
                <Col sm={6}>
                <h4> <i className="fa fa-info-circle text-info"></i>  Who We Are</h4>
                <br/>
                <p className="aboutinfoText">
                    ABAITC is the programming training center for kids. Our programs include year-around 
                    weekly classes for students. We also run summer camps and provide spring-break special 
                    program. Classes come in a  
                    variety of different skill levels to cater to your child's skills as well as encouraging
                    growth. We are based in Alberta, Canada with physical locations in Edmonton and Calgary. 
                </p>
                </Col>

                <Col sm={6}>
                <h4> <i className="fa fa-info-circle text-info"></i> Why Choose Us</h4>
                <br/>
                <p className="aboutinfoText">
                    Our goal is to help your child become skilled at programming. We have a teaching staff 
                    of well educated and skilled individuals, most are professionals in the coding industry
                    and have years of knowledge to share. ABAITC also prides itself in excellent coordination and
                    communication, thankful to our Director and Course Coordinator, June Wang.
                </p>
                </Col>
            </Row>
        </Container>
        </div>
    )
}

export default HomeAbout