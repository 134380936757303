import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { AppUser } from '../../app/models/user';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const UserModal = ({userName}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadUser, setUserInfo} = rootStore.usersStore;
  const [user, setUser] = useState(new AppUser());

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadValues = async() => {
      setLoading(true);
      var u = await loadUser(userName);
      setUser(new AppUser(u));          
      setLoading(false);
    }
    if (userName) {
      loadValues();
    }
  }, [loadUser, userName]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target; 
    if (name === "active") {
      setUser({...user, [name]: checked});  
    }
    else{
      setUser({...user, [name]: value});       
    }
  };

  const handleSubmit = () => {
    var userInfo = {
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      city: user.city,
      age: user.age,
      grade: user.grade,
      phone: user.phone,
      wechat: user.wechat,
      active: user.active,      
      parentFirstName: user.parentFirstName,
      parentLastName: user.parentLastName,
      introduction: user.introduction
    };

    setUserInfo(userInfo);
  };

  return (
    <Container className="content">
      <Row className="mt-2 ml-1">
        <h2 className={` mr-auto ${user.active ? "active-title" : "inactive-title"}`}>Profile of {user.firstName + ' ' + user.lastName}</h2>              
      </Row>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Email
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={user.email} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Check type="checkbox" label="Active" checked={user.active} 
            name="active" onChange={handleChange}/>
          </Col>
        </Row>
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                First Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="firstName"
                value = {user.firstName}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Last Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="lastName"
                value = {user.lastName}
                onChange={handleChange}
              />
              </Col>
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Age
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="age"
                value = {user.age || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Grade
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="grade"
                value = {user.grade || ''}
                onChange={handleChange}

              />
              </Col>
        </Row>   
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Phone
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="phone"
                value = {user.phone || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Wechat
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="wechat"
                value = {user.wechat || ''}
                onChange={handleChange}
              />
              </Col>
        </Row>              
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            City
          </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            type="text" 
            name="city" 
            value = {user.city || ''}
            onChange={handleChange}
            >
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Parent Email
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="parentEmail"
                value = {user.parentEmail || ''}
                readOnly
              />
              </Col>          
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Parent FirtName
          </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            type="text" 
            name="parentFirstName" 
            value = {user.parentFirstName || ''}
            onChange={handleChange}            
            >
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Parent LastName
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="parentLastName"
                value = {user.parentLastName || ''}
                onChange={handleChange}                
              />
              </Col>          
        </Row>        
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Introduction
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={8} className="full-control" name="introduction" value={user.introduction || ''} 
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
        </Row>         
        <Row className="d-flex justify-content-center pb-3 mt-5">
        {!loading && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {loading && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  );
};

export default UserModal;
