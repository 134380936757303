import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ICourse, INewCourse, ICourseInfo } from '../models/course';
import { history } from "../..";

export default class CoursesStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable courses: ICourseInfo[] = [];
  
  @action loadCourses = async() => {
      this.loading = true;
      try {
          var cs = await agent.Courses.list();
          runInAction(() => {
              this.courses = cs;
              this.loading = false;
          });
          return cs;
      } catch (error){
          runInAction(() => {
              this.loading = false;
          });
      }
  };
  
  @action loadActiveCourses = async() => {
    this.loading = true;
    try {
        var cs = await agent.Courses.activelist();
        runInAction(() => {
            this.courses = cs;
            this.loading = false;
        });
        return cs;
    } catch (error){
        runInAction(() => {
            this.loading = false;
        });
    }
  };

  @action createNewCourse = async(course: INewCourse) => {
    try {
      await agent.Courses.createNewCourse(course);
      history.push('/a-classes');
    } catch(error){
      runInAction(() => {
        throw error;
      })    
    }
  };

  @action loadCourse = async (courseCode: string) => {
      this.loading = true;
      try{
        var course = await agent.Courses.info(courseCode);
        runInAction(() => {
          this.loading = false;
        });
        return course;
      } catch(error){
        runInAction(() => {
          this.loading = false;
        });          
      }      
  };

  @action editCourse = async(course: ICourse) => {
    try {
      await agent.Courses.editCourse(course);
    } catch(error){
      runInAction(() => {
        throw error;
      })    
    }
  }

  @action toggleCourseActive = async(courseCode: string, active: boolean) => {
    this.loading = true;
    try{
      await agent.Courses.toggleActive(courseCode, active);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action addCourseAssistant = async(courseCode: string, userName: string) => {
    this.loading = true;
    try{
      await agent.Courses.addCourseAssistant(courseCode, userName);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action removeCourseAssistant= async(courseCode: string, userName: string) => {
    this.loading = true;
    try{
      await agent.Courses.removeCourseAssistant(courseCode, userName);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action addCourseStudent = async(courseCode: string, userName: string) => {
    this.loading = true;
    try{
      await agent.Courses.addCourseStudent(courseCode, userName);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action removeCourseStudent = async(courseCode: string, userName: string) => {
    this.loading = true;
    try{
      await agent.Courses.removeCourseStudent(courseCode, userName);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }
}