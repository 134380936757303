import React, { Fragment, useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Table, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import SubmitCodeModal from '../lesson/SubmitCodeModal';
import SubmitAttachModal from '../lesson/SubmitAttachModal';
import AssignmentProblemModal from './AssignmentProblemModal';
import LastSubmitModal from '../lesson/LastSubmitModal';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import './assignment.css';
import AssignmentResourceModal from './AssignmentResourceModal';

interface LessonParams {
  lessonId: string;
}

const StudentAssignmentForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);
    const {studentAssignmentContent, loadStudentAssignmentContent } = rootStore.assignmentStore;
    const { openModal} = rootStore.modalStore;
     
    useEffect(() => {
      const loadValues = async() => {
        await loadStudentAssignmentContent(match.params.lessonId);   
      }
      if(match.params.lessonId){
        loadValues();
      }
    }, [loadStudentAssignmentContent, match.params.lessonId]);
    
    return (
      <div className="content">
        <Row>
          <h3 className="mr-auto"><span style={{color: 'red'}}>Assignment {studentAssignmentContent?.seqNo} </span> of Course: {studentAssignmentContent?.courseName}</h3>
          <Link to={`/s-classes/${studentAssignmentContent?.courseCode}`} className="mr-3">
            <Button >Back to Course</Button>
          </Link>  
        </Row>
        <Row>
          <h4 className="text-info">Date: {studentAssignmentContent && (new Date(studentAssignmentContent.date)).toISOString().substr(0, 10)}</h4>
        </Row>
        <Row>
          <h4 className="text-info mr-auto">TimePeriod: {studentAssignmentContent?.timePeriod}</h4>     
        </Row>  
  
        <Table striped bordered className="mt-3">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Deadline</th>  
              <th>Analysis</th> 
              <th>Discuss</th>           
              <th>Last Submit</th>
              <th>Submit</th>
            </tr>
          </thead>
          <tbody>
            {studentAssignmentContent?.studentAssignments?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
            .map(assignment =>
            <tr key={assignment.id}>
              <td>{assignment.seqNo}</td>
              <td>
                {assignment.url && (
                  <a href={assignment.url} target="_blank" rel="noopener noreferrer">{assignment.title}</a>     
                )}
                {!assignment.url && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`problem ${assignment.title}`, <AssignmentProblemModal title={assignment.title} description={assignment.description}/>, 'modal-pane-1')}>
                    {assignment.title}    
                  </span>                        
                )}
              </td>
              <td>
              {moment(assignment.deadline).format('YYYY-MM-DD')}
            </td>
            <td>
              {assignment.analysisUrl && (
                <a href={assignment.analysisUrl} target="_blank" rel="noopener noreferrer">Video</a>     
              )}           
            </td>            
            <td>
              {assignment.courseTopicId && assignment.topicItemId && (
                <Link to={`/coursetopics/discuss/${assignment.courseTopicId}/${assignment.topicItemId}/${assignment.title}`}>
                <span className="clickable text-primary">Discuss</span>
              </Link>
              )}
              {(!assignment.courseTopicId || !assignment.topicItemId) && (
                <Link to={`/lesson/discuss/${assignment.id}/${assignment.title}`}>
                  <span className="clickable text-primary">Discuss</span>
                </Link>
              )}
            </td>              
              <td>
                {(assignment.code || assignment.attachment) && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`last submit for ${assignment.title}`, 
                    <LastSubmitModal studentRemark={assignment.studentRemark} 
                    grade = {assignment.grade}
                    teacherRemark={assignment.teacherRemark} 
                    code={assignment.code} attachment={assignment.attachment} attachmentUrl = {assignment.attachmentUrl}/>, 'modal-pane-1')}>
                      Last Submit
                    </span>           
                )}
              </td>
              <td>
              {(new Date(assignment.deadline)) > (new Date()) && (assignment.submitMode === 'Code Only' || assignment.submitMode === 'Both') &&
                <i className="fa fa-code fa-2x mr-3 text-info clickable" 
                  onClick={() => openModal(`Submit code for ${assignment.title}`, <SubmitCodeModal problemId={assignment.id}/>, 'modal-pane-1')}>          
                </i> 
              }
              {(new Date(assignment.deadline)) > (new Date()) && (assignment.submitMode === 'Attach Only' || assignment.submitMode === 'Both') &&
                <i className="fa fa-paperclip fa-2x mr-3 text-info clickable" 
                  onClick={() => openModal(`Submit attachment for ${assignment.title}`, <SubmitAttachModal problemId={assignment.id}/>, 'modal-pane-1')}>          
                </i> 
              }
              </td>
            </tr>
              )}
          </tbody>
        </Table> 
        {studentAssignmentContent?.resources && studentAssignmentContent?.resources.length > 0 && 
        <Fragment>
        <div className='mt-5' style={{display: 'flex'}}>
        <h4 className='mr-auto' style={{color:'darkcyan'}}>Resources</h4>
        </div>
        <Table striped bordered hover className="mb-b">
          <thead>
            <tr>
              <th>#</th>
              <th>title</th>
            </tr>
          </thead>
          <tbody>
            {studentAssignmentContent?.resources?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
            .map(assignmentResource =>
              <tr key={assignmentResource.id}>
              <td>{assignmentResource.seqNo}          
              </td>
              <td>
                {assignmentResource.url && (
                  <a href={assignmentResource.url} target="_blank" rel="noopener noreferrer">{assignmentResource.title}</a>
                )}
                {!assignmentResource.url && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`resource ${assignmentResource.title}`, <AssignmentResourceModal content={assignmentResource.content}/>, 'modal-pane-1')}>
                    {assignmentResource.title}
                  </span>
                )}
              </td>
            </tr>  
            )}     
          </tbody>
        </Table>   
        </Fragment>
      }                
      </div>
  )
}

export default observer(StudentAssignmentForm)
