import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router'
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Row, Button, Table, Col } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'; // Import
import AddLessonResourceText from './AddLessonResourceText';
import AddLessonResourceLink from './AddLessonResourceLink';
import AddLessonResourceAttach from './AddLessonResourceAttach';
import LessonResourceModal from './LessonResourceModal';
import UpdateLesson from '../lesson/UpdateLesson';
import SetLessonResourceSeqNo from './SetLessonResourceSeqNo';

interface LessonParams {
  lessonId: string;
}
const initialValues = {
  courseName: '',
  courseCode: '',
  date: new Date()
};

const TeacherLessonContentForm: React.FC<RouteComponentProps<LessonParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);  
  const {lessonContent, loadLessonContent, removeResource} = rootStore.lessonStore;
  const { openModal} = rootStore.modalStore;    
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const loadValues = async() => {
      var lesson = await loadLessonContent(match.params.lessonId);
      if(lesson) {
        setValues({
          courseName: lesson.courseName,
          courseCode: lesson.courseCode,
          date: new Date(lesson.date)
        });
      }
    }
    if(match.params.lessonId){
      loadValues();
    }
  }, [loadLessonContent, match.params.lessonId])

  const handleRemoveResource = (id: any) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeResource(match.params.lessonId, id)        
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  }

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
      <h2 className= "mr-auto active-title" >Course: {values.courseName}</h2>
      <Link to={`/t-classes/${values.courseCode}`}>
          <Button >Back to Course</Button>
        </Link> 
      </div>
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Lesson #
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.seqNo}
            <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal("Update Lesson", 
                <UpdateLesson lessonId={match.params.lessonId} 
                seqNo={lessonContent?.seqNo} topic={lessonContent?.topic} date={lessonContent?.date} timePeriod={lessonContent?.timePeriod}/>, 'modal-pane-2')}>          
              </i> 
          </h3>
        </Col>
      </Row> 
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Topic
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.topic}
          </h3>
        </Col>
      </Row> 
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Date
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
          {values.date?.toISOString().substr(0, 10)}
          </h3>
        </Col>
      </Row>
      <Row className="form-field">
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Time Period
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {lessonContent?.timePeriod}
          </h3>
        </Col>
      </Row>

      <div className='mt-3' style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color:'darkcyan'}}>Resources</h3>
        <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (text)", <AddLessonResourceText lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i>
        <i className="fa fa-link fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (link)", <AddLessonResourceLink lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i> 
        <i className="fa fa-paperclip fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new resource (attachment)", <AddLessonResourceAttach lessonId={match.params.lessonId}/>, 'modal-pane-1')}>          
        </i>                 
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {lessonContent?.lessonResources?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map(lessonResource =>
            <tr key={lessonResource.id}>
            <td>{lessonResource.seqNo}
              <i className="fa fa-edit ml-2 text-info clickable" 
                onClick={() => openModal(`Set SeqNo for ${lessonResource.title}`, 
                <SetLessonResourceSeqNo lessonResourceId={lessonResource.id} seqNo={lessonResource.seqNo.toString()} />, 'modal-pane-2')} >          
              </i>            
            </td>
            <td>
              {lessonResource.url && (
                <a href={lessonResource.url} target="_blank" rel="noopener noreferrer">{lessonResource.title}</a>
              )}
              {!lessonResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${lessonResource.title}`, <LessonResourceModal content={lessonResource.content}/>, 'modal-pane-1')}>
                  {lessonResource.title}
                </span>
              )}
              <i className="fa fa-trash ml-2 text-danger clickable" onClick={() => handleRemoveResource(lessonResource.id)}></i>
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>          
    </div>
  )
}

export default observer(TeacherLessonContentForm)
