import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Table} from 'react-bootstrap'

const initialValues = [];

const AttendanceModal = ({lessonId})  => {
  const rootStore = useContext(RootStoreContext);  
  const {loadLesson, loadAttendances} = rootStore.lessonStore;
  const [newAttendances, setNewAttendances] = useState(initialValues);

  useEffect(() => {
    const loadValues = async() => {
      var attendances = await loadAttendances(lessonId);   
      if(attendances){
        setNewAttendances(attendances);
      }
    }
    if(lessonId){
      loadValues();
    }
  }, [loadAttendances, loadLesson, setNewAttendances, lessonId]);

  const renderSwitch = (param) => {
    switch(param) {
      case 1: 
        return 'On Time';
      case 2:
      case 0:
        return 'Late';
      case 3:
        return 'Absent with notice';
      default:
        return 'Absent';
    }
  };

  return (
    <Container className="mt-3">
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>City</th>
            <th>Email</th>
            <td>Attended?</td>
          </tr>
        </thead>
        <tbody>
          {newAttendances?.slice().sort((a, b) => {
                      if (a.student.firstName.toLowerCase() > b.student.firstName.toLowerCase()) return 1;
                      else if(a.student?.firstName.toLowerCase() < b.student.firstName.toLowerCase()) return -1;
                      else {
                          if(a.student.lastName.toLowerCase() > b.student.lastName.toLowerCase()) return 1;
                          else return -1;
                      }
                    })
          .map((attendance, index) => 
            <tr key={index} className={`${attendance.attendingCode===1 ? "text-primary" : "text-secondary"}`}>
              <td>{index + 1}</td>
              <td>{attendance.student.firstName}   {attendance.student.lastName}</td>
              <td>{attendance.student.city}</td>
              <td>{attendance.student.email}</td>
              <td>
                {renderSwitch(attendance.attendingCode)}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="mb-3">
        {newAttendances.filter(x=>x.attendingCode < 3).length} of {newAttendances.length} students attended.
      </div>
    </Container>
  )
}

export default AttendanceModal
