import React, {useState, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddClassResource = ({courseCode}) => {
  const rootStore = useContext(RootStoreContext);
  const {addCourseResource} = rootStore.courseStore;
  const [title, setTitle] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };
  
  const handleTitleChange = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    setSubmitting(true);
    if(title.length > 0 && currentContent){
      var url = '';
      addCourseResource(courseCode, title, url, draftToHtml(convertToRaw(currentContent)))
      .finally(() =>{
        setSubmitting(false);
      })
    }
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {title}
              onChange={handleTitleChange}
            />
          </Col>
        </Row> 
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          title && editorState.getCurrentContent()  &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Add</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default AddClassResource
