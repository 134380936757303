import React, {useState, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddQuestionModal = ({courseCode}) => {
  const rootStore = useContext(RootStoreContext);
  const {addQuestion} = rootStore.qaStore;
  const [topic, setTopic] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };  

  const handleTopicChange = (event) => {
    const { value } = event.target;
    setTopic(value);
  };
  
  const handlePrivateCheck = (event) => {
    setIsPrivate(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    setSubmitting(true);
    if(topic.length > 0 && currentContent){
      addQuestion(courseCode, topic, isPrivate, draftToHtml(convertToRaw(currentContent)))
      .finally(() =>{
        setSubmitting(false);
      })
    }
  }  

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={1} md={2} sm={2}>
            <Form.Check type="checkbox" label="Private" checked={isPrivate}
              name="isPrivate" onChange={handlePrivateCheck}/>
          </Col>
          <Col lg={1} md={2} sm={2}>
            <Form.Label className="mt-2">
              Topic
            </Form.Label>
          </Col>
          <Col lg={10} md={8} sm={8}>
            <Form.Control
              required
              type="text"
              name="topic"
              value = {topic}
              onChange={handleTopicChange}
            />
          </Col>
        </Row> 
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          topic && editorState.getCurrentContent()  &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Add</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default AddQuestionModal
