import { RootStore } from './rootStore';
import { observable, runInAction, action } from 'mobx';
import agent from '../api/agent';
import { IAchievement } from '../models/achievement';

export default class AchievementStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  
  @observable loading = false;
  @observable achievements: IAchievement[] = [];

  @action getAchievements = async() => {
    this.loading = true;
    try {
      var as = await agent.Achievement.getAchievements();
      runInAction(() => {
        this.achievements = as;
        this.loading = false;
      })
      return as;
    } catch(error) {
      runInAction(()=> {
        this.loading = false;
      })
    }
  }

  @action addAchievement = async(title: string, date: string, description: string) => {
    this.loading = true;
    try {
      var achievement = await agent.Achievement.addAchievement(title, date, description);
      runInAction(() => {
        this.loading = false;
        this.achievements.push(achievement);
        this.rootStore.modalStore.closeModal();
      });
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action updateAchievement = async(id: string, title: string, date: string, description: string) => {
    this.loading = true;
    try {    
      await agent.Achievement.updateAchievement(id, title, date, description);
      runInAction(() => {
        this.loading = false;
        this.achievements = this.achievements.map(a =>
          a.id === id ? {...a, title: title, date: new Date(date), description: description} : a);
        this.rootStore.modalStore.closeModal();
      }) 
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
