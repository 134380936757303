import React, {useState, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";

const SubmitCodeModal = ({topicItemId}) => {
  const rootStore = useContext(RootStoreContext);
  const {submitCode} = rootStore.topicStore;
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);  

  const handleSubmit = () => {
    setSubmitting(true);
    submitCode(topicItemId, code);
  }  

  return (
    <Container>
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Code
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <Editor value={code}
            onValueChange = {code => setCode(code)}
            highlight={code => highlight(code, languages.js)}
            padding={5}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: 'aliceblue'
            }}
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-center pb-3 mt-5">
      {code && !submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row> 
    </Container>
  )
}

export default SubmitCodeModal
