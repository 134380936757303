import React from 'react'
import Parser from 'html-react-parser';
import { Container } from 'react-grid-system';

const ContentModal = ({content}) => {
  return (
    <Container>
      {content && Parser(content)}
    </Container>
  )
}

export default ContentModal
