import React from 'react';
import { Card, Button  } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Card>
            <Card.Body>
              
              <Card.Title><i className="fa fa-search mr-2" style={{color:"teal"}}/>
              Not found</Card.Title>
              <Card.Text>
                Oops - we've looked everywhere but couldn't find this.
              </Card.Text>
              <Link to={"/"}>
                <Button  variant="primary">Return to home page</Button>
              </Link>
            </Card.Body>
        </Card>
    );
};

export default NotFound;