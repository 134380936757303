import React, {useState, useContext} from 'react'
import { Container, Form, Button, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddDiscussionModal = ({assignnmentId}) => {
  const rootStore = useContext(RootStoreContext);
  const {addDiscussion} = rootStore.assignmentStore;
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };  

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    setSubmitting(true);
    if(currentContent){
      addDiscussion(assignnmentId, draftToHtml(convertToRaw(currentContent)))
      .finally(() =>{
        setSubmitting(false);
      })
    }
  }

  return (
    <Container>
      <Form noValidate> 
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          editorState.getCurrentContent()  &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Add</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>  
  )
}

export default AddDiscussionModal