import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ICourseTopic } from '../models/courseTopic';
import { IStudentCourseTopic } from '../models/studentCourseTopic';
import { IMessageInfo } from '../models/message'
import { ITeacherCourseTopic } from '../models/TeacherCourseTopic';
import { ISubmission } from '../models/submission';

export default class CourseTopicStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable currentTopic: ICourseTopic | null = null;
  @observable teacherTopic: ITeacherCourseTopic | null = null;
  @observable studentTopic: IStudentCourseTopic | null = null;
  @observable discussions : IMessageInfo[] = [];
  @observable studentSubmissions : ISubmission[] = [];

  @action loadCourseTopics = async (courseCode: string) => {
    this.loading = true;
    try {
      var cts = await agent.CourseTopic.getCourseTopics(courseCode);
      runInAction(() => {
        this.loading = false;
      });
      return cts;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action setCourseTopic = async(courseCode: string, topicId: string, isAdded: boolean) => {
    this.loading = true;
    try {
      await agent.CourseTopic.setCourseTopic(courseCode, topicId, isAdded);
      runInAction(() => {
        this.loading = false;
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action loadCourseTopicItems = async(courseTopicId: string) => {
    this.loading = true;
    try {
      var ctis = await agent.CourseTopic.getCourseTopicItems(courseTopicId);
      runInAction(() => {
        this.loading = false;
      });
      return ctis;    
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }  
  }

  @action addCourseTopicItem = async(courseTopicId: string, topicitemId: string) => {
    this.loading = true;
    try {
      await agent.CourseTopic.addCourseTopicItem(courseTopicId, topicitemId);
      runInAction(() => {
        this.loading = false;
      });       
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action removeCourseTopicItem = async(courseTopicId: string, topicItemId: string) => {
    this.loading = true;
    try {
      await agent.CourseTopic.removeCourseTopicItem(courseTopicId, topicItemId);
      runInAction(() => {
        this.loading = false;
      });       
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action toggleShowConcepts = async(courseTopicId: string, topicItemId: string, showConcepts: boolean) => {
    this.loading = true;
    try {
      await agent.CourseTopic.toggleConcepts(courseTopicId, topicItemId, showConcepts);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action toggleShowAnalysis = async(courseTopicId: string, topicItemId: string, showAnalysis: boolean) => {
    this.loading = true;
    try {
      await agent.CourseTopic.toggleAnalysis(courseTopicId, topicItemId, showAnalysis);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action toggleShowCode = async(courseTopicId: string, topicItemId: string, showCode: boolean) => {
    this.loading = true;
    try {
      await agent.CourseTopic.toggleCode(courseTopicId, topicItemId, showCode);
      runInAction(() => {
        this.loading = false;
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action loadCourseTopic = async(courseTopicId: string) => {
    this.loading = true;
    try {
      var topic = await agent.CourseTopic.getCourseTopic(courseTopicId);
      runInAction(() => {
        this.loading = false;
        this.currentTopic = topic;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action loadTeacherCourseTopic = async(courseTopicId: string) => {
    this.loading = true;
    try {
      var topic = await agent.CourseTopic.getTeacherCourseTopic(courseTopicId);
      runInAction(() => {
        this.loading = false;
        this.teacherTopic = topic;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action loadStudentCourseTopic = async(courseTopicId: string) => {
    this.loading = true;
    try {
      var topic = await agent.CourseTopic.getStudentCourseTopic(courseTopicId);
      runInAction(() => {
        this.loading = false;
        this.studentTopic = topic;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }
  }

  @action loadDiscussions = async(courseTopicId: string, topicItemId: string) => {
    this.loading = true;
    try {
      var dis = await agent.CourseTopic.getDiscussions(courseTopicId, topicItemId);
      runInAction(() => {
        this.loading = false;
        this.discussions = dis;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }    
  }

  @action addDiscussion = async(courseTopicId: string, topicItemId: string, message: string) => {
    this.loading = true;    
    try {
      var discussion = await agent.CourseTopic.addDiscussion(courseTopicId, topicItemId, message);    
      runInAction(() => {
        this.loading = false;
        this.discussions.push(discussion);
        this.rootStore.modalStore.closeModal();        
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }

  @action submitCode= async(courseTopicId: string, topicItemId: string, remark: string, code: string) => 
  {
    this.loading = true;
    try{
      await agent.CourseTopic.submitCode(courseTopicId, topicItemId, remark, code);
      if(this.studentTopic){
        var topicItem = this.studentTopic.topicItems.find(x=>x.id === topicItemId);
        if(topicItem){
          topicItem.submitRemark = remark;
          topicItem.submitCode = code;
        }
      }
      this.rootStore.modalStore.closeModal();  
    } finally {
      this.loading = false;
    }
  }

  // for teachers
  @action loadStudentSubmissions = async (courseTopicId: string, topicItemId: string) => {
    this.loading = true;
    try{
      var sms = await agent.CourseTopic.getSubmissions(courseTopicId, topicItemId);
      runInAction(() => {
        this.studentSubmissions = sms;
        this.loading = false;
      });
      return sms;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  }  

  @action updateMessage= async(courseTopicId: string, topicItemId: string,
    messageId: string, message: string) => {
    this.loading = true;
    try {
      await agent.CourseTopic.updateDiscussion(courseTopicId, topicItemId, messageId, message);
      runInAction(() => {
        this.loading = false;
        this.rootStore.modalStore.closeModal();        
      })
    } catch(error) {
      runInAction(() => {
        this.loading = false;
      });     
    }
  }
}