import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

export const LoadingComponent: React.FC<{
  inverted?: boolean;
  content?: string;
}> = ({ inverted=true, content }) => {
  return (
    <LoadingOverlay
      active={inverted}
      spinner
      text={content}
      >
    </LoadingOverlay>
  );
};
