import { RootStore } from './rootStore';
import { observable, runInAction, action } from 'mobx';
import agent from '../api/agent';
import { IInquiry } from '../models/inqury';

export default class InquiryStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;
  @observable inquiries: IInquiry[] = [];

  @action addInquiry = async(phone: string, email: string, topic: string, description: string) => 
  {
    this.loading = true;
    try{
      await agent.Inquiries.addInquiry(phone, email, topic, description);
      runInAction(() => {
        this.loading = false;
      }); 
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action getInquires = async() => {
    this.loading = true;
    try {
        var iqs = await agent.Inquiries.getInquiries();
        runInAction(() => {
            this.inquiries = iqs;
            this.loading = false;
        });
    } catch (error){
        runInAction(() => {
            this.loading = false;
        });
    }
  }

  @action setInquiryChecked = async(id: string) => {
    this.loading = true;
    try {
      await agent.Inquiries.setInquiryChecked(id);
      runInAction(() => {
        this.loading = false;
        var inquiry = this.inquiries.find(x=>x.id === id);
        if(inquiry){
          inquiry.checked = true;
        }        
      }); 
    } catch(error){
      runInAction(() => {
        this.loading = false;
      })      
    }
  }
}