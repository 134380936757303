import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';
import "../classes.css";

const initialValues = {
  subjectName: '',
  code: '',
  level: 'Beginner',
  description: ''
}

const verificationError = {
  emptyCode: false,
  invalidLevel: false,
  emptyName: false
};

const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};

const NewSubjectModal = () => {
  const rootStore = useContext(RootStoreContext);  
  const {newSubject} = rootStore.subjectStore;  
  const [values, setValues] = useState(initialValues);  
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const emptyCode = (values.code && values.code.trim().length) ? false: true;
    const emptyName = (values.name && values.name.trim().length) ? false: true;
    var invalidLevel = false;
    var level = 0;
    if(values.level === "Beginner"){
      level = 1;
    }
    else if(values.level === "Intermediate"){
      level = 2;
    } else if(values.level === "Advanced") {
        level = 3;
    } else if(values.level === "Challenging") {
      level = 4;
    } else{
      invalidLevel = true;
    }
    setValueErrors({...valueErrors, emptyCode, emptyName, invalidLevel});
    if(emptyCode || emptyName || invalidLevel){
    } else {
      const subject = {
        name: values.subjectName.trim(),
        level: level,
        code: values.code.trim(),
        description: values.description.trim()
      }
      newSubject(subject).catch(error => {
        if(error.data && error.statusText){
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);   
        }
        else {
          const submitErrorInfo2 = {
            errors: [], 
            text: "Network Error"
          };
          setSubmitError(submitErrorInfo2);    
        }
      }).finally(() => {
        setSubmitting(false);
      });           
    }
  }
  return (
    <Container className="uer-page mt-3">
      <h2 style={{color: "red"}}>New Subject</h2>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control
                required
                type="text"
                name="code"
                value={values.code}
                onChange={handleChange}
              />  
          </Col> 
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
                required 
                as="select" 
                name="level" 
                value={values.level}
                onChange={handleChange}
                >
                <option>Beginner</option>
                <option>Intermediate</option>
                <option>Advanced</option>
                <option>Challenging</option>  
              </Form.Control> 
          </Col> 
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control
                required
                type="text"
                name="subjectName"
                value={values.subjectName}
                onChange={handleChange}
              />  
          </Col> 
        </Row> 
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Description
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={6} className="full-control" 
              name="description"
              value={values.description}
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
        </Row>   
        <ul className="mb-4">
              {valueErrors.emptyCode && (
                <li className="text-danger">Code is empty</li>
              )}          
              {valueErrors.emptyName && (
                <li className="text-danger">Empty suject name</li>
              )}
              {valueErrors.invalidLevel && (
                <li className="text-danger">Invalid level </li>
              )}
            </ul>
            {submitError.text && (
                <ErrorMessage errors={submitError.errors} text={submitError.text} />
        )}          
        <Row className="d-flex justify-content-center pb-3 mt-3">
        {!submitting && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                               
      </Form>
    </Container>
  )
}

export default NewSubjectModal