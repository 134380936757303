import React from 'react'
import { Container, Form, Col, Row } from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";

const LastSubmitModal = ({remark, code, attachment, attachmentUrl}) => {
  return (
    <Container>
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Remark:
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <div className="student-remark">
            <p className="mt-2 ml-2">{remark}</p>
          </div>
        </Col>
      </Row>  
      {code && (
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Code
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <Editor value={code}
            onValueChange = {() => {}}
            highlight={code => highlight(code, languages.js)}
            padding={5}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: 'aliceblue'
            }}
          />
        </Col>
      </Row>
      )}
      {attachment && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Attachment
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">{attachment}</a>            
          </Col>
        </Row>
      )}        
    </Container>
  )
}

export default LastSubmitModal
