import React, { Fragment} from 'react'
import NavBarDesktop from './NavBarDesktop'

interface IProps {
  children: JSX.Element
}

const NavBar: React.FC<IProps> = ({children}) => {
  return (
    <Fragment>
        {/* <Responsive {...Responsive.onlyMobile}>
          <NavBarMobile children={children}/>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}> */}
          <NavBarDesktop children={children} />
        {/* </Responsive>       */}
    </Fragment>
  )
};

export default NavBar;