import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ITeacher } from '../models/teacher';

export default class TeacherStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable teachers: ITeacher[] = [];

  @action loadTeachers = async() => {
      this.loading = true;
      try {
          var cs = await agent.Teachers.list();
          runInAction(() => {
              this.teachers = cs;
              this.loading = false;
          });          
          return cs;
      } catch (error){
          runInAction(() => {
              this.loading = false;
          });
      }
  };
  
}