import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';
import "../classes.css";

const initialSubject = {
  name: '',
  code: '',
  level: ''
};

const initialValues = {
  courseName: '',
  startDate: '',
  endDate: '',
  dayOfTheWeek: '',
  timePeriod: '',
  numOfLessons: '',
  description: ''
};

const initialTeacher = {
  userName: '',
  firstName: '',
  lastName: ''
}

const verificationError = {
  subjectNotSelected: false,
  emptyCourseName: false,
  emptyStartDate: false,
  emptyEndDate: false,
  teacherNotSelected: false,
  invalideNumOfLessons: false
};
const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};

const NewClassForm = () => {
  const rootStore = useContext(RootStoreContext);  
  const {subjects, loadSubjects} = rootStore.subjectStore;
  const {teachers, loadTeachers } = rootStore.teacherStore;
  const {createNewCourse} = rootStore.coursesStore;
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState(initialSubject);
  const [teacher, setTeacher] = useState(initialTeacher);
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);

  useEffect(() => {
    const loadValues = async () => {
      await loadSubjects();
      await loadTeachers();
    }
    loadValues();
  }, [loadSubjects, loadTeachers])

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const subjectNotSelected = subject.code.length === 0;
    const emptyCourseName = values.courseName.length === 0;
    const emptyStartDate = values.startDate.length === 0;
    const emptyEndDate = values.endDate.length === 0;
    const teacherNotSelected = teacher.userName.length === 0;
    const invalideNumOfLessons = values.numOfLessons.length === 0 || isNaN(Number(values.numOfLessons));
    setValueErrors({...valueErrors, subjectNotSelected, emptyCourseName, emptyStartDate, emptyEndDate, teacherNotSelected, invalideNumOfLessons});
    if(subjectNotSelected ||
      emptyCourseName ||
      emptyStartDate ||
      emptyEndDate ||
      teacherNotSelected ||
      invalideNumOfLessons){
    }
    else{
      var startDate = new Date(values.startDate);
      var endDate = new Date(values.endDate);
      var numOfLessons = parseInt(values.numOfLessons);
      const course = {      
        courseName: values.courseName,
        startDate: startDate,
        endDate: endDate,
        dayOfTheWeek: values.dayOfTheWeek,
        timePeriod: values.timePeriod,
        numOfLessons: numOfLessons,
        description: values.description,
        subjectCode: subject.code,  
        teacherUserName: teacher.userName,
      };
      setSubmitting(true);
      createNewCourse(course).catch(error => {
        if(error.data && error.statusText){
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);   
        }
        else {
          const submitErrorInfo2 = {
            errors: [], 
            text: "Network Error"
          };
          setSubmitError(submitErrorInfo2);    
        }
      }).finally(() => {
        setSubmitting(false);
      });     
    }
  };

  const selectSubject = (event: any) => {
    const { value } = event.target;
    const subject = subjects.find(x=>x.code === value);
    if(subject){
      var level = 'Beginner';
      switch(subject.level){
        case 2:
          level = 'Intermediate';
          break;
        case 3:
          level = 'Advanced';
          break;
        case 4:
          level = 'Challenging';
          break;
      }
      setSubject({
        code: subject.code,        
        name: subject.name,
        level: level
      });
    }
  };

  const selectTeacher = (event: any) => {
    const {value} = event.target;
    const teacher = teachers.find(x=>x.userName===value);
    if(teacher){
      setTeacher({
        userName: teacher.userName,
        firstName: teacher.firstName,
        lastName: teacher.lastName
      });
    }
  }
  return (
    <Container className="content">
      <h2 style={{color: "red"}}>New Course</h2>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3 bold-font">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            required 
            as="select" 
            name="subjectName"
            value = {subject.code}
            onChange={selectSubject}
          >
            <option disabled value="" key={-1}>select</option>
            {subjects.map((subject)=> {
                return <option key={subject.code} value={subject.code} label={subject.name}/>
              })}                      
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={subject.code} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={subject.level} readOnly>            
          </Form.Control>
          </Col> 
        </Row>       
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3 bold-font">
                Course Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="courseName"
                value={values.courseName}
                onChange={handleChange}
              />              
              </Col>              
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3 bold-font">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="date"
                name="startDate"
                value={values.startDate}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3 bold-font">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="date"
                name="endDate"
                value={values.endDate}
                onChange={handleChange}
              />
              </Col>
          </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time Period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="timePeriod"
                value={values.timePeriod}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3 bold-font">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                required 
                as="select" 
                name="teacher" 
                value={teacher.userName}
                onChange={selectTeacher}
                >
                  <option disabled value='' key={-1}>select</option>
                  {teachers.map((teacher)=> {
                    return (<option key={teacher.userName} value={teacher.userName} label={teacher.firstName + ' ' + teacher.lastName}/>)
                    })}   
              </Form.Control>
              </Col>              
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="numOfLessons"
                value={values.numOfLessons}
                onChange={handleChange}
              />
              </Col>             
        </Row> 
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Description
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={6} className="full-control" 
              name="description"
              value={values.description}
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
        </Row>   
        <ul className="mb-4">
              {valueErrors.subjectNotSelected && (
                <li className="text-danger">Subject not selected</li>
              )}          
              {valueErrors.emptyCourseName && (
                <li className="text-danger">Empty CourseName</li>
              )}
              {valueErrors.emptyStartDate && (
                <li className="text-danger">Empty Start Date </li>
              )}
              {valueErrors.emptyEndDate && (
                <li className="text-danger">Empty End Date</li>
              )}
              {valueErrors.teacherNotSelected && (
                <li className="text-danger">Teacher not selected</li>
              )}   
              {valueErrors.invalideNumOfLessons && (
               <li className="text-danger">Invalid # of Lessons</li>
              )}
            </ul>
            {submitError.text && (
                <ErrorMessage errors={submitError.errors} text={submitError.text} />
        )}                                          
        <Row className="d-flex justify-content-center pb-3 mt-3">
        {!submitting && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  );
}

export default observer(NewClassForm)
