import React from 'react'
import Parser from 'html-react-parser';
import { Container } from 'react-grid-system';

const AssignmentProblemModal = ({title, description}) => {
  return (
    <Container>
      <h4>{title}</h4>
      {description && Parser(description)}
    </Container>
  )
}

export default AssignmentProblemModal
