import { RootStore } from './rootStore';
import { observable, computed, runInAction, action, toJS } from 'mobx';
import { IAppUser, IUserInfo } from '../models/user';
import agent from '../api/agent';

export default class UsersStore  {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
    }

    @observable userRegistry = new Map();
    @observable userSelected? : IAppUser;
    @observable loading = false;
    @observable checkedValue = new  Map<string, boolean>();
    @observable submitting = false;

    @computed get users(): IAppUser[] {
      return Array.from(this.userRegistry.values()).sort(
          (a, b) => {
              if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
              else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
              else {
                  if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                  else return -1;
              }
          }
      )
    }

    @action loadUsers = async () => {
        this.loading = true;
        try {
            const user_list = await agent.Users.list();
            runInAction(() => {
              user_list.forEach(user => {
                    this.userRegistry.set(user.userName, user);
                });
              this.loading = false;
            });
        } catch (error){
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    @action loadUser = async (userName: string) => {
      let user = this.getUser(userName);
      if(user) {
        this.userSelected = user;
        return toJS(user);
      }
      else {
        this.loading = true;
        try {
            user = await agent.Users.info(userName);
            runInAction(() => {
                this.userSelected = user;
                this.loading = false;
            });
            return user;
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            });
        }
      } 
    };

    getUser(userName: string) : IAppUser {
        return this.userRegistry.get(userName);
    }

    @action setUserInfo = async (userInfo: IUserInfo) => {
      this.submitting = true;
      try{
          await agent.Users.setUserInfo(userInfo);
          let user = this.getUser(userInfo.userName);
          if(user) {
            user.firstName = userInfo.firstName;
            user.lastName = userInfo.lastName;
            user.introduction = userInfo.introduction;
            user.city = userInfo.city;
            user.age = userInfo.age;
            user.grade = userInfo.grade;
            user.phone = userInfo.phone;
            user.wechat = userInfo.wechat;
            user.active = userInfo.active;   
            user.parentFirstName = userInfo.parentFirstName;
            user.parentLastName = userInfo.parentLastName;         
          }
          runInAction(() => {
              this.submitting = false;
              this.rootStore.modalStore.closeModal();              
          });
      } catch (error) {
          runInAction(() => {
              this.submitting = false;
          })
          this.rootStore.modalStore.setError("Error: set user info failed");            
      }
    }

    @action setParent = async (userName: string, parentEmail: 
      string, parentFirstName: string, parentLastName: string) => {
        this.submitting = true;
        try {
          await agent.Users.setParentInfo(userName, parentEmail, parentFirstName, parentLastName);
          runInAction(() => {
            this.submitting = false;
            let user = this.getUser(userName);
            if(user) {
              user.parentEmail = parentEmail;
              user.parentFirstName = parentFirstName;
              user.parentLastName = parentLastName;          
            }
            this.rootStore.modalStore.closeModal();               
          });                  
        }catch (error) {
          runInAction(() => {
              this.submitting = false;
          })
          this.rootStore.modalStore.setError("Error: set parent failed");            
      }
    }

    @action setUserRoles = async(userName: string, roles: string[]) => {
      this.submitting = true;
      try {
        await agent.Users.setRoles(userName, roles);
        runInAction(() => {
          this.submitting = false;
          let user = this.getUser(userName);
          if(user) {
            user.roles = roles;
          }
          this.rootStore.modalStore.closeModal();            
        })
      } catch (error) {
        runInAction(() => {
            this.submitting = false;
        })
        this.rootStore.modalStore.setError("Error: set roles failed");          
      }
    }

    @action removeUser = async(userName: string) => {
      this.submitting = true;
      try {
        await agent.Users.removeUser(userName);
        let user = this.getUser(userName);
        if(user) { 
          // need to update userRegistry
          this.userRegistry.delete(userName);    
        }
        runInAction(() => {
          this.submitting = false;
        });        
      } catch (error) {
        runInAction(() => {
            this.submitting = false;
        })
      }
    }

    @action setUserActive = async(userName: string, active: boolean) => {
      this.submitting = true;
      try {
        await agent.Users.setUserActive(userName, active);
        runInAction(() => {
          this.submitting = false;
          let user = this.getUser(userName);
          if(user) {
            user.active = active;
          }
        })
      } catch (error) {
        runInAction(() => {
            this.submitting = false;
        })
      }
    }

    @action changeUserEmail = async(userName: string, email: string) => {
        this.submitting = true;
        try {
            await agent.Users.changeUserEmail(userName, email);
            runInAction(() => {
                this.submitting = false;
                let user = this.getUser(userName);
                if(user) {
                  // userName and email are the same
                  user.userName = email;
                  user.email = email;   
                  // need to update userRegistry
                  this.userRegistry.delete(userName);
                  this.userRegistry.set(email, user);     
                }
                this.rootStore.modalStore.closeModal();                
            });
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            });  
            this.rootStore.modalStore.setError("Error: change email failed");     
        }
    }

    @action resetPassword = async(userName: string, password: string) => {
      this.submitting = true;
      try {
        await agent.Users.resetPassword(userName, password);
        runInAction(() => {
            this.submitting = false;
            this.rootStore.modalStore.closeModal();                
        });
      } catch (error) {
          runInAction(() => {
              this.submitting = false;               
          })
          this.rootStore.modalStore.setError("Error:reset password failed");            
      }
    }

    @action getStudentCourses = async(userName: string) => {
      this.submitting = true;      
      try {
          var courses = await agent.Users.getStudentCourses(userName);
          runInAction(() => {
            this.submitting = false;            
          });
          return courses;
      } catch (error){
        runInAction(() => {
          this.submitting = false;
      })        
      }
    };
    
    @action getChildCourses = async(userName: string) => {
      try {
          var cs = await agent.Users.childCourses(userName);
          return cs;
      } catch (error){
      }
    };    
}
