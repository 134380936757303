import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button} from 'react-bootstrap';
import "../classes.css";
import AddClassResourceText from './AddClassResourceText';
import AddClassResourceLink from './AddClassResourceLink';
import AddClassResourceAttach from './AddClassResourceAttach';
import AddLesson from './AddLesson';
import SetTimePeriodModal from './SetTimePeriodModal';
import SetNoticeModal from './SetNoticeModal';
import ContentModal from '../../app/common/modals/ContentModal';
import SetVideoModal from './SetVideoModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SetDayOfTheWeekModal from './SetDayOfTheWeekModal';
import SetCourseResourceSeqNo from './SetCourseResourceSeqNo';
import CodeModal from '../../app/common/modals/CodeModal';
import parse from 'html-react-parser';

interface ClassParams {
  courseCode: string;
}

const TeacherClassForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {courseContent, loadCourseContent, removeResource} = rootStore.courseStore;
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    const loadValues = async() => {
      await loadCourseContent(match.params.courseCode);
    }
    if(match.params.courseCode){
      loadValues();
    }
  }, [loadCourseContent, match.params.courseCode])

  const handleRemoveResource = (id: any) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeResource(match.params.courseCode, id)        
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  }

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className={` mr-auto ${courseContent?.active ? "active-title" : "inactive-title"}`}>Class: {courseContent?.courseName}</h2>
        <Link to={`/t-class/set-course-topics/${match.params.courseCode}`}>
          <Button variant="secondary" className="mr-3">Set Topics</Button>
        </Link>   
        <Link to={`/coursediscussions/${courseContent?.courseId}`}>
          <Button variant="warning" className="mr-3">Latest Discussions</Button>
        </Link>                 
        <Link to={`/t-class/qa/${match.params.courseCode}`}>
          <Button variant="warning" className="mr-3">Q&A</Button>
        </Link>        
        <Link to={`/t-class/info/${match.params.courseCode}`}>
          <Button variant="info">Info</Button>
        </Link>
      </div>
      
      <Row className="form-field" >
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Day of the week
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>            
            {courseContent?.dayOfTheWeek}
            {courseContent?.active &&
              <i className="fa fa-edit ml-3 text-info clickable"
                onClick={() => openModal("Set Day of the week",     
                <SetDayOfTheWeekModal courseCode={courseContent?.courseCode} dayOfTheWeek={courseContent?.dayOfTheWeek} />)} >          
              </i>
            }
          </h3>
        </Col>
      </Row>

      <Row className="form-field" >
        <Col lg={3} md={3} sm={3}>
          <h3 style={{color: 'darkblue'}}>
            Time Period
          </h3>
        </Col>
        <Col lg={4} md={4} sm={4}>
          <h3 style={{color: 'maroon'}}>
            {courseContent?.timePeriod}
            {courseContent?.active &&            
              <i className="fa fa-edit ml-3 text-info clickable"
                onClick={() => openModal("Set Time Period",     
                <SetTimePeriodModal courseCode={courseContent?.courseCode} timePeriod={courseContent?.timePeriod} />)} >          
              </i>
            }
          </h3>
        </Col>
      </Row>

      <div className="mt-3" style={{display: 'flex'}}>
          <h3 className="mr-auto" style={{color: 'magenta'}}>Notice
          {courseContent?.active &&             
            <i className="fa fa-edit ml-3 text-info clickable"
                  onClick={() => openModal("Set Notice",     
                  <SetNoticeModal courseCode={courseContent?.courseCode} notice={courseContent?.notice} />, 'modal-pane-2')} >          
            </i>
          }
          </h3>
      </div>  
      <Row className="form-field" >
        <Col>
        <p className="wrap-line">{courseContent?.notice  && parse(courseContent?.notice)}</p>
        </Col>
      </Row>
    
      <div style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color: 'darkgreen'}}>Lessons</h3>      
          <i className="fa fa-plus-circle fa-2x mr-3 mt-2 mb-2 text-info clickable" 
            onClick={() => openModal("Add new lesson", <AddLesson courseCode={match.params.courseCode} timePeriod={courseContent?.timePeriod}/>, 'modal-pane-2')}>          
          </i>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Topic</th>
            <th>Date</th>
            <th>Time Period</th>
            <th>Attendance</th>
            <th>Video</th>
            <th>Assignment</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.lessons?.slice()
          .sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map((lesson, index) => 
            <tr key={lesson.id}>
            <td>
              {lesson.seqNo}        
            </td>
            <td>
              <Link to={`/lesson/t-content/${lesson.id}`}>
              {lesson.topic}
              </Link>              
            </td>            
            <td>{new Date(lesson.date).toISOString().substring(0, 10)}</td>
            <td>{lesson.timePeriod}</td>
            <td>
              <Link to={`/lesson/attendance/${lesson.id}`}>
                {lesson.numOfAttendances} students                             
              </Link>
            </td>
            <td>
              {lesson.url && (
                <a href={lesson.url} target="_blank" rel="noopener noreferrer">video</a>
              )}
              <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal("set video", <SetVideoModal lessonId={lesson.id} url={lesson.url}/>, 'modal-pane-1')}>          
              </i> 
            </td>
            <td>
              <Link to={`/lesson/assignment/${lesson.id}`}>
                Assignment
              </Link>
            </td>
          </tr> 
          )}     
        </tbody>
      </Table>

      <div className='mt-3' style={{display: 'flex'}}>
        <h3 className='mr-auto' style={{color:'darkcyan'}}>Resources</h3>    
          <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
            onClick={() => openModal("Add new resource (Text)", <AddClassResourceText courseCode={match.params.courseCode}/>, 'modal-pane-1')}>          
          </i>    
          <i className="fa fa-link fa-2x mr-3 mt-2 mb-2 text-info clickable" 
            onClick={() => openModal("Add new resource (Link)", <AddClassResourceLink courseCode={match.params.courseCode}/>, 'modal-pane-1')}>          
          </i>         
          <i className="fa fa-paperclip fa-2x mr-3 mt-2 mb-2 text-info clickable" 
            onClick={() => openModal("Add new resource (Attach)", <AddClassResourceAttach courseCode={match.params.courseCode}/>, 'modal-pane-1')}>          
          </i>                  
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.courseResources?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
            .map(courseResource =>
            <tr key={courseResource.id}>
            <td>{courseResource.seqNo}
            <i className="fa fa-edit ml-2 text-info clickable" 
              onClick={() => openModal(`Set SeqNo for ${courseResource.title}`, 
              <SetCourseResourceSeqNo courseResourceId={courseResource.id} seqNo={courseResource.seqNo.toString()} />, 'modal-pane-2')} >          
            </i>    
            </td>
            <td>
              {courseResource.url && (
                <a href={courseResource.url} target="_blank" rel="noopener noreferrer">{courseResource.title}</a>
              )}
              {!courseResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${courseResource.title}`, <ContentModal content={courseResource.content}/>, 'modal-pane-1')}>
                  {courseResource.title}
                </span>
              )}
              <i className="fa fa-trash ml-2 text-danger clickable" onClick={() => handleRemoveResource(courseResource.id)}></i>
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>

      {courseContent?.nonSepTopics?.map((topic, idx) => 
        <React.Fragment>
          <div className='mt-3' style={{display: 'flex'}}>
              <h3 className='mr-auto' style={{color:'darkcyan'}}>{topic.name}</h3> 
              <Link to={`/coursetopics/setitems/${topic.id}/${topic.topicId}`}>
                <i className="fa fa-edit fa-2x mr-3 text-info clickable"></i>       
              </Link>              
          </div>
          <Table striped bordered hover className="mb-3">
          <thead>            
            <tr>
              <th>Category</th>
                <th>SeqNo</th>
                <th>Title</th>
                {topic.hasConcepts &&
                  (<th>Concepts</th>)
                }
                {topic.hasAnalysis &&
                  (<th>Analysis</th>)
                }
                {topic.hasCode &&
                  (<th>Code</th>)
                }                        
          </tr>  
          </thead>
          <tbody>
            {topic.topicItems.map(topicItem => 
            <tr key={topicItem.id}>
              <td>{topicItem.category}</td>
              <td>{topicItem.seqNo}</td>
              <td>
              {topicItem.url && (
                <a href={topicItem.url} target="_blank" rel="noopener noreferrer">{topicItem.title}</a>
              )}               
              </td>
              {topic.hasConcepts && (
                <td>{topicItem.concepts}</td>
              )}
              {topic.hasAnalysis && (
                 (<td> 
                  {topicItem.analysisUrl && (
                  <a href={topicItem.analysisUrl} target="_blank" rel="noopener noreferrer">Analysis</a>
                  )}                
                  </td>)               
              )}
              {topic.hasCode &&
                (<td>
                {topicItem.code  && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`Code for ${topicItem.title}`, 
                    <CodeModal code={topicItem.code} />, 'modal-pane-1')}>
                      Code
                    </span>           
                )}                                    
                </td>)
              }              
            </tr>
            )}
          </tbody>                   
          </Table>
        </React.Fragment>
      )}

      {courseContent?.sepTopics?.map((topic, idx) =>
        <div className='mt-3' style={{display: 'flex'}} key={topic.id}>
        {topic.isWorkbook && (
        <Link to={`/coursetopics/showteacheritems/${topic.id}`} className='mr-auto' >
          <h3 style={{color:'blue'}}>{topic.name}</h3>  
        </Link>
        )} 
        {!topic.isWorkbook && (
        <Link to={`/coursetopics/showitems/${topic.id}`} className='mr-auto' >
          <h3 style={{color:'blue'}}>{topic.name}</h3>  
        </Link>
        )}  
        <Link to={`/coursetopics/setitems/${topic.id}/${topic.topicId}`}>
          <i className="fa fa-edit fa-2x mr-3 text-info clickable"></i>       
        </Link>
        </div>            
      )}
    </div>
  );
}

export default observer(TeacherClassForm)
