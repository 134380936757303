import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table} from 'react-bootstrap'
import StudentReportModal from '../adminClasses/StudentReportModal';

const ParentClassesForm = () => {
  const rootStore = useContext(RootStoreContext);    
  const { openModal} = rootStore.modalStore;      
  const {parentCourses, loadParentCourses} = rootStore.userStore; 

  useEffect(() => {
    loadParentCourses();
  }, [loadParentCourses])

  return (
    <Container fluid style={{ marginTop: '2em'}}>  
     <Table striped bordered hover>
      <thead>
        <tr>
          <td>Name</td>
          <td>Student Name</td>
          <td>Level</td>
          <td>Start Date</td>
          <td>End Date</td>
          <td>Scheduled Time</td>
          <td>Teacher</td>
          <td>Active</td>                                                                      
        </tr>
      </thead>
      <tbody>
        {parentCourses && 
        parentCourses.map(course => (
          <tr key={course.courseCode} className="clickable" onClick={(e: any) => {
            openModal(`report of ${course.studentFullName}`, 
            <StudentReportModal courseCode={course.courseCode} userName={course.studentUserName}/>, 'modal-pane-1')             
          }}>
            <td>{course.courseName}</td>
            <td>{course.studentFullName}</td>
            <td>{course.subject?.level}</td>
            <td>{new Date(course.startDate).toISOString().substring(0, 10)}</td>
            <td>{new Date(course.endDate).toISOString().substring(0, 10)}</td>
            <td>{course.dayOfTheWeek + ' ' + course.timePeriod}</td>
            <td>{course.teacher!.firstName + ' ' + course.teacher!.lastName}</td>
            <td>{course.active ? 'Yes' : 'No'}</td>                                                                                    
          </tr>
        ))
        }
      </tbody>
     </Table>
    </Container>      
  )  
}

export default observer(ParentClassesForm)