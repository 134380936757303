import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';
import { observer } from 'mobx-react-lite';
import GoogleLoginSegment from './GoogleLoginSegment';
import { IErrorInfo } from '../../app/models/errorInfo';

const initialValues = {
  email: '',
  password: ''
};
const verificationError = {
  invalidEmail: false,
  emptyPassword: false
};
const intialSubmitErrorInfo: IErrorInfo = {
  errors: [],
  text: ''
};

const LoginForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { login, gLogin, gLoading } = rootStore.userStore;
  const [values, setValues] = useState(initialValues);
  const [valueErrors, setValueErrors] = useState(verificationError);
  const [submitting, setSubmitting] = useState(false);  
  const [submitError, setSubmitError] = useState(intialSubmitErrorInfo);
  const onFailure = (error: any) => {
    console.log(error);
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitError(intialSubmitErrorInfo);
    const invalidEmail = values.email.length === 0;
    const emptyPassword = values.password.length === 0;
    setValueErrors({...valueErrors, invalidEmail, emptyPassword});
    if(invalidEmail || emptyPassword) {

    }
    else {
      setSubmitting(true);   
      login(values).catch(error => {
        if(error.data && error.statusText){
          const submitErrorInfo1 = {
            errors: error.data.errors, 
            text: error.statusText
          };
          setSubmitError(submitErrorInfo1);
        }
        else {
          const submitErrorInfo3 = {
            errors: [], 
            text: "Login Failed"
          };
          setSubmitError(submitErrorInfo3);       
        } 
      }).finally(() => {
        setSubmitting(false);
      });     
    }
  };

  return (
    <Container className="content">
          <Form noValidate>
          <Row className="form-field, pt-3">
              <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                Email
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="email"
                name="email"
                value = {values.email}
                onChange={handleChange}
                placeholder="Email"
              />
              </Col>
            </Row>
            <Row className="form-field">
              <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                Password
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="password"
                name="password"
                value = {values.password}
                onChange={handleChange}                
                placeholder="Password"
              />
              </Col>
            </Row>
            <ul className="mb-4">
              {valueErrors.invalidEmail && (
                <li className="text-danger">Empty or invalid email</li>
              )}
              {valueErrors.emptyPassword && (
                <li className="text-danger">Empty Password</li>
              )}
            </ul>
            {submitError.text && (
                <ErrorMessage errors={submitError.errors} text={submitError.text} />
            )}            
            <Row className="d-flex justify-content-center pb-3 pt-2">
            {!submitting && <Button variant="primary" onClick={handleSubmit}>Login</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
            </Row>            
            <Row>
              <Col className="col">
                <hr/>
              </Col>
              <Col className="col-auto">
                OR
              </Col>
              <Col className="col">
                <hr/>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center pb-3 pt-2">
            <GoogleLoginSegment loading={gLoading} onSuccess={gLogin} onFailure={onFailure}/>
            </Row>
          </Form>
        </Container>
  );
};

export default observer(LoginForm);
