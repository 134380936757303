import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Container, Card, Button} from 'react-bootstrap';
import parse from 'html-react-parser'
import "./qa.css";
import AddAnswerModal from './AddAnswerModal';
import UpdateAnswerModal from './UpdateAnswerModal';
import {convertUTCDateToLocalDataString, convertUTCDateToLocalTimeString } from '../helper/DateHelper';

interface QuestionParams {
  questionId: string;
}

const TeacherQuestionForm: React.FC<RouteComponentProps<QuestionParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);  
    const {currentQuestion, loadQuestion} = rootStore.qaStore;
    const {openModal} = rootStore.modalStore;
    
    useEffect(() => {
      const loadValues = async() => {
        await loadQuestion(match.params.questionId);
      }
      if(match.params.questionId) {
        loadValues();
      }
    }, [loadQuestion, match.params.questionId])


    return (
      <Container>       
          <Card className="mt-3 mb-2 text-info">
            <Card.Header>
              <div style={{display: 'flex'}}>
                <h3 className='mr-auto text-info'>{currentQuestion?.topic}</h3>
                <Button className="mr-3" 
                onClick={() => history.goBack()}
                >Back to Q&A</Button>              
                <Button variant="info"
                onClick={() => openModal(`Reply to question ${currentQuestion?.topic}`, 
                <AddAnswerModal questionId={match.params.questionId}/>, 'modal-pane-65')}
                >Reply
                </Button>                
              </div>
            </Card.Header>
            <Card.Body className="question">
              <Card.Title className="author">{currentQuestion?.userFullName}, &nbsp; &nbsp;
                {currentQuestion ? convertUTCDateToLocalDataString(currentQuestion.date) : ''}
                &nbsp;
                {currentQuestion ? convertUTCDateToLocalTimeString(currentQuestion.date) : ''}
              </Card.Title>
              {currentQuestion ? parse(currentQuestion?.message): ''}
            </Card.Body>
          </Card>
          {currentQuestion?.answers?.slice().sort((a, b) => a.date > b.date ? 1: -1)
          .map((answer, idx) => (
            <Card key={idx} text={idx % 2 === 0 ? 'primary' : 'secondary'}
            className="mb-2">
            <Card.Body>
              <Card.Title className="replier">{answer.userFullName}, &nbsp; &nbsp;
                {convertUTCDateToLocalDataString(answer.date)}
                &nbsp;
                {convertUTCDateToLocalTimeString(answer.date)}     
                <Button className="ml-3" variant="warning"
                onClick={() => openModal(`Update Answer for ${currentQuestion?.topic}`, 
                <UpdateAnswerModal answerId={answer.answerId} message={answer.message}/>, 'modal-pane-65')}
                >Update
                </Button>          
              </Card.Title>              
              {parse(answer.message)}
            </Card.Body>
            </Card>
          ))}
      </Container>
    )
}

export default observer(TeacherQuestionForm)
