import React, {useState, useContext} from 'react'
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const initialValues = {
  title: '',
  url: ''
};

const AddClassResource = ({courseCode}) => {
  const rootStore = useContext(RootStoreContext);
  const {addCourseResource} = rootStore.courseStore;
  const [values, setValues] = useState(initialValues);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    setSubmitting(true);
    if(values.title.length > 0 && values.url.length > 0 ){
      var content = '';
      addCourseResource(courseCode, values.title, values.url, content)
      .finally(() =>{
        setSubmitting(false);
      })
    }
  }

  return (
    <Container>
      <Form noValidate>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Title
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="title"
              value = {values.title}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              hyper link
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              required
              type="text"
              name="url"
              value = {values.url}
              onChange={handleChange}
            />
          </Col>
        </Row>  
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {
          values.title && values.url &&
          !submitting && <Button variant="primary" onClick={handleSubmit}>Add</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>      
      </Form>
    </Container>
  )
}

export default AddClassResource
