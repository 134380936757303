import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Button, Table} from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

interface CourseParams {
  courseId: string;
}

const CourseDiscussionsForm: React.FC<RouteComponentProps<CourseParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {discussions, getCourseDiscussions} = rootStore.discussionStore;

  useEffect(() => {
    const loadValues = async() => {
      await getCourseDiscussions(match.params.courseId);
    }
    if(match.params.courseId){
      loadValues();
    }
  }, [getCourseDiscussions, match.params.courseId])

  return (
    <div className="qa-page mt-3 p-3">
      <div style={{display: 'flex'}}>
        <h2 className='mr-auto active-title'>Latest discussions</h2>        
        <Button className="mr-3 mt-1 mb-3"
          onClick={() => history.goBack()}
        >Go Back</Button>               
      </div>       
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>TopicName</th>
            <th>Category</th>
            <th>SeqNo</th>
            <th>Title</th>            
            <th>Date</th>
            <th>Discuss</th>    
          </tr>
        </thead>
        <tbody>
        {discussions?.slice().sort((a, b) => a.date > b.date ? 1 : -1)
        .map((discussion, index) => 
        <tr key={index}>    
          <td>{discussion.topicName}</td> 
          <td>{discussion.category}</td>
          <td>{discussion.seqNo}</td>          
          <td>
              {discussion.url && (
                <a href={discussion.url} target="_blank" rel="noopener noreferrer">{discussion.title}</a>
              )}              
          </td>
          <td>{(new Date(discussion.date)).toLocaleString()}</td>
          <td>
                <Link to={`/coursetopics/discuss/${discussion.courseTopicId}/${discussion.topicItemId}/${discussion.title}`}>
                  <span className="clickable text-primary"> Discuss</span>
                </Link>
            </td> 
        </tr> 
        )}         
        </tbody>
      </Table>    
    </div>
  )
}

export default observer(CourseDiscussionsForm)