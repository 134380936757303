import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Row, Col, Form, Table} from 'react-bootstrap';
import NewTopicItemLink from './NewTopicItemLink';
import SetTopicModal from './SetTopicModal';
import SetTopicItemCategory from './SetTopicItemCategory';
import SetTopicItemSeqNo from './SetTopicItemSeqNo';
import SetTopicItemConcepts from './SetTopicItemConcepts';
import SetTopicItem from './SetTopicItem';
import AttachAnalysisModal from './AttachAnalysisModal';
import SubmitCodeModal from './SubmitCodeModal';
import CodeModal from '../../app/common/modals/CodeModal';
import EditAnalysisContentModal from './EditAnalysisContentModal';
import ContentModal from '../../app/common/modals/ContentModal';
import LinkAnalysisModal from './LinkAnalysisModal';


interface TopicParams {
  topicId: string;
}

const TopicForm: React.FC<RouteComponentProps<TopicParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {currentTopic, getTopic} = rootStore.topicStore;
  const { openModal} = rootStore.modalStore;
  const [searchCategory, setSearchCategory] = useState('');
  const [searchSeqNo, setSearchSeqNo] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [searchConcept, setSearchConcept] = useState('');

  const handleCategoryChange = (event: any) => {
    setSearchCategory(event.target.value);
  };

  const handleSeqNoChange = (event: any) => {
    setSearchSeqNo(event.target.value);
  };
  
  const handleTitleChange = (event: any) => {
    setSearchTitle(event.target.value);
  };
  
  const handleConceptChange = (event: any) => {
    setSearchConcept(event.target.value);
  };  

  useEffect(() => {
    const loadValues = async() => {
      await getTopic(match.params.topicId);
    }
    if(match.params.topicId) {
      loadValues();
    }
  }, [getTopic, match.params.topicId]);

  const filter_problems = () => {
    var problems = currentTopic?.topicItems?.slice()
    .filter(topicItem => {
      if(searchCategory === "") return true;
      else if(topicItem.category.toLowerCase().includes(searchCategory.toLowerCase())) return true;
      else return false;
    })
    .filter(topicItem => {
      if(searchSeqNo === "") return true;
      else if(topicItem.seqNo.toLowerCase().includes(searchSeqNo.toLowerCase())) return true;
      else return false;
    })     
    .filter(topicItem => {
      if(searchTitle === "") return true;
      else if(topicItem.title.toLowerCase().includes(searchTitle.toLowerCase())) return true;
      else return false;            
    }) 
    .filter(topicItem => {
      if(searchConcept === "") return true;
      else if(!topicItem.concepts) return false;
      else if(topicItem.concepts.toLowerCase().includes(searchConcept.toLowerCase())) return true;
      else return false;            
    });
    return problems ? problems : [];
  };

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className='mr-auto active-title'>{currentTopic?.name}</h2>   
        <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
                onClick={() => openModal(`Edit topic`, <SetTopicModal
                topicId={match.params.topicId} 
                name={currentTopic?.name}
                hasConcepts={currentTopic?.hasConcepts}
                hasAnalysis={currentTopic?.hasAnalysis}
                hasCode={currentTopic?.hasCode}
                isSeparatePage={currentTopic?.isSeparatePage}
                isWorkbook={currentTopic?.isWorkbook}
                submitMode={currentTopic?.submitMode}
                 />, 'modal-pane-1')}>          
              </i>             
        {/* <i className="fa fa-edit fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new item (text)", <NewTopicItemText topicId={match.params.topicId} topicName={currentTopic?.name}/>, 'modal-pane-1')}>          
        </i> */}
        <i className="fa fa-link fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new item (link)", <NewTopicItemLink topicId={match.params.topicId} topicName={currentTopic?.name}/>, 'modal-pane-1')}>          
        </i> 
        {/* <i className="fa fa-paperclip fa-2x mr-3 mt-2 mb-2 text-info clickable" 
          onClick={() => openModal("Add new item (attachment)", <NewTopicItemAttach topicId={match.params.topicId} topicName={currentTopic?.name}/>, 'modal-pane-1')}>          
        </i>      */}
      </div>
      <Row className="mb-3">
        <Col md="auto" className="search-item">
        <Form.Label >
            Category
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchCategory} placeholder="Search..." onChange={handleCategoryChange}>
          </Form.Control>
        </Col>
        <Col md="auto" className="search-item">
        <Form.Label >
            SeqNo
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchSeqNo} placeholder="Search..." onChange={handleSeqNoChange}>
          </Form.Control>
        </Col>
        <Col md="auto" className="search-item">
        <Form.Label >
            Title
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchTitle} placeholder="Search..." onChange={handleTitleChange}>
          </Form.Control>
        </Col> 
        <Col md="auto" className="search-item">
        <Form.Label >
            Concept
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchConcept} placeholder="Search..." onChange={handleConceptChange}>
          </Form.Control>
        </Col>                         
      </Row>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>SeqNo</th>
            <th>Title</th>
            {currentTopic?.hasConcepts &&
              (<th>Concepts</th>)
            }
            {currentTopic?.hasAnalysis &&
              (<th>Analysis</th>)
            }
            {currentTopic?.hasCode &&
              (<th>Code</th>)
            }                        
          </tr>
        </thead>
        <tbody>
          {
          filter_problems()             
          .sort((a, b) => {
            if (a.category > b.category) return 1;
            else if(a.category < b.category) return -1;
            else if(a.seqNo > b.seqNo) return 1;
            else return -1;
          }).map(topicItem => 
            <tr key={topicItem.id}>
              <td>{topicItem.category}
              <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal(`set category for ${topicItem.title}`, <SetTopicItemCategory topicId={match.params.topicId} topicItemId={topicItem.id} category={topicItem.category} />, 'modal-pane-1')}>          
              </i>               
              </td>
              <td>
              {!topicItem.seqNoLink && topicItem.seqNo}
                {topicItem.seqNoLink && (
                <a href={topicItem.seqNoLink} target="_blank" rel="noopener noreferrer">{topicItem.seqNo}</a>
                )}                 
              <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal(`set seqNo for ${topicItem.title}`, <SetTopicItemSeqNo topicId={match.params.topicId} topicItemId={topicItem.id} seqNo={topicItem.seqNo} seqNoLink={topicItem.seqNoLink} />, 'modal-pane-1')}>          
              </i>               
              </td>
              <td>
              {topicItem.url && (
                <a href={topicItem.url} target="_blank" rel="noopener noreferrer">{topicItem.title}</a>
              )}
              <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal(`set item for ${topicItem.title}`, <SetTopicItem topicId={match.params.topicId} topicItemId={topicItem.id} title={topicItem.title} url={topicItem.url} />, 'modal-pane-1')}>          
              </i>               
              </td>
              {currentTopic?.hasConcepts &&
                (<td> {topicItem.concepts}
              <i className="fa fa-edit ml-3 text-info clickable" 
                onClick={() => openModal(`set concepts for ${topicItem.title}`, <SetTopicItemConcepts topicId={match.params.topicId} topicItemId={topicItem.id} concepts={topicItem.concepts} />, 'modal-pane-1')}>          
              </i>                  
                </td>)
              }
              {currentTopic?.hasAnalysis &&
                (<td> 
                {topicItem.analysisUrl && (
                <a href={topicItem.analysisUrl} target="_blank" rel="noopener noreferrer">Analysis</a>
                )}
                {topicItem.analysisContent && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`Analysis of ${topicItem.title}`, <ContentModal content={topicItem.analysisContent}/>, 'modal-pane-1')}>
                    Analysis
                  </span>                
                )}
                 <i className="fa fa-edit ml-3 text-info clickable" 
                  onClick={() => openModal(`Write analysis for ${topicItem.title}`, <EditAnalysisContentModal topicId={topicItem.topicId} topicItemId={topicItem.id} content={topicItem.analysisContent}/>, 'modal-pane-1')}>          
                </i>   
                <i className="fa fa-link ml-3 text-info clickable" 
                  onClick={() => openModal(`Link analysis for ${topicItem.title}`, <LinkAnalysisModal topicId={topicItem.topicId} topicItemId={topicItem.id} url={topicItem.analysisUrl} />, 'modal-pane-1')}>          
                </i>                               
                 <i className="fa fa-paperclip ml-3 text-info clickable" 
                  onClick={() => openModal(`Attach analysis for ${topicItem.title}`, <AttachAnalysisModal topicItemId={topicItem.id}/>, 'modal-pane-1')}>          
                </i>                  
                </td>)
              }
              {currentTopic?.hasCode &&
                (<td>
                {topicItem.code  && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`Code for ${topicItem.title}`, 
                    <CodeModal code={topicItem.code} />, 'modal-pane-1')}>
                      Code
                    </span>           
                )}                  
                <i className="fa fa-code ml-3 text-info clickable" 
                  onClick={() => openModal(`Submit code for ${topicItem.title}`, <SubmitCodeModal topicItemId={topicItem.id}/>, 'modal-pane-1')}>          
                </i>                   
                </td>)
              }                
            </tr>
          )}
        </tbody>
      </Table>  
      <div style={{display: 'flex'}}>   
      <h3 className='mr-auto text-info'>Total problems: {filter_problems().length}</h3>         
      <i className="fa fa-link fa-2x mr-3 text-info clickable" 
          onClick={() => openModal("Add new item (link)", <NewTopicItemLink topicId={match.params.topicId} topicName={currentTopic?.name}/>, 'modal-pane-1')}>          
      </i> 
      </div>                 
    </div>
  )
}

export default observer(TopicForm)
