import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { AppUser} from '../../app/models/user';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';


const ProfileForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { user, setUserProfile } = rootStore.userStore;
  const [submitting, setSubmitting] = useState(false);
  const [currentUser, setCurrentUser] = useState(new AppUser());
  const [isStudent, setIsStudent] = useState(false);

  useEffect(() => {
    setCurrentUser(new AppUser(user));
    if(user.roles.includes("Student")){
      setIsStudent(true);
    }
  }, [user])

  const handleChange = (event) => {
    const { name, value, checked } = event.target; 
    if (name === "active") {
      setCurrentUser({...currentUser, [name]: checked});  
    }
    else{
      setCurrentUser({...currentUser, [name]: value});       
    }
  };

  const handleSubmit = () => {
    var userProfile  = {
      id: currentUser.id,
      userName: currentUser.userName,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      city: currentUser.city,
      age: currentUser.age,
      grade: currentUser.grade,
      phone: currentUser.phone,
      wechat: currentUser.wechat,
      parentFirstName: currentUser.parentFirstName,
      parentLastName: currentUser.parentLastName,
      introduction: currentUser.introduction,
    };
    setSubmitting(true);
    setUserProfile(userProfile)
    .finally(() => {
      setSubmitting(false);
    })
  }; 

  return (
    <Container className="content">
      <h2 className={` mr-auto ${user.active? "active-title" : "inactive-title"}`}>Profile of {user.userName}</h2>
      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Email
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={user.email} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Check type="checkbox" label="Active" checked={user.active} 
            name="active" onChange={handleChange}/>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Roles
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={user.roles.toString()} readOnly>            
          </Form.Control>
          </Col> 
        </Row>
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                First Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="firstName"
                value = {currentUser.firstName}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Last Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="lastName"
                value = {currentUser.lastName}
                onChange={handleChange}
              />
              </Col>
        </Row>  
        {isStudent &&
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Age
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="age"
                value = {currentUser.age || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Grade
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="grade"
                value = {currentUser.grade || ''}
                onChange={handleChange}
              />
              </Col>
          </Row>  
        } 
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Phone
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="phone"
                value = {currentUser.phone || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Wechat
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="wechat"
                value = {currentUser.wechat || ''}
                onChange={handleChange}
              />
              </Col>
        </Row>              
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            City
          </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            type="text" 
            name="city" 
            value = {currentUser.city || ''}
            onChange={handleChange}
            >
          </Form.Control>
          </Col>     
        </Row>
        {isStudent && 
            <Row>
              <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Parent FirstName
                </Form.Label>
                </Col>
                <Col lg={4} md={4} sm={4}>
                <Form.Control
                  type="text"
                  name="parentFirstName"
                  value = {currentUser.parentFirstName || ''}
                  onChange={handleChange}
                />
                </Col>   
                <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Parent LastName
                </Form.Label>
                </Col>
                <Col lg={4} md={4} sm={4}>
                <Form.Control
                  type="text"
                  name="parentLastName"
                  value = {currentUser.parentLastName || ''}
                  onChange={handleChange}
                />
                </Col>                 
              </Row>
            }          
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Introduction
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={8} className="full-control" name="introduction" value={currentUser.introduction || ''} 
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
          </Row>         
        <Row className="d-flex justify-content-center pb-3 mt-5">
        {!submitting && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {submitting && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  );
};

export default ProfileForm
