import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';


const SetTopicItemConcepts = ({topicId, topicItemId, concepts}) => {
  const rootStore = useContext(RootStoreContext);
  const {setTopicItemConcepts} = rootStore.topicStore;
  const [newConcepts, setNewConcepts] = useState(concepts);
  const [submitting, setSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
   
  const handleChange = (event) => {
    const {value} = event.target;
    setNewConcepts(value);
    if(value && value !== concepts) {
      setIsChanged(true);
    }
    else{
      setIsChanged(false);
    }
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    setTopicItemConcepts(topicId, topicItemId, newConcepts);
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={4} md={4} sm={4}>
              <Form.Label className="mt-3">
                Concepts
              </Form.Label>
              </Col>
              <Col lg={8} md={8} sm={8}>
              <Form.Control
                required
                type="text"
                name="concepts"
                value = {newConcepts}
                onChange={handleChange}
              />
            </Col>
          </Row>  
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isChanged && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetTopicItemConcepts
