import React, { useState } from 'react';
import {Alert, ListGroup, Container} from 'react-bootstrap';

interface IProps {
  errors: any;
  text: string;
}

export const ErrorMessage: React.FC<IProps> = ({ errors, text }) => {
  const [show, setShow] = useState(true);
  return (
    <Container>
      {show && (
    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{ text }</Alert.Heading>
        {Object.keys(errors).length > 0 && (
          <ListGroup>
            {Object.values(errors).flat().map((err: any, i) => (
              <ListGroup.Item key={i}>{ err }</ListGroup.Item>
            ))}
          </ListGroup>
        )}
    </Alert>  
    )}
    </Container>
 
  );
};
