import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import {Card, Button} from 'react-bootstrap';
import parse from 'html-react-parser'
import "./discussion.css";
import AddDiscussionModal from './AddDiscussionModal'
import {convertUTCDateToLocalDataString, convertUTCDateToLocalTimeString } from '../helper/DateHelper';

interface TopicItemParams {
  courseTopicId: string;
  topicItemId: string;
  title: string;
}

const TopicItemDiscussForm: React.FC<RouteComponentProps<TopicItemParams>> = ({
  match, history}) => {
    const rootStore = useContext(RootStoreContext);  
    const {discussions, loadDiscussions} = rootStore.courseTopicStore;
    const {openModal} = rootStore.modalStore;
    
    useEffect(() => {
      const loadValues = async() => {
        await loadDiscussions(match.params.courseTopicId, match.params.topicItemId);
      }
      if(match.params.courseTopicId && match.params.topicItemId) {
        loadValues();
      }
    }, [loadDiscussions, match.params.courseTopicId, match.params.topicItemId])
    
  return (
    <div className="discussion-page mt-3 p-3">
          <Card className="mt-3 mb-2 text-info">
            <Card.Header>
              <div style={{display: 'flex'}}>
                <h3 className='mr-auto text-info'>Discussions for <span style={{color: 'red'}}>{match.params.title}</span></h3>
                <Button className="mr-3" 
                onClick={() => history.goBack()}
                >Go Back</Button>              
                <Button variant="info"
                onClick={() => openModal(`Add new discussion to ${match.params.title}`, 
                <AddDiscussionModal courseTopicId={match.params.courseTopicId} topicItemId={match.params.topicItemId}/>, 'modal-pane-65')}
                >Post
                </Button>                
              </div>
            </Card.Header>
          </Card>
          {discussions?.slice().sort((a, b) => a.date > b.date ? 1: -1)
          .map((discussion, idx) => (
            <Card key={idx} text={idx % 2 === 0 ? 'primary' : 'secondary'}
            className="mb-2">
            <Card.Body>
              <Card.Title className="author">{discussion.userFullName}, &nbsp; &nbsp;
                {convertUTCDateToLocalDataString(discussion.date)}
                  &nbsp;
                {convertUTCDateToLocalTimeString(discussion.date)}            
              </Card.Title>
              {parse(discussion.message)}
            </Card.Body>
            </Card>
          ))}      
    </div>
  )
}

export default observer(TopicItemDiscussForm)