export const BeginnerClassTree = 
[
    {
        courseId: 101,
        courseName: 'Scratch: Intro to Coding',
        courseLevel: 'Beginner',
        Recommendation: 'Recommended for kids ages 6+',
        Preview: 
            "Learn the basics of coding while creating fun Scratch games and animations! Perfect for those with little to no coding experience.",
        Description:
            " use a text document (?)",
        image: '/images/home/scratch_1.png',
        link: '/scratch-class'
    },
    {
        courseId: 102,
        courseName: 'Basics of Programming',
        courseLevel: 'Beginner',
        Recommendation: 'Recommended for kids ages 7+',
        Preview: 
            "Start learning coding languages used in professional fields, such as Java and Python. Our lessons are designed to teach your child proper coding habits and to practice their problem solving skills.",

        Description:
            " use a text document (?)",
        image: '/images/home/basic_programming_1.png'  ,
        link: '/basics-class'
    },
];