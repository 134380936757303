export const AdvancedClassTree = 
[
      {
        courseId: 301,
        courseName: 'Junior Algorithm',
        courseLevel: 'Intermediate',
        Recommendation: 'Recommended for kids ages 11+',
        Preview: 
            "Wanting a challenge? Prepare your child for Canadian Computing Competition (CCC) - Junior Level.",

        Description:
            " use a text document (?)",
        image: '/images/home/algorithm_1.png',
        link: '/algorithm-junior'
    },
    {
        courseId: 302,
        courseName: 'C++',
        courseLevel: 'Advanced',
        Recommendation: 'Recommended for kids ages 12+',
        Preview: 
            "Learn the basics of C++ while learning more advanced coding concepts. With C++, our instructor will guide your child through this incredible complex yet powerful language.",
        Description:
            " use a text document (?)",
        image: '/images/home/cpp_1.png'  ,
        link: '/c++'
    },
    {
      courseId: 303,
      courseName: 'Math Enhancement',
      courseLevel: 'Advanced',
      Recommendation: 'Recommended for kids ages 12+',
      Preview: 
        `Sharpen your math skills with our Math Enhancement course! Our material covers 
          what is taught in school plus more, giving your child the headstart for math competition.`,
      Description:
      " use a text document (?)",
      image: '/images/home/math_2.png'  ,
      link: '/math-enhancement'   
    } 
];