import React, {useState, useContext, useEffect} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Row, Spinner } from 'react-bootstrap';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../app/layout/editorStyle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SetNoticeModal = ({courseCode, notice}) => {
  const rootStore = useContext(RootStoreContext);
  const {saveNotice} = rootStore.courseStore;
  const [submitting, setSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  }; 

  useEffect(() => {
    if(notice) {
      const blocksFromHTML = htmlToDraft(notice);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));       
    }
  }, [notice])
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    var currentContent = editorState.getCurrentContent();
    if(currentContent){    
      setSubmitting(true);
      saveNotice(courseCode, draftToHtml(convertToRaw(currentContent)));
      setSubmitting(false);
    }
  }

  return (
    <Container>
      <Form>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        /> 
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {editorState.getCurrentContent() && 
          !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetNoticeModal
