import React from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';

const StudentClassInfo = ({courseContent})  => {
  const getLevel = () => {
    if(courseContent){
      switch(courseContent.subject.level){
        case 2:
          return 'Intermediate';
        case 3:
          return 'Advanced';
        case 4:
          return 'Challenging';
        default:
          return 'Beginner';  
      }      
    }
    else return '';
  };

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className={`ml-3 mr-auto ${courseContent.active ? "text-primary" : "text-secondary"}`}>Course: {courseContent.courseName}</h2>
      </div>

      <Form className="mt-3">
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseContent.subject?.name} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseContent.subject?.code} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={getLevel()} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Check type="checkbox" label="Active" checked={courseContent.active} 
                name="active" readOnly/>
          </Col>            
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Code
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              value = {courseContent.courseCode}
              readOnly
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Session
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent.session}
                readOnly
              />
              </Col>          
        </Row>         
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {new Date(courseContent.startDate).toISOString().substr(0, 10)}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {new Date(courseContent.endDate).toISOString().substr(0, 10)}
                readOnly
              />
              </Col>
          </Row>  
        <Row className="form-field">
            <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Day of the week
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent.dayOfTheWeek || ''}
                readOnly
              />
              </Col> 
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent.timePeriod || ''}
                readOnly
              />
              </Col>             
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent.numOfLessons}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                type="text"
                value = {courseContent.teacher.firstName + ' ' + courseContent.teacher.lastName}
                readOnly
              />
              </Col>                                            
        </Row> 
        <Row>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Description
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={5} className="full-control" value={courseContent.description} readOnly
              >                
              </Form.Control>
          </Col>   
        </Row>                                                                
      </Form>

      <div className='mt-5' style={{display: 'flex'}}>
        <h3 className='mr-auto'>Assistants</h3>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.assistants
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })
          .map((assistant, index) => 
            <tr key={assistant.userName}>
            <td>{index+1}</td>
            <td>{assistant.firstName+' ' + assistant.lastName}</td>
          </tr>
          )}       
        </tbody>
      </Table>         
    </div>
    
  );  
}

export default StudentClassInfo
