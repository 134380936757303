import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

export const ChangePasswordModal = () => {
  const rootStore = useContext(RootStoreContext);
  const { changePassword } = rootStore.userStore;
  const [oldPassword, setOldPassword] = useState('');  
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    const {name, value} = event.target;
    if (name === "oldPassword") {
      setOldPassword(value);
      if(value.length < 8) setIsValid(false);
    }
    else if (name === "password"){
      setPassword(value);
      if (value.length < 8 ||
        value !== rePassword){
          setIsValid(false);
        }
      else{
        setIsValid(true);
      }
    }
    else if(name === "rePassword"){
      setRePassword(value);
      if (password.length < 8 ||
        password !== value){
          setIsValid(false);
        }
      else{
        setIsValid(true);
      }
    }
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    changePassword(oldPassword, password);
    setSubmitting(false);
  }  
  return (
    <Container>
      <Form>
        <Row className="form-field">
          <Col lg={4} md={4} sm={4}>
          <Form.Label className="mt-3">
            Old Password
          </Form.Label>
          </Col>
          <Col lg={8} md={8} sm={8}>
          <Form.Control
            required
            type="password"
            name="oldPassword"
            value = {oldPassword}
            onChange={handleChange}                
            placeholder="Password"
          />
          </Col>
        </Row>         
        <Row className="form-field">
          <Col lg={4} md={4} sm={4}>
          <Form.Label className="mt-3">
            Password (8+ characters)
          </Form.Label>
          </Col>
          <Col lg={8} md={8} sm={8}>
          <Form.Control
            required
            type="password"
            name="password"
            value = {password}
            onChange={handleChange}                
            placeholder="Password"
          />
          </Col>
        </Row> 
        <Row className="form-field">
          <Col lg={4} md={4} sm={4}>
          <Form.Label className="mt-3">
          Confirm password
          </Form.Label>
          </Col>
          <Col lg={8} md={8} sm={8}>
          <Form.Control
            required
            type="password"
            name="rePassword"
            value = {rePassword}
            onChange={handleChange}                
            placeholder="Confirm passsword"
          />
          </Col>
        </Row>             
        <Row className="d-flex justify-content-center pb-3 mt-2">
          {isValid && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
        </Row>    
      </Form>
    </Container>
  )
};

export default ChangePasswordModal