import { observable, action } from "mobx";
import { RootStore } from "./rootStore";

export default class ModalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
    @observable.shallow modal = {
        open: false,
        title: null,
        body: null,
        modal_class: '',
        error: ''
    }

    @action openModal = (title: any, content: any, modal_class: any = '') => {
        this.modal.open = true;
        this.modal.title = title;
        this.modal.body = content;
        this.modal.modal_class = modal_class;   
        this.modal.error = '';     
    }

    @action closeModal = () => {
        this.modal.open = false;
        this.modal.title = null;
        this.modal.body = null;
        this.modal.modal_class = '';
        this.modal.error = '';           
    }

    @action setError = (error: any = '') =>{
      this.modal.error = error;
    }
}