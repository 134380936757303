import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import ClassInfo from './ClassInfo';
import { Container, Table } from 'react-bootstrap';

const ClassesForm = () => {
  const rootStore = useContext(RootStoreContext);  
  const {courses, loadActiveCourses} = rootStore.coursesStore;
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    loadActiveCourses();
  }, [loadActiveCourses])

  const convertLevel = (level : number) => {
    if(level === 1) return "Beginner";
    else if(level === 2) return "Intermediate";
    else if(level === 3) return "Advanced";
    else return "Challenging";
  };

  return ( 
    <Container fluid className="table-page">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Level</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Scheduled Time</th>
            <th>Teacher</th>
          </tr>
        </thead>
        <tbody>
          {courses &&
          courses.map(course => (
            <tr key={course.courseCode} className="clickable" onClick={(e: any) => {
              openModal('Class Info', <ClassInfo courseInfo={course} />, 'modal-pane-3')}}>
              <td>{course.courseCode}</td>
              <td>{course.courseName}</td>
              <td>{convertLevel(course.subject!.level)}</td>
              <td>{new Date(course.startDate).toISOString().substring(0, 10)}</td>
              <td>{new Date(course.endDate).toISOString().substring(0, 10)}</td>
              <td>{course.dayOfTheWeek + ' ' + course.timePeriod}</td>
              <td>{course.teacher!.firstName + ' ' + course.teacher!.lastName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
          
  )
}

export default observer(ClassesForm)
