import PythonProblemStore from './pythonProblemStore';
import ModalStore from './modalStore';
import { createContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import UsersStore from './usersStore';
import RoleStore from './roleStore';
import CourseStore from './courseStore';
import CoursesStore from './coursesStore';
import SubjectStore from './subjectStore';
import TeacherStore from './teacherStore';
import NumberPuzzleStore from './numberPuzzleStore';
import StudentStore from './studentStore';
import LessonStore from './lessonStore';
import AssignmentStore from './assignmentStore';
import InquiryStore from './inquiryStore';
import QAStore from './qaStore';
import TopicStore from './topicStore';
import CourseTopicStore from './courseTopicStore';
import DiscussionStore from './discussionStore';
import AchievementStore from './achievementStore';
import AnnouncementStore from './announcementStore';
import AssistantStore from './assistantStore';

export class RootStore {
    pythonProblemStore: PythonProblemStore;
    modalStore: ModalStore;
    userStore: UserStore;
    commonStore: CommonStore;
    usersStore: UsersStore;
    roleStore: RoleStore;
    courseStore: CourseStore;
    coursesStore: CoursesStore;
    subjectStore: SubjectStore;
    teacherStore: TeacherStore;
    assistantStore: AssistantStore;
    studentStore: StudentStore;
    lessonStore: LessonStore;
    assignmentStore: AssignmentStore;
    numberPuzzleStore: NumberPuzzleStore;
    inquiryStore: InquiryStore;
    qaStore: QAStore;
    topicStore: TopicStore;
    courseTopicStore: CourseTopicStore;
    discussionStore: DiscussionStore;
    achievementStore: AchievementStore;
    announcementStore: AnnouncementStore;

    constructor() {
        this.pythonProblemStore = new PythonProblemStore(this);
        this.modalStore = new ModalStore(this);
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this); 
        this.usersStore = new UsersStore(this);
        this.roleStore = new RoleStore(this);
        this.courseStore = new CourseStore(this);
        this.coursesStore = new CoursesStore(this);
        this.subjectStore = new SubjectStore(this);
        this.teacherStore = new TeacherStore(this);
        this.assistantStore = new AssistantStore(this);
        this.studentStore = new StudentStore(this);
        this.lessonStore = new LessonStore(this);
        this.assignmentStore = new AssignmentStore(this);
        this.numberPuzzleStore = new NumberPuzzleStore(this);
        this.inquiryStore = new InquiryStore(this);
        this.qaStore = new QAStore(this);
        this.topicStore = new TopicStore(this);
        this.courseTopicStore = new CourseTopicStore(this);
        this.discussionStore = new DiscussionStore(this);
        this.achievementStore = new AchievementStore(this);
        this.announcementStore = new AnnouncementStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());