import React, { useContext, useEffect, Fragment } from 'react';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import LoginForm from '../user/LoginForm';
import { RegisterForm } from '../user/RegisterForm';

import { observer } from 'mobx-react-lite';
import ChangePasswordModal from '../user/ChangePasswordModal';

interface IProps {
  children: JSX.Element;
}

const NavBarDesktop: React.FC<IProps> = ({ children }) => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user, logout } = rootStore.userStore;
  const {inquiries, getInquires} = rootStore.inquiryStore;
  const {questions, taQuestions, getQuestions, getTAQuestions} = rootStore.qaStore;
  const { openModal} = rootStore.modalStore;
  const token = window.localStorage.getItem('jwt');

  useEffect(() => {
    // get inquiries only when user is admin
    if(user && user.roles.includes('Admin')){
      getInquires();
    }
    if(user && user.roles.includes('Teacher')){
      getQuestions();
    }
    if(user && user.roles.includes('Assistant')){
      getTAQuestions();
    }
  }, [getInquires, getQuestions, getTAQuestions, user]);

  return (
    <Fragment>
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="primary"
        variant="dark"
        className="main-nav"
      >
        <Navbar.Brand as={NavLink} exact to="/">
          <img
            alt=""
            src="/assets/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          ABAITC
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"> 
            <Nav.Link as={NavLink} to="/courses">Courses</Nav.Link>
            <Nav.Link as={NavLink} to="/staff">Staff</Nav.Link>            
            <Nav.Link as={NavLink} to="/classes">Current Classes</Nav.Link>                        
            <Nav.Link as={NavLink} to="/achievements">Honor Rolls</Nav.Link>         
            <Nav.Link as={NavLink} to="/contact">Contact Us</Nav.Link>
            {user && (
              <NavDropdown title="Number Puzzle" id="numberpuzzle" renderMenuOnMount={true}>
                <NavDropdown.Item as={Link} to="/numberpuzzle/ranks">Ranks</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/numberpuzzle/solve">Puzzle</NavDropdown.Item>
              </NavDropdown>
            )}
            {user && user.roles.includes('Admin') && (
              inquiries.some(x=>!x.checked) ? (
              <NavDropdown title={<div style={{display: "inline-block"}}>Admin 
                <i className="fa fa-bell text-warning"></i></div>} id="admin" renderMenuOnMount={true}>
                <NavDropdown.Item as={Link} to="/users">Users</NavDropdown.Item>     
                <NavDropdown.Item as={Link} to="/inquiries">Inquiries</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/a-announcements">Announcements</NavDropdown.Item>  
                <NavDropdown.Item as={Link} to="/a-achievements">Honor Rolls</NavDropdown.Item>                           
              </NavDropdown>                         
              ) : (
              <NavDropdown title="Admin" id="admin" renderMenuOnMount={true}>
                <NavDropdown.Item as={Link} to="/users">Users</NavDropdown.Item>     
                <NavDropdown.Item as={Link} to="/inquiries">Inquiries</NavDropdown.Item>   
                <NavDropdown.Item as={Link} to="/a-announcements">Announcements</NavDropdown.Item>  
                <NavDropdown.Item as={Link} to="/a-achievements">Honor Rolls</NavDropdown.Item>                                                         
              </NavDropdown> 
              ))}  
            {user && user.roles.includes('CourseAdmin') && (              
                <NavDropdown title="Course Admin" id="courseAdmin" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/subjects">Subjects</NavDropdown.Item>                     
                  <NavDropdown.Item as={Link} to="/a-classes">Classes</NavDropdown.Item>                
                </NavDropdown>                 
            )}      
            {user && user.roles.includes('Teacher') && (
              questions.some(x=>!x.viewed) ? (             
                <NavDropdown title={<div style={{display: "inline-block"}}>Teacher 
                <i className="fa fa-bell text-warning"></i></div>} id="teacher" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/t-classes">Classes</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/topics">Topics</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/questions">Latest Questions</NavDropdown.Item>
                </NavDropdown>  
              ) : (
                <NavDropdown title="Teacher" id="teacher" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/t-classes">Classes</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/topics">Topics</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/questions">Latest Questions</NavDropdown.Item>
                </NavDropdown>     
              )            
            )}  
            {user && user.roles.includes('Assistant') && (
              taQuestions.some(x=>!x.viewed) ? (             
                <NavDropdown title={<div style={{display: "inline-block"}}>Assistant
                <i className="fa fa-bell text-warning"></i></div>} id="assistant" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/h-classes">Classes</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/h-topics">Topics</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/h-questions">Latest Questions</NavDropdown.Item>
                </NavDropdown>  
              ) : (
                <NavDropdown title="Assistant" id="assistant" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/h-classes">Classes</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/h-topics">Topics</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/h-questions">Latest Questions</NavDropdown.Item>
                </NavDropdown>     
              )            
            )}             
            {user && user.roles.includes('Student') && (
                <NavDropdown title="Student" id="student" renderMenuOnMount={true}>
                  <NavDropdown.Item as={Link} to="/s-classes">Classes</NavDropdown.Item>
                </NavDropdown>                 
            )}
            {user && user.roles.includes('Parent') && (
                <NavDropdown title="Parent" id="parent" renderMenuOnMount={true}>
                <NavDropdown.Item as={Link} to="/p-classes">Classes</NavDropdown.Item>
              </NavDropdown>          
            )}       
          </Nav>
          <Nav >
            {/* already signed in */}
            {isLoggedIn && user && token ? (
              <NavDropdown title={'Hello ' + user.firstName + ' ' + user.lastName} id="user-id">
                <NavDropdown.Item as={Link} to="/users/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={() => openModal("Change Password", <ChangePasswordModal />)}>
                  Reset Password
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>        
            ) : (
             <>
              <Nav.Item className="nav-item-user" onClick={() => openModal("Login to ABAITC", <LoginForm />)}>
                Login
              </Nav.Item> 
              <Nav.Item className="nav-item-user" onClick={() => openModal("Register for ABAITC", <RegisterForm />)}>
                Register
              </Nav.Item> 
             </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
    </Fragment>
  );
};

export default observer(NavBarDesktop);
