import React, {useState, useContext} from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Form, Button, Col, Row, Spinner } from 'react-bootstrap';

const SetParentModal = ({userName, parentEmail, parentFirstName, parentLastName}) => {
  const rootStore = useContext(RootStoreContext);
  const {setParent} = rootStore.usersStore;
  const [newParentEmail, setNewParentEmail] = useState(parentEmail);
  const [newParentFirstName, setNewParentFirstName] = useState(parentFirstName);
  const [newParentLastName, setNewParentLastName] = useState(parentLastName);
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    const {name, value} = event.target;
    if(name === "parentEmail"){
      setNewParentEmail(value);
      if(value.includes("@") && !value.includes(" ")
      && newParentFirstName && newParentLastName){
        setIsValid(true);
      }
      else {
        setIsValid(false);
      }
    }
    else if(name === "parentFirstName"){
      setNewParentFirstName(value);
      if(newParentEmail.includes("@") && !newParentEmail.includes(" ")
      && value && newParentLastName){
        setIsValid(true);
      }
      else {
        setIsValid(false);
      }      
    } else if(name === "parentLastName"){
      setNewParentLastName(value);
      if(newParentEmail.includes("@") && !newParentEmail.includes(" ")
      && newParentFirstName && value){
        setIsValid(true);
      }
      else {
        setIsValid(false);
      }        
    }
  }

  const handleSubmit = (event) => {
    setSubmitting(true);
    setParent(userName, newParentEmail, newParentFirstName, newParentLastName);
    setSubmitting(false);
  }

  return (
    <Container>
      <Form>
        <Row className="form-field">
            <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                Parent Email
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="text"
                name="parentEmail"
                value = {newParentEmail}
                onChange={handleChange}
              />
            </Col>
          </Row>  
          <Row className="form-field">
            <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                Parent First Name
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="text"
                name="parentFirstName"
                value = {newParentFirstName}
                onChange={handleChange}
              />
            </Col>
          </Row>   
          <Row className="form-field">
            <Col lg={3} md={3} sm={3}>
              <Form.Label className="mt-3">
                Parent Last Name
              </Form.Label>
              </Col>
              <Col lg={9} md={9} sm={9}>
              <Form.Control
                required
                type="text"
                name="parentLastName"
                value = {newParentLastName}
                onChange={handleChange}
              />
            </Col>
          </Row>                   
          <Row className="d-flex justify-content-center pb-3 mt-2">
          {isValid && !submitting && <Button variant="primary" onClick={handleSubmit}>Confirm</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>    
      </Form>
    </Container>
  )
}

export default SetParentModal