export interface IPythonProblem {
    id: string;
    seqNo: number;
    title: string;
    description: string;
    def: string;
    dateCreated: Date;
    pythonTestCases: IPythonTestCase[];
    pythonUserAnswers: IPythonUserAnswer[]
}

export interface IPythonProblemValues extends Partial<IPythonProblem>{

}

export class PythonProblemValues implements IPythonProblemValues {
    id?: string = undefined;
    seqNo?: number = undefined;
    title: string = '';
    description: string = '';
    def: string = '';
    dateCreated?:Date = undefined;
    pythonTestCases: IPythonTestCase[] = [];
    pythonUserAnswers: IPythonUserAnswer[] = [];

    constructor(init?: IPythonProblemValues) {
        Object.assign(this, init);
    }

}
export interface IPythonTestCase {
    id: string;
    input: string;
    testCode: string;
    output: string;
    resultType: string;
    pythonProblemId: string
}

export class PythonTestCase implements IPythonTestCase{
    id: string = '';
    input: string = '';
    testCode: string = '';
    output: string = '';
    resultType: string = '';
    pythonProblemId: string = '';
    constructor(init?: IPythonTestCase) {
        Object.assign(this, init);
    }      
}

export interface IPythonUserAnswer {
    firstName: string;
    lastName: string;
    city: string;
    userName: string;
    email: string;
    answer: string
}

export interface IPythonProbleSolutionFormValues {
    id?: string;
    seqNo?: number;
    title: string;
    description: string;
    def: string;
    dateCreated?: Date;
    answer?: string;
    code: string   
}

export class PythonProblemSolutionFormValues implements IPythonProbleSolutionFormValues {
    id?: string = undefined;
    seqNo? : number = undefined;
    title: string = '';
    description: string = '';
    def: string = '';
    dateCreated?: Date = undefined;
    answer?: string = '';
    code: string = ''

    constructor (init? : IPythonProbleSolutionFormValues){
        Object.assign(this, init);
    }
}