import React, {useContext, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import { RootStoreContext } from '../../app/stores/rootStore';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const AddLessonResourceAttach = ({lessonId}) => {
  const rootStore = useContext(RootStoreContext);
  const {addLessonResourceAttach} = rootStore.lessonStore;  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*, application/pdf, .zip', 
    maxFiles: 1,
    onDrop: acceptedFiles => {      
      // attach file
      var file = acceptedFiles[0];
      addLessonResourceAttach(lessonId, file.name, file);
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);  

  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop one file here, or click to select one file</p>
      }
      <p>Only image/pdf/zip file is allowed</p>
    </div>
  )
}

export default AddLessonResourceAttach
