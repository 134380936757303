import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';


const AdminClassesForm = () => {
  const history = useHistory();
  const rootStore = useContext(RootStoreContext);  
  const {subjects, loadSubjects } = rootStore.subjectStore;
  const {courses, loadCourses} = rootStore.coursesStore;
  const [searchSubject, setSearchSubject] = useState('');
  const [searchActive, setSearchActive] = useState(true);

  useEffect(() => {
    loadSubjects();
    loadCourses();
  }, [loadSubjects, loadCourses])
  const handleSubjectSearch = (event: any) => {
    setSearchSubject(event.target.value);
  };
  const handleActiveSearch = (event: any) => {
    setSearchActive(event.target.checked);
  };

  return (
    <Container fluid style={{ marginTop: '2em'}}>
      <Row className="mb-3">
      <Col lg={1} md={1} sm={1} className="search-item">
          <Form.Label>
            Subject
          </Form.Label>
          </Col>
          <Col lg={3} md={3} sm={3}>
          <Form.Control 
            required 
            as="select" 
            name="subject" 
            value={searchSubject}
            onChange={handleSubjectSearch}
            >
            <option></option>
            {subjects.map((subject)=> {
              return (<option key={subject.code}>{subject.name}</option>)
            })}              
          </Form.Control>
        </Col>
        <Col lg={1} md={1} sm={1} className="search-item">
          <Form.Check type="checkbox" label="Active Only" defaultChecked={true}
          name="active" onChange={handleActiveSearch}/>
        </Col>
        <Col lg={{span:1, offset:2}} className="mr-auto search-item">
        <Link to={`/a-classes/newclass`}>
          <Button variant="primary">New Class</Button>
        </Link>
      </Col>          
      </Row>  
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Level</th>
            <th>Sart Date</th>
            <th>End Date</th>
            <th>Scheduled Time</th>
            <th>Teacher</th>
            <th># of Students</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {courses && 
          courses.filter(course=>{
            if(searchSubject === ""){
              return true;
            } else {
              return course.subject?.name === searchSubject;
            }
          }).filter(course=>{
            if(searchActive){
              return course.active;
            } else{
              return true;
            }      
          }).map(course => (
            <tr key={course.courseCode} className="clickable" onClick={(e: any) => {
              history.push(`/a-classes/info/${course.courseCode}`); }
            }>
              <td>{course.courseCode}</td>
              <td>{course.courseName}</td>
              <td>{course.subject!.level}</td>
              <td>{new Date(course.startDate).toISOString().substring(0, 10)}</td>
              <td>{new Date(course.endDate).toISOString().substring(0, 10)}</td>
              <td>{course.dayOfTheWeek + ' ' + course.timePeriod}</td>
              <td>{course.teacher!.firstName + ' ' + course.teacher!.lastName}</td>
              <td>{course.students.length}</td> 
              <td>{course.active ? 'Yes' : 'No'}</td>                                                          
            </tr>
          ))
          }
        </tbody>
      </Table>       
    </Container>      
  )
}

export default observer(AdminClassesForm)
