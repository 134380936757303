export const Director =
[
    {
    staffId: 101,
    staffName: 'June Wang',
    staffTitle: 'Director, Course Coordinator',
    image: '/images/home/JuneWang.png',
    description: 
      `June has always dreamed of opening an eductional center for youth. In 2018, she received the opportunity to 
      be trained in computer programming. This experience ultimately inspired her to create her own 
      training center. With excellent communication skills and years of management experience, June guarentees 
      parents and their children an enriching stay at ABAITC.`

    },
 
];

export const InstructorsLeft = 
[
    {
        staffId: 102,
        staffName: 'Frank Bao',
        staffTitle: 'Senior Instructor',
        image: '/images/home/FrankBao.png',
        description: 
        `Frank has been working as senior software engineer for years and is still enjoying
        the IT industry. Graduating from Peking University and moving on towards Cornell University
        as a graduate, Frank has a solid background in both Mathematics and Computer Science.`
    },
        
    {
        staffId: 103,
        staffName: 'Jimmy Lei',
        staffTitle: 'Senior Instructor',
        image: '/images/home/JimmyLei.png',
        description: 
        `Jimmy graduated from the University of Alberta with both bachelor's and master's degrees in Computing Science.
        Currently, he is a senior software developer in a robotic company. 
        He has an abundance of expericence in paractical and professional programming.
        In his spare time, Jimmy is enthusiastic about robotics, AI and electronics (Arduino).`
    },
    {
        staffId: 104,
        staffName: 'Tyler Shen',
        staffTitle: 'Senior Instructor',
        image: '/images/home/TylerShen.png',
        description: 
        `Tyler is an expert of many programming languages, including but not limited to; Python, C/C++, C# 
        Java, SQL, HTML and much more. After graduating from the University of Alberta, his career in the IT 
        has sparked a passion in teaching coding to youths.`

        },           
    {
        staffId: 105,
        staffName: 'Jeff Wang',
        staffTitle: 'Math Instructor',
        image: '/images/home/JeffWang.png',
        description: 
        `Jeff has an incredibly rich background in the world of mathematics. Spending much of his youth competing
        in math competitions, Jeff took first place at the Chinese national mathematics competition
        in his second year of high school. Jeff graduated from Peking University. No mathematical problem is unsolvable to him.`   
    },

    {
        staffId: 106,
        staffName: 'Frank Zhao',
        staffTitle: 'Instructor',
        image: '/images/home/FrankZhao.png',
        description: 
        `Frank is currently a graduate student at the University of Alberta, continuing his passion and
        interest in coding. With a solid understanding of C++ and Python, Frank is eager to share his
        knowledge and experience of the programming world.`        
        },
        {
        staffId: 107,
        staffName: 'Christine Bao',
        staffTitle: 'Instructor',
        image: '/images/home/ChristineBao.png',
        description: 
        `Christine is currently an undergraduate studying Computer Science at the University of Alberta.
        With a resume filled with leadership and tutoring experiences, she is well-equipped to
        instruct. She also has an interest in computer graphics and interface design.`
        },
        {
          staffId: 108,
          staffName: 'Mingjie Han',
          staffTitle: 'Instructor',
          image: '/images/home/MingjieHan.png',
          description: 
          `Mingjie graduated from the University of Alberta with both bachelor's and master's degrees.
          He is proficient in Python, C++, Robot Operating System (ROS), etc. His interests and experience 
          include computer vision and neural network powered robotics. `
        },
        {
          staffId: 109,
          staffName: 'Kathy Deng',
          staffTitle: 'Instructor',
          image: '/images/home/KathyDeng.png',
          description: 
          `Dr. Deng was a former university teacher in China. She graduated with a master's degree from 
          Hunan University and a Ph. D degree from University of Alberta. Dr. Deng has many years of teaching
          experience and very strong math background. Dr. Deng won multiple math competition awards when 
          she was in high school.
          `
        }                   
]; 
                   
