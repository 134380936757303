import React, {useContext, useEffect} from 'react'
import { Container, Form, Col, Row} from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';

const StudentSubmissionsModal = ({courseTopicId, topicItemId}) => {
  const rootStore = useContext(RootStoreContext);  
  const {studentSubmissions, loadStudentSubmissions} = rootStore.courseTopicStore;

  useEffect(() => {
    const loadValues = async() => {
      await loadStudentSubmissions(courseTopicId, topicItemId);
    }
    loadValues();
  }, [loadStudentSubmissions, courseTopicId, topicItemId]);

  return (
    <Container>
      {studentSubmissions?.map((submission, index) => 
      <div key={index}>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              <h5>Student Name:</h5>
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Form.Control
              type="text"
              name="studentname"
              value = {submission.studentName}
              readOnly
            />
          </Col>
        </Row>
        {submission.studentRemark && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Student's Remark:
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <div className="student-remark">
            <p className="mt-2 ml-2">{submission.remark}</p>
          </div>
          </Col>
        </Row>
        )}
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Code
            </Form.Label>
            </Col>
            <Col lg={10} md={10} sm={10}>
            <Editor value={submission.code}
              onValueChange = {() => {}}
              highlight={code => highlight(code, languages.js)}
              padding={5}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                backgroundColor: 'aliceblue'
              }}
            />
          </Col>
        </Row>        
      </div>
        )}

    </Container>
  )
}

export default observer(StudentSubmissionsModal)