import React, { useState, useContext } from 'react'
import { Form, Button, Image, Row, Col, Spinner } from 'react-bootstrap'
import { RootStoreContext } from '../../app/stores/rootStore';
import { ErrorMessage } from '../../app/common/form/ErrorMessage';

const initialValues = {
  phone: '',
  email: '',
  topic: '',
  description: ''
};

const verificationError = {
  emptyPhoneOrEmail: false,
  emptyDescription: false
};

const intialSubmitErrorInfo = {
  errors: [],
  text: ''
};

export const Contact = () => {
    const rootStore = useContext(RootStoreContext);
    const {addInquiry} = rootStore.inquiryStore;
    const { openModal} = rootStore.modalStore;
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(intialSubmitErrorInfo); 
    const [values, setValues] = useState(initialValues);
    const [valueErrors, setValueErrors] = useState(verificationError);
    const handleChange = (event: any) => {
      const { name, value } = event.target;
      setValues({ ...values, [name]: value });
    }
    const handleSubmit = (event: any) => {
      event.preventDefault();
      event.stopPropagation();    
      setSubmitError(intialSubmitErrorInfo);
      const emptyPhoneOrEmail = values.email.length < 5 ||
        values.phone.length < 10;
      const emptyDescription = values.description.length < 10;
      setValueErrors({emptyPhoneOrEmail, emptyDescription});
      if(emptyPhoneOrEmail || emptyDescription){        
      }
      else{
        setSubmitting(true);
        addInquiry(values.phone, values.email, values.topic, values.description)
        .catch(error => {
          if(error.data && error.statusText){
            const submitErrorInfo1 = {
              errors: error.data.errors, 
              text: error.statusText
            };
            setSubmitError(submitErrorInfo1);     
          }
          else {
            const submitErrorInfo2 = {
              errors: [], 
              text: "Network Error"
            };
            setSubmitError(submitErrorInfo2);               
          }
          setSubmitting(false);
      }).finally(() => {
        setSubmitting(false);
        if(!submitError.text){
          openModal("Question sent", "Your question has been sent. We will contact you soon");
          setValues(initialValues);
        }
      });     
    }
  }
  return (
      <div className='generalPage'>

          <div className='contactHeading'>
              <h5> We're always happy to help.</h5>   
              <h1> Contact Us</h1>                            
          </div>
          
          <div className='contactBreak'/>

          <div className='contactBody'>
              <Row>
                  <Col md={5}>                        
                      <div className='contactEnroll'>
                          <h2> Contact Info</h2>
                          <div className='black-line'/>    
                          <br/>                      
                      </div>

                      <h5> Contact Director and Course Coordinator June Wang.</h5>
                      <br/>
                      <div>
                          Let us know which course you and your child are intrested in and 
                          we will walk you through the enrollment process as well as
                          payment detail. 
                          <br/><br/>
                          We also offer course consultations to match your child with the class 
                          best suited to their skill level.                             
                      </div>
                      <br/>
                      <Row>
                        <Col md={6}>
                        <h5><i className="fa fa-phone"></i>&nbsp; 
                        <a href="tel:+1-587-926-9005" target="_self">(587) 926-9005 </a>
                        </h5>  
                        </Col>
                        <Col md={6}>
                        <h5><i className="fa fa-envelope"></i> &nbsp; 
                        <a href="mailto:hanjun_wang@hotmail.com" target="_blank" rel="noopener noreferrer">hanjun_wang@hotmail.com</a> 
                        </h5>  
                        </Col>
                      </Row>
                                   
                      <br></br>                      
                      <h4>
                        WeChat  &nbsp;&nbsp;&nbsp;&nbsp;               
                        <Image src="/assets/wechat_fengyeyingxue.png" alt="fengyeyingxue"></Image> 
                        </h4>
 
                  </Col>

                  <Col md={2}>
                  </Col>

                  <Col md={5}>
                      <div className='contactAsk'>
                          <h2> Questions?</h2>
                          <div className='black-line'/>    
                          <br/>              
                      </div>

                      <h5> Have any questions? We're happy to help.</h5>

                      <br/>

                      <Form>
                          <Form.Group as={Row} controlId="QueryPhone">
                              <Form.Label column sm="3">Phone #</Form.Label>
                              <Col sm="9">                                  
                              <Form.Control type="text" name="phone" value={values.phone} placeholder="Enter phone #" onChange={handleChange} />
                              </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="QueryEmail">
                              <Form.Label column sm="3">Email Address</Form.Label>
                              <Col sm="9">   
                              <Form.Control type="email" name="email" value={values.email} placeholder="Enter email" onChange={handleChange}/>
                              </Col>
                          </Form.Group>

                          <Form.Group controlId="QueryTopic">
                              <Form.Control type="text" name="topic" value={values.topic} placeholder="What topic are you asking about?" onChange={handleChange} />
                          </Form.Group>

                          <Form.Group controlId="QueryTopic">
                              <Form.Control as="textarea" name="description" value={values.description} placeholder="Write your question here." rows={4} onChange={handleChange}/>
                          </Form.Group>

    <ul className="mb-4">
      {valueErrors.emptyPhoneOrEmail && (
        <li className="text-danger">Either Phone or Email is required</li>
      )}
      {valueErrors.emptyDescription && (
        <li className="text-danger">question is required</li>
      )}
    </ul>
    {submitError.text && (
              <ErrorMessage errors={submitError.errors} text={submitError.text} />
          )}
                          <Row className="d-flex justify-content-center pb-3">
          {!submitting && <Button variant="primary" onClick={handleSubmit}>Submit</Button> }
          {submitting && <Button variant="secondary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"/>
              Submitting...</Button>}
          </Row>
                      </Form>
                  </Col>
              </Row>
          </div>

      </div>
  )
}
