import React, {useState, useEffect, useContext} from 'react'
import {Container, Form, Row, Button} from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';

const allRoles = ["Admin", "CourseAdmin", "Teacher", "Assistant", "Student", "Parent"];

const SetRolesModal = ({userName, roles}) => {
  const rootStore = useContext(RootStoreContext);  
  const [roleValues, setRoleValues] = useState(new Map());
  const {setUserRoles} = rootStore.usersStore;
  const [isChanged, setIsChanged] = useState(false);

  useEffect(()=> {
    var rValues = new Map();
    allRoles.forEach(role => {
      rValues.set(role, roles.indexOf(role) >= 0);
    });
    setRoleValues(rValues); 
  }, [roles]);

  const handleRolesChange = (event) => {
    const { name, checked } = event.target; 
    roleValues.set(name, checked);
    setIsChanged(true);
  };

  const handleSubmit = () => {
    var userRoles= [];
    roleValues.forEach((value, key) => {
      if (value) {
        userRoles.push(key);
      }
    }); 
    setUserRoles(userName, userRoles);
  }
  return (
    <Container>
      <Form>
      <div className="form-field">
          <h4>Roles</h4>
          {roleValues.size > 0 &&
            allRoles.map(role => (                  
                <Form.Check  inline type="checkbox" 
                label={role} defaultChecked={roleValues.get(role)} key={role}
                name={role}
                onChange={handleRolesChange}/>
            ))}
      </div>       
      <Row className="d-flex justify-content-center pb-3 mt-5">
        {isChanged && <Button variant="primary" onClick={handleSubmit}>Save</Button>}
      </Row>
      </Form>
    </Container>
  )
}

export default SetRolesModal