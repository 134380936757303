import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { AppUser, UserInfo } from '../../app/models/user';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import ResetPasswordModal from './ResetPasswordModal';
import CoursesTakenModal from './CoursesTakenModal';
import SetParentModal from './SetParentModal';
import CheckChildModal from './CheckChildModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

interface UserParams {
  userName: string;
}

const allRoles = ["Admin", "CourseAdmin", "Teacher", "Assistant", "Student", "Parent"];

const UserForm: React.FC<RouteComponentProps<UserParams>> = ({
  match,
  history
}) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal} = rootStore.modalStore;
  const { loadUser, setUserInfo, removeUser } = rootStore.usersStore;
  const [user, setUser] = useState(new AppUser());
  const [roleValues, setRoleValues] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadValues = async() => {
      setLoading(true);
      var u = await loadUser(match.params.userName);
      setUser(new AppUser(u));
        var rValues = new Map<string, boolean>();
        allRoles.forEach(role => {
          rValues.set(role, u!.roles.indexOf(role) >= 0);
        });
        setRoleValues(rValues);            
      setLoading(false);
    }
    if (match.params.userName) {
      loadValues();
    }
  }, [loadUser, match.params.userName]);

  const handleRolesChange = (event: any) => {
    const { name, checked } = event.target; 
    roleValues.set(name, checked);
  };

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target; 
    if (name === "active") {
      setUser({...user, [name]: checked});  
    }
    else{
      setUser({...user, [name]: value});       
    }
  };

  const handleSubmit = () => {
    var userRoles: string[] = [];
    roleValues.forEach((value: boolean, key: string) => {
      if (value) {
        userRoles.push(key);
      }
    });
    var userInfo : UserInfo = {
      id: user.id,
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      city: user.city,
      age: user.age,
      grade: user.grade,
      phone: user.phone,
      wechat: user.wechat,
      parentEmail: user.parentEmail,
      parentFirstName: user.parentFirstName,
      parentLastName: user.parentLastName,
      introduction: user.introduction,
      active: user.active,
      roles: userRoles
    };

    setUserInfo(userInfo);
  };

  const handleDeleteUser = (userName: any) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete it?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeUser(userName)        
          }  
        },
        {
          label: 'No',
          onClick: ()  => {}
        }
      ]
    }) 
  }

  return (
    <Container className="content">
      <Row className="mt-2 ml-1">
        <h2 className={` mr-auto ${user.active ? "active-title" : "inactive-title"}`}>Profile of {user.firstName + ' ' + user.lastName}</h2>              
        <Button variant="primary" className="mr-2 mt-2"
          onClick={() => openModal(`courses taken by ${user.firstName + ' ' + user.lastName}`, 
          <CoursesTakenModal userName={user.userName}/>, 'modal-panel-1')}
          >Courses Taken
        </Button>
        <Button variant="primary" className="mr-2 mt-2"
          onClick={() => openModal(`courses taken by ${user.firstName + ' ' + user.lastName}'s child`, 
          <CheckChildModal userName={user.userName}/>, 'modal-panel-1')}
          >Check Child
        </Button>        
        <Button variant="secondary" className="mr-2 mt-2"
          onClick={() => openModal(`set parent for ${user.firstName + ' ' + user.lastName}`, 
          <SetParentModal userName={user.userName} 
          parentEmail={user.parentEmail} parentFirstName={user.parentFirstName}
          parentLastName={user.parentLastName} />, 'modal-panel-1')}
          >Set Parent
        </Button>        
        <Button variant="warning" className="mt-2 mr-3"
          onClick={() => openModal(`reset password for ${user.firstName + ' ' + user.lastName}`,
          <ResetPasswordModal userName={user.userName}/>, 'modal-panel-1')}
          >Reset Password
        </Button>
        <Button variant="danger" className="mr-2 mt-2"
          onClick={() => handleDeleteUser(user.userName)}
          >Delete User
        </Button>          
      </Row>

      <Form>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Email
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={user.email} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
            <Form.Check type="checkbox" label="Active" checked={user.active} 
            name="active" onChange={handleChange}/>
          </Col>
        </Row>
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                First Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="firstName"
                value = {user.firstName}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Last Name
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                required
                type="text"
                name="lastName"
                value = {user.lastName}
                onChange={handleChange}
              />
              </Col>
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Age
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="age"
                value = {user.age || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Grade
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="grade"
                value = {user.grade || ''}
                onChange={handleChange}

              />
              </Col>
        </Row>   
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Phone
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="phone"
                value = {user.phone || ''}
                onChange={handleChange}
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Wechat
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="wechat"
                value = {user.wechat || ''}
                onChange={handleChange}
              />
              </Col>
        </Row>              
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            City
          </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            type="text" 
            name="city" 
            value = {user.city || ''}
            onChange={handleChange}
            >
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Parent Email
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="parentEmail"
                value = {user.parentEmail || ''}
                readOnly
              />
              </Col>          
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Parent FirtName
          </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control 
            type="text" 
            name="parentFirstName" 
            value = {user.parentFirstName || ''}
            readOnly
            >
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Parent LastName
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                name="parentLastName"
                value = {user.parentLastName || ''}
                readOnly
              />
              </Col>          
        </Row>        
        <Row>
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Introduction
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={8} className="full-control" name="introduction" value={user.introduction || ''} 
              onChange={handleChange}>                
              </Form.Control>
          </Col>   
          </Row>         
        <div className="form-field">
            <h4>Roles</h4>
            {roleValues.size > 0 &&
              allRoles.map(role => (                  
                  <Form.Check  inline type="checkbox" 
                  label={role} defaultChecked={roleValues.get(role)} key={role}
                  name={role}
                  onChange={handleRolesChange}/>
              ))}
        </div> 
        <Row className="d-flex justify-content-center pb-3 mt-5">
        {!loading && <Button variant="primary" onClick={handleSubmit}>Save</Button> }
            {loading && <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"/>
                Submitting...</Button>}
        </Row>                   
      </Form>
    </Container>
  );
};

export default observer(UserForm);
