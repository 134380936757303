import React, {Fragment, useContext, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Row, Col, Form, Table, Button} from 'react-bootstrap';
import CodeModal from '../../app/common/modals/CodeModal';
import ContentModal from '../../app/common/modals/ContentModal';
import StudentSubmissionsModal from './StudentSubmissionsModal';
import ShowItemInfo from './ShowItemInfo';

interface CourseTopicParams {
  courseTopicId: string;
}

const ShowTeacherCourseTopicItems: React.FC<RouteComponentProps<CourseTopicParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {teacherTopic, loadTeacherCourseTopic} = rootStore.courseTopicStore;
  const { openModal} = rootStore.modalStore;
  const [searchCategory, setSearchCategory] = useState('');
  const [searchSeqNo, setSearchSeqNo] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [searchConcept, setSearchConcept] = useState('');

  const handleCategoryChange = (event: any) => {
    setSearchCategory(event.target.value);
  };

  const handleSeqNoChange = (event: any) => {
    setSearchSeqNo(event.target.value);
  };
  
  const handleTitleChange = (event: any) => {
    setSearchTitle(event.target.value);
  };
  
  const handleConceptChange = (event: any) => {
    setSearchConcept(event.target.value);
  }; 

  useEffect(() => {
    const loadValues = async() => {
      await loadTeacherCourseTopic(match.params.courseTopicId);
    }
    if(match.params.courseTopicId) {
      loadValues();
    }
  }, [loadTeacherCourseTopic, match.params.courseTopicId]);

  const filter_problems = () => {
    var problems = teacherTopic?.topicItems?.slice()
    .filter(topicItem => {
      if(searchCategory === "") return true;
      else if(topicItem.category.toLowerCase().includes(searchCategory.toLowerCase())) return true;
      else return false;
    })
    .filter(topicItem => {
      if(searchSeqNo === "") return true;
      else if(topicItem.seqNo.toLowerCase().includes(searchSeqNo.toLowerCase())) return true;
      else return false;
    })     
    .filter(topicItem => {
      if(searchTitle === "") return true;
      else if(topicItem.title.toLowerCase().includes(searchTitle.toLowerCase())) return true;
      else return false;            
    }) 
    .filter(topicItem => {
      if(searchConcept === "") return true;
      else if(!topicItem.concepts) return false;
      else if(topicItem.concepts.toLowerCase().includes(searchConcept.toLowerCase())) return true;
      else return false;            
    });
    return problems ? problems : [];
  };

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className='mr-auto active-title'>{teacherTopic?.courseName} - {teacherTopic?.name}</h2>        
        <Button className="mr-3 mt-1 mb-3"
          onClick={() => history.goBack()}
        >Back to Class</Button>    
        <Link to={`/discussions/${match.params.courseTopicId}`}>
          <Button variant="warning" className="mr-3 mt-1 mb-3">Latest Discussions</Button>
        </Link>             
      </div>      
      <Row className="mb-3">
        <Col md="auto" className="search-item">
        <Form.Label >
            Category
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchCategory} placeholder="Search..." onChange={handleCategoryChange}>
          </Form.Control>
        </Col>
        <Col md="auto" className="search-item">
        <Form.Label >
            SeqNo          
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchSeqNo} placeholder="Search..." onChange={handleSeqNoChange}>
          </Form.Control>
        </Col>
        <Col md="auto" className="search-item">
        <Form.Label >
            Title
          </Form.Label>
        </Col>
        <Col md="2">
          <Form.Control type="text" value={searchTitle} placeholder="Search..." onChange={handleTitleChange}>
          </Form.Control>
        </Col> 
        {teacherTopic?.hasAnalysis && 
        <React.Fragment>     
          <Col md="auto" className="search-item">
          <Form.Label >
              Concept
            </Form.Label>
          </Col>
          <Col md="2">
            <Form.Control type="text" value={searchConcept} placeholder="Search..." onChange={handleConceptChange}>
            </Form.Control>
          </Col>          
        </React.Fragment>           
        }                       
      </Row>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>Category</th>
            <th>SeqNo</th>
            <th>Title</th>
            {teacherTopic?.hasConcepts &&
              (<th>Concepts</th>)
            }
            {teacherTopic?.hasAnalysis &&
              (<th>Analysis</th>)
            }
            {teacherTopic?.hasCode &&
              (<th>Code</th>)
            }
            <th>Discuss</th>
            <th>Submissions</th>                                 
          </tr>
        </thead>
        <tbody>
          {          
          filter_problems()
          .sort((a, b) => {
            if (a.category > b.category) return 1;
            else if(a.category < b.category) return -1;
            else if(a.seqNo > b.seqNo) return 1;
            else return -1;
          }).map(topicItem => 
            <tr key={topicItem.id}>
              <td>{topicItem.category}              
              </td>
              <td>
                {!topicItem.seqNoLink && (
                  <Fragment>{topicItem.seqNo} 
            <i className="fa fa-info-circle ml-3 text-info clickable" 
            onClick={() => openModal(`${teacherTopic?.name} ${topicItem.category} ${topicItem.seqNo}: ${topicItem.title}`, <ShowItemInfo 
            courseTopicId={match.params.courseTopicId} topicItemId={topicItem.id} />, 'modal-pane-1')}>          
          </i>   </Fragment>                 
                )}
                {topicItem.seqNoLink && (
                <a href={topicItem.seqNoLink} target="_blank" rel="noopener noreferrer">{topicItem.seqNo}</a>
                )} 
              </td>
              <td>
              {topicItem.url && (
                <a href={topicItem.url} target="_blank" rel="noopener noreferrer">{topicItem.title}</a>
              )}              
              </td>
              {teacherTopic?.hasConcepts &&
                (<td> {topicItem.concepts}                
                </td>)
              }
              {teacherTopic?.hasAnalysis &&
                (<td> 
                {topicItem.analysisUrl && (
                <a href={topicItem.analysisUrl} target="_blank" rel="noopener noreferrer">Analysis</a>
                )}   
                {topicItem.analysisContent && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`Analysis of ${topicItem.title}`, <ContentModal content={topicItem.analysisContent}/>, 'modal-pane-1')}>
                    Analysis
                  </span>                
                )}                             
                </td>)
              }
              {teacherTopic?.hasCode &&
                (<td>
                {topicItem.code  && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`Code for ${topicItem.title}`, 
                    <CodeModal code={topicItem.code} />, 'modal-pane-1')}>
                      Code
                    </span>           
                )}                 
                </td>)
              }
              <td>
                <Link to={`/coursetopics/t-discuss/${match.params.courseTopicId}/${topicItem.id}/${topicItem.title}`}>
                  <span className="clickable text-primary"> Discuss</span>
                </Link>
            </td> 
            <td>
                {topicItem.numOfSubmissions > 0 && (
                  <span className="clickable text-primary"
                  onClick={() => openModal(`student submissions of ${topicItem.title}`, <StudentSubmissionsModal courseTopicId={teacherTopic?.id} topicItemId={topicItem.id}/>, 'modal-pane-1')}>
                    {topicItem.numOfSubmissions} submissions
                  </span>
                  )}               
              </td>                                        
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{display: 'flex'}}>   
      <h3 className='mr-auto text-info'>Total problems: {filter_problems().length}</h3>         
      </div>      
    </div>
  )
}

export default observer(ShowTeacherCourseTopicItems)
