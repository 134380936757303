import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import PythonProblemList from './PythonProblemList';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';

const PythonProblemDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadPythonProblems, loading } = rootStore.pythonProblemStore;

  useEffect(() => {
    loadPythonProblems();
  }, [loadPythonProblems]);
  
  if(loading) return <LoadingComponent content='loading python problems...' />
  return (
    <Container style={{ marginTop: '1rem' }} >
      <Row>
        <Col lg={9}>
          <PythonProblemList />
        </Col>
        <Col lg={3}>
          {/* <h2>Python Problem Filters</h2> */}
        </Col>
      </Row>

    </Container>
  );
};

export default observer(PythonProblemDashboard);