import axios, { AxiosResponse } from 'axios';
import { IPythonProblem, IPythonTestCase } from '../models/pythonProblem';
import { IUserFormValues, IUser, IAppUser, UserInfo, UserProfile } from '../models/user';
import { history } from '../..';
import { toast } from 'react-toastify';
import { ICourseLesson } from '../models/courseLesson';
import { IResource } from '../models/resource';
import { IRole } from '../models/role';
import { ICourse, INewCourse, ICourseInfo, ICourseContent, IParentCourseInfo } from '../models/course';
import { ISubject } from '../models/subject';
import { IStudent } from '../models/student';
import {ITeacher} from '../models/teacher';
import {IAttendance} from '../models/attendance';
import {IAssignment, IAssignmentContent, IStudentAssignment, IStudentAssignmentContent} from '../models/assignment';
import { INumberPuzzle, IUserRank } from '../models/numberPuzzle';
import { IInquiry } from '../models/inqury';
import { ILessonContent} from '../models/lesson';
import { IStudentLesson} from '../models/studentLesson';
import { IAnswerInfo, IQAInfo, IQuestionInfo, ICourseQuestionInfo } from '../models/qa';
import {ITopic, ITopicItem} from '../models/topic';
import { ICourseTopic, ICourseTopicItem } from '../models/courseTopic';
import {ITeacherCourseTopic } from '../models/TeacherCourseTopic';
import { IStudentCourseTopic } from '../models/studentCourseTopic';
import { IDiscussionInfo } from '../models/discussion';
import { IMessageInfo } from '../models/message';
import {ISubmission} from '../models/submission';
import {IAchievement} from '../models/achievement';
import { IAnnouncement } from '../models/announcement';
import { IAssistant } from '../models/assistant';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('jwt');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, error => {
  // console.log(error.message);
  // console.log(error.response);
  if (error.message === 'Network Error' && !error.response) {
    toast.error('Network error - make sure API is running!');
    return Promise.reject(error);
  }
  const { status, data, config, headers } = error.response;
  if (status === 404) {
    history.push('/notfound');
  }
  if (
    status === 401 &&
    String(headers['www-authenticate']).includes('The token expired')
  ) {
    window.localStorage.removeItem('jwt');
    history.push('/');
    toast.info('Your session has expired, please log in again');
  }
  if (
    status === 400 &&
    config.method === 'get' &&
    data.errors.hasOwnProperty('id')
  ) {
    history.push('/notfound');
  }
  if (status === 500) {
    toast.error('Server error - check the terminal for more info!');
  }
  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append('File', file);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  }
};

const PythonProblems = {
  list: (): Promise<IPythonProblem[]> => requests.get('/pythonproblems'),
  info: (id: string) => requests.get(`/pythonproblems/${id}`),
  create: (pythonProblem: IPythonProblem) =>
    requests.post('/pythonproblems', pythonProblem),
  update: (pythonProblem: IPythonProblem) =>
    requests.put(`/pythonproblems/${pythonProblem.id}`, pythonProblem),
  delete: (id: string) => requests.del(`/pythonproblems/${id}`),
  solve: (id: string, code: string) =>
    requests.put('/pythonproblems', { id, code })
};

const PythonTestCases = {
  create: (pythonTestCase: IPythonTestCase) => 
    requests.post('/pythontestcases', pythonTestCase),
  update: (pythonTestCase: IPythonTestCase) => 
    requests.put(`/pythontestcases/${pythonTestCase.id}`, pythonTestCase),
    delete: (id: string) => requests.del(`/pythontestcases/${id}`)
}

const User = {
  current: (): Promise<IUser> => requests.get('/user'),
  login: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/login`, user),
  register: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/register`, user),
  changePassword: (oldPassword: string, newPassword: string): Promise<IUser> => 
    requests.post(`/user/changepassword`, {oldPassword, newPassword}),
  setProfile: (profile: UserProfile) => 
    requests.put(`/user/setprofile`, profile),
  fbLogin: (accessToken: string): Promise<IUser> => 
    requests.post(`/user/facebook`, {accessToken}),
  gLogin: (accessToken: string) : Promise<IUser> => 
    requests.post(`/user/google`, {accessToken}),
  studentCourses: (): Promise<ICourseInfo[]> => requests.get('/user/studentcourses'),  
  teacherCourses: (): Promise<ICourseInfo[]> => requests.get('/user/teachercourses'),
  assistantCourses: (): Promise<ICourseInfo[]> => requests.get('/user/assistantcourses'),  
  parentCourses: (): Promise<IParentCourseInfo[]> => requests.get('/user/parentcourses'), 
};

const Users = {
  list: (): Promise<IAppUser[]> => requests.get('/users'),
  info: (userName: string): Promise<IAppUser> => requests.get(`/users/${userName}`),
  setUserInfo: (userInfo: UserInfo) =>
    requests.put(`/users/setinfo/${userInfo.userName}`, userInfo),
  setParentInfo: (userName: string, parentEmail: string, parentFirstName: string, parentLastName: string) =>
    requests.put('/users/setparent', {userName, parentEmail, parentFirstName, parentLastName}),
  setRoles: (userName: string, roles: string[]) =>
    requests.put('/users/setroles', {userName, roles}),
  removeUser: (userName: string) => 
    requests.del(`/users/removeuser/${userName}`),
  setUserActive: (userName: string, active: boolean) =>
    requests.put('/users/setactive', {userName, active}),
  changeUserEmail: (userName: string, email: string) => 
    requests.put('/users/changeemail', {userName, email}),
  resetPassword: (userName: string, password: string) => 
    requests.put('/users/resetpassword', {userName, password}),
  getStudentCourses: (userName: string): Promise<ICourseInfo[]> => 
  requests.get(`/users/studentcourses/${userName}`),   
  childCourses: (userName: string): Promise<IParentCourseInfo[]> => 
    requests.get(`/users/childcourses/${userName}`)   
}

const Roles = {
  list: (): Promise<IRole[]> => requests.get('/roles')
}

const Courses = {
  list: (): Promise<ICourseInfo[]> => requests.get('/admin/courses'),
  activelist: (): Promise<ICourseInfo[]> => requests.get('/courses/activelist'),
  info:(courseCode: string): Promise<ICourseInfo> => requests.get(`courses/${courseCode}`),
  createNewCourse: (course: INewCourse) => requests.post('/courses', course),
  editCourse: (course: ICourse) => requests.put('/courses/edit', course),
  toggleActive:(courseCode: string, active: boolean) => requests.put('/courses/toggleactive', {courseCode, active}),
  addCourseAssistant:(courseCode: string, userName: string) => requests.post('/courses/addassistant', {courseCode, userName}),
  removeCourseAssistant:(courseCode: string, userName: string) => requests.post('/courses/removeassistant', {courseCode, userName}),
  addCourseStudent:(courseCode: string, userName: string) => requests.post('/courses/addstudent', {courseCode, userName}),
  removeCourseStudent:(courseCode: string, userName: string) => requests.post('/courses/removestudent', {courseCode, userName})
}

const Course = {
  studentcontent:(courseCode: string): Promise<ICourseContent> => requests.get(`/course/studentcontent/${courseCode}`),  
  content:(courseCode: string): Promise<ICourseContent> => requests.get(`/course/${courseCode}`),
  setDayOfTheWeek: (courseCode: string, dayOfTheWeek: string) => 
    requests.put('/course/setdayoftheweek', {courseCode, dayOfTheWeek}),
  setTimePeriod: (courseCode: string, timePeriod: string) => 
    requests.put('/course/settimeperiod', {courseCode, timePeriod}),
  setNotice: (courseCode: string, notice: string) => 
    requests.put('/course/setnotice', {courseCode, notice}),
  addResource: (courseCode: string, title: string, url: string, content: string): Promise<IResource> => 
    requests.post('/course/addresource', {courseCode, title, url, content}),
  addResourceAttach: (courseCode: string, title: string, file: Blob) => {
    let formData = new FormData();
    formData.append('CourseCode', courseCode);
    formData.append('Title', title);
    formData.append('File', file);
    return axios.post<IResource>('/course/addresourceattach', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })
  },
  removeResource: (courseCode: string, resourceId: string) => {
    requests.post('/course/removeresource', {courseCode, resourceId})
  },
  addLesson: (courseCode: string, topic: string, date: Date, timePeriod: string): Promise<ICourseLesson> =>
    requests.post('/course/addLesson', {courseCode, topic, date, timePeriod}),
  setResourceSeqNo: (resourceId: string, seqNo: number) => {
    requests.put('/course/setresourceseqno', {resourceId, seqNo})
  },
  studentReport:(courseCode: string, userName: string): Promise<IStudentLesson[]> =>
    requests.get(`/course/studentReport/${courseCode}/${userName}`)
}

const Lesson = {
  getAttendances:(lessonId: string): Promise<IAttendance[]> => 
    requests.get(`/lesson/attendances/${lessonId}`),
  setAttendance: (lessonId: string, userName: string, attendingCode: number) => 
    requests.put('/lesson/setattendance', {lessonId, userName, attendingCode}),
  getLesson:(lessonId: string): Promise<ICourseLesson> => 
    requests.get(`/lesson/${lessonId}`),
  setVideoUrl:(lessonId: string, url: string) =>
    requests.put('/lesson/seturl', {lessonId, url}),
  setLesson: (lessonId: string, seqNo: string, topic: string, date: string, timePeriod: string) => 
    requests.put('/lesson/setlesson', {lessonId, seqNo, topic, date, timePeriod}),
  lessonStudentContent:(lessonId: string): Promise<ILessonContent> => 
    requests.get(`lesson/studentcontent/${lessonId}`),     
  lessonContent:(lessonId: string): Promise<ILessonContent> => requests.get(`lesson/content/${lessonId}`), 
  addResource: (lessonId: string, title: string, url: string, content: string): Promise<IResource> => 
    requests.post('/lesson/addresource', {lessonId, title, url, content}),  
  addResourceAttach: (lessonId: string, filename: string, blob: Blob) => {
    let formData = new FormData();
    formData.append('LessonId', lessonId);
    formData.append('Title', filename);
    formData.append('File', blob);
    return axios.post<IResource>('/lesson/addresourceattachment', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })
  },
  removeResource: (lessonId: string, resourceId: string) => {
    requests.post('/lesson/removeresource', {lessonId, resourceId})
  },   
  setResourceSeqNo: (resourceId: string, seqNo: number) => {
    requests.put('/lesson/setresourceseqno', {resourceId, seqNo})
  }    
}

const Subjects = {
  list: (): Promise<ISubject[]> => requests.get('/subjects'),
  createNewSubject: (subject: ISubject) => requests.post('/subjects', subject),
}

const Teachers = {
  list: (): Promise<ITeacher[]> => requests.get('/teachers') 
}

const Assistants = {
  list: (): Promise<IAssistant[]> => requests.get('/assistants')
}

const Students = {
  list: (): Promise<IStudent[]> => requests.get('/students')
}

const Assignments = {
  assignmentStudentContent:(lessonId: string): Promise<IAssignmentContent> => 
  requests.get(`assignments/studentcontent/${lessonId}`),  
  assignmentContent:(lessonId: string): Promise<IAssignmentContent> => 
  requests.get(`assignments/content/${lessonId}`),
  addProblem: (lessonId: string, title: string, url: string, description: string, seqNo: string, deadline: string,
    submitMode: string) => 
    requests.post('/lesson/addproblem', {lessonId, title, url, description, seqNo, deadline, submitMode}), 
  addPickProblem: (lessonId: string, title: string, courseTopicId: string, topicItemId: string, 
    seqNo: string, deadline: string, submitMode: string): Promise<IAssignment> => 
    requests.post('/lesson/addpickproblem', {lessonId, title, courseTopicId, topicItemId, seqNo, deadline, submitMode}),     
  addProblemAttach: (lessonId: string, title: string, seqNo: string, deadline: string, submitMode: string, file: Blob) => {
    let formData = new FormData();
    formData.append('LessonId', lessonId);
    formData.append('Title', title);
    formData.append('SeqNo', seqNo);
    formData.append('Deadline', deadline);
    formData.append('SubmitMode', submitMode);
    formData.append('File', file);
    return axios.post<IAssignment>('/lesson/addproblemattach', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })    
  },  
  setSeqNo: (assignmentId: string, seqNo: number) => {
    requests.put('/assignments/setseqno', {assignmentId, seqNo})
  },  
  setDeadline: (assignmentId: string, deadline: string) => {
    requests.put('/assignments/setdeadline', {assignmentId, deadline})
  },
  setAnalysisUrl: (assignmentId: string, analysisUrl: string) => {
    requests.put("/assignments/setanalysisurl", {assignmentId, analysisUrl})
  },
  updateProblem: (problemId: string, title: string, url: string, description: string, seqNo: string, 
    deadline: string, submitMode: string) => 
    requests.put('/lesson/updateproblem', {problemId, title, url, description, seqNo, deadline, submitMode}), 
  updatePickProblem: (problemId: string, title: string, courseTopicId: string, topicItemId: string, 
    seqNo: string, deadline: string, submitMode: string) => 
    requests.put('/lesson/updatepickproblem', {problemId, title, courseTopicId, topicItemId, seqNo, deadline, submitMode}),     
  removeProblem:(problemId: string) => 
    requests.del(`/lesson/removeproblem/${problemId}`),   
  submitCode:(problemId: string, remark: string, code: string) =>
    requests.post('/assignments/submitcode', {problemId, remark, code}),
  submitAttach: (problemId: string, remark: string, file: Blob) => {
    let formData = new FormData();
    formData.append("ProblemId", problemId);
    formData.append("Remark", remark);
    formData.append("File", file);
    return axios.post<IStudentAssignment>('/assignments/submitattach', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })    
  },
  studentAssignmentContent:(lessonId: string): Promise<IStudentAssignmentContent> => 
    requests.get(`/assignments/studentcontent/${lessonId}`),    
  getStudentSubmissions: (problemId: string): Promise<IStudentAssignment[]> =>
    requests.get(`/assignments/submissions/${problemId}`),
  getProblem:(problemId: string): Promise<IAssignment> => 
    requests.get(`/assignments/problem/${problemId}`),
  setTeacherGrade:(problemId: string, userName: string, grade: string, teacherRemark: string) =>
    requests.put('/assignments/problem/teachergrade', {problemId, userName, grade, teacherRemark}),
  addResource: (lessonId: string, title: string, url: string, content: string): Promise<IResource> => 
    requests.post('/assignments/addresource', {lessonId, title, url, content}),  
  addResourceAttach: (lessonId: string, filename: string, blob: Blob) => {
    let formData = new FormData();
    formData.append('LessonId', lessonId);
    formData.append('Title', filename);
    formData.append('File', blob);
    return axios.post<IResource>('/assignments/addresourceattachment', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })
  },
  setResourceSeqNo: (resourceId: string, seqNo: number) => {
    requests.put('/assignments/setresourceseqno', {resourceId, seqNo})
  },
  removeResource: (lessonId: string, resourceId: string) => {
    requests.post('/assignments/removeresource', {lessonId, resourceId})
  }, 
  getDiscussions: (assignmentId: string) 
    :Promise<IMessageInfo[]> => 
    requests.get(`/assignments/getdiscussions/${assignmentId}`),
  addDiscussion: (assignmentId: string, message: string) =>
    requests.post('/assignments/adddiscussion', {assignmentId, message}),      
}

const Inquiries = {
  addInquiry:(phone: string, email: string, topic: string, description: string) =>
    requests.post('/inquiries/add', {phone, email, topic, description}),
  getInquiries:(): Promise<IInquiry[]> => requests.get('/inquiries'),
  setInquiryChecked: (id: string) => requests.put('/inquiries/check', {id})  
}

const NumberPuzzles = {
  info: (): Promise<INumberPuzzle> => requests.get('/numberpuzzle'),
  ranks: (): Promise<IUserRank[]> => requests.get('/numberpuzzle/ranks'),
  solve: (id: string, expression: string) => 
    requests.put('/numberpuzzle', {id, expression})
};

const QA = {
  teacherInfo: (courseCode: string): Promise<IQAInfo> => requests.get(`/qa/teacherinfo/${courseCode}`),
  studentInfo: (courseCode: string): Promise<IQAInfo> => requests.get(`/qa/studentinfo/${courseCode}`),  
  questionInfo: (questionId: string): Promise<IQuestionInfo> => requests.get(`/qa/questioninfo/${questionId}`),
  addQuestion: (courseCode: string, topic: string, isPrivate: boolean, message: string): Promise<IQuestionInfo> =>
    requests.post('/qa/addquestion', {courseCode, topic, isPrivate, message}),
  addAnswer: (questionId: string, message: string): Promise<IAnswerInfo> =>
    requests.post('/qa/addanswer', {questionId, message}),
  updateAnswer: (answerId: string, message: string) =>
    requests.post('/qa/updateanswer', {answerId, message}),
  getQuestions: (): Promise<ICourseQuestionInfo[]> => 
    requests.get('/qa/questions'),
  getTAQuestions: (): Promise<ICourseQuestionInfo[]> => 
    requests.get('/qa/taquestions')    
};

const Topic = {
  list: (): Promise<ITopic[]> => requests.get('/topics'),
  assistantlist: (): Promise<ITopic[]> => requests.get('/topics/alist'),  
  addTopic: (name: string, subjectCode: string, 
    hasConcepts: boolean, hasAnalysis: boolean, hasCode: boolean, 
    isSeparatePage: boolean, isWorkbook: boolean, submitMode: string)
  : Promise<ITopic> => 
    requests.post('topics/addtopic', {name, subjectCode, hasConcepts, hasAnalysis, hasCode, 
      isSeparatePage, isWorkbook, submitMode}),
  setTopic: (topicId: string, name: string, hasConcepts: boolean, hasAnalysis: boolean, 
    hasCode: boolean, isSeparatePage: boolean, isWorkbook: boolean, submitMode: string) => 
    requests.put('/topics/settopic', {topicId, name, hasConcepts, hasAnalysis, hasCode, 
      isSeparatePage, isWorkbook, submitMode}),
  getTopic: (topicId: string) : Promise<ITopic> => 
    requests.get(`/topics/info/${topicId}`),
  getTopicItems: (topicId: string) : Promise<ITopicItem[]> => 
    requests.get(`/topics/items/${topicId}`),
  addTopicItem: (topicId: string, category: string, seqNo: string, seqNoLink: string, title: string, content: string, url: string) => 
    requests.post('/topics/addtopicitem', {topicId, category, seqNo, seqNoLink, title, content, url}),
  setTopicItemCategory: (topicId: string, topicItemId: string, category: string) => 
    requests.put('/topics/item/setcategory', {topicId, topicItemId, category}),
  setTopicItemSeqNo: (topicId: string, topicItemId: string, seqNo: string, seqNoLink: string) => 
    requests.put('/topics/item/setseqno', {topicId, topicItemId, seqNo, seqNoLink}),
  setTopicItemConcepts: (topicId: string, topicItemId: string, concepts: string) => 
    requests.put('/topics/item/setconcepts', {topicId, topicItemId, concepts}),
  setTopicItem: (topicId: string, topicItemId: string, title: string, url: string) => 
    requests.put('/topics/item/setitem', {topicId, topicItemId, title, url}),
  submitCode:(topicItemId: string, code: string) =>
    requests.post('/topics/item/submitcode', {topicItemId, code}),   
  attachAnalysis: (topicItemId: string, file: Blob) => {
    let formData = new FormData();
    formData.append("TopicItemId", topicItemId);
    formData.append("File", file);
    return axios.post<ITopicItem>('/topics/item/attachanalysis', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })   
  },
  setTopicItemAnalysisContent: (topicId: string, topicItemId: string, content: string) =>
    requests.put('topics/item/setanalysiscontent', {topicId, topicItemId, content}),
  setTopicItemAnalysisLink: (topicId: string, topicItemId: string, analysisUrl: string) =>
    requests.put('topics/item/setanalysislink', {topicId, topicItemId, analysisUrl})
};

const CourseTopic = {
  getCourseTopics:(courseCode: string): Promise<ICourseTopic[]> => 
    requests.get(`/coursetopics/${courseCode}`),
  setCourseTopic: (courseCode: string, topicId: string, isAdded: boolean) => 
    requests.put('/coursetopics/setcoursetopic', {courseCode, topicId, isAdded}),
  getCourseTopicItems: (courseTopicId: string) : Promise<ICourseTopicItem[]> => 
    requests.get(`/coursetopics/items/${courseTopicId}`),
  addCourseTopicItem: (courseTopicId: string, topicItemId: string) => 
    requests.post('/coursetopics/additem', {courseTopicId, topicItemId}),
  removeCourseTopicItem: (courseTopicId: string, topicItemId: string) => 
    requests.post('coursetopics/removeitem', {courseTopicId, topicItemId}),
  getCourseTopic: (courseTopicId: string) : Promise<ICourseTopic> => 
    requests.get(`/coursetopics/topic/${courseTopicId}`),
  getTeacherCourseTopic: (courseTopicId: string) : Promise<ITeacherCourseTopic> => 
    requests.get(`/coursetopics/teachertopic/${courseTopicId}`),    
  getStudentCourseTopic: (courseTopicId: string) : Promise<IStudentCourseTopic> => 
  requests.get(`/coursetopics/studenttopic/${courseTopicId}`),
  toggleConcepts: (courseTopicId: string, topicItemId: string, showConcepts: boolean) => 
    requests.put('coursetopics/toggleconcepts', {courseTopicId, topicItemId, showConcepts}),
  toggleAnalysis: (courseTopicId: string, topicItemId: string, showAnalysis: boolean) => 
    requests.put('coursetopics/toggleanalysis', {courseTopicId, topicItemId, showAnalysis}),
  toggleCode: (courseTopicId: string, topicItemId: string, showCode: boolean) => 
    requests.put('coursetopics/togglecode', {courseTopicId, topicItemId, showCode}),
  getDiscussions: (courseTopicId: string, topicItemId: string) 
    :Promise<IMessageInfo[]> => 
    requests.get(`/coursetopics/getdiscussions/${courseTopicId}/${topicItemId}`),
  addDiscussion: (courseTopicId: string, topicItemId: string, message: string) =>
    requests.post('/coursetopics/adddiscussion', {courseTopicId, topicItemId, message}),
  submitCode:(courseTopicId: string, topicItemId: string, remark: string, code: string) =>
    requests.post('/coursetopics/submitcode', {courseTopicId, topicItemId, remark, code}),
  submitAttach: (courseTopicId: string, topicItemId: string, remark: string, file: Blob) => {
    let formData = new FormData();
    formData.append("CourseTopicId", courseTopicId);
    formData.append("TopicItemId", topicItemId);
    formData.append("Remark", remark);
    formData.append("File", file);
    return axios.post<IStudentAssignment>('/coursetopics/submitattach', formData, {
      headers: {'Content-type': 'multipart/form-data'}
    })
  },
  getSubmissions: (courseTopicId: string, topicItemId: string) : Promise<ISubmission[]> => 
    requests.get(`/coursetopics/getsubmissions/${courseTopicId}/${topicItemId}`),
  updateDiscussion: (courseTopicId: string, topicItemId: string, discussionId: string, message: string) =>
    requests.put('/coursetopics/updatediscussion', {courseTopicId, topicItemId, discussionId, message}),          
};

const Discussion = {
  discussions: (courseTopicId: string): Promise<IDiscussionInfo[]> => 
    requests.get(`/discussions/getdiscussions/${courseTopicId}`),
  courseDiscussions: (courseId: string): Promise<IDiscussionInfo[]> => 
    requests.get(`/discussions/getcoursediscussions/${courseId}`),    
};

const Achievement = {
  getAchievements: (): Promise<IAchievement[]> => requests.get('/achievements/'),
  addAchievement: (title: string, date: string, description: string) =>
    requests.post('/achievements/add', {title, date, description}),
  updateAchievement: (id: string, title: string, date: string, description: string) => 
    requests.put('/achievements/update', {id, title, date, description})
}

const Announcement = {
  getAnnouncements: (): Promise<IAnnouncement[]> => requests.get('/announcements/'),
  getActiveAnnouncements: (): Promise<IAnnouncement[]> => requests.get('/announcements/activelist'),
  addAnnouncement: (title: string, date: string, description: string) =>
    requests.post('/announcements/add', {title, date, description}),
  updateAnnouncement: (id: string, title: string, description: string) => 
    requests.put('/announcements/update', {id, title, description}),
  setActive: (id: string, active: boolean) => 
    requests.put('/announcements/setactive', {id, active})
}

export default {
  PythonProblems,
  PythonTestCases,
  User,
  Users,
  Roles,
  Courses,
  Course,
  Subjects,
  Teachers,
  Assistants,
  Students,
  Lesson,
  Inquiries,
  Assignments,
  NumberPuzzles,
  QA, 
  Topic,
  CourseTopic,
  Discussion,
  Achievement,
  Announcement
};
