import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Card, Button} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser'
import EditAchievementModal from './EditAchievementModal';

const AdminAchievements = () => {
  const rootStore = useContext(RootStoreContext);
  const {achievements, getAchievements} = rootStore.achievementStore; 
  const {openModal} = rootStore.modalStore;
  const { user } = rootStore.userStore;
  useEffect(() => {
    const loadValues = async() => {
      await getAchievements();
    }
    loadValues();
  }, [getAchievements])

  return (
    <Container>
    {user && user.roles.includes('Admin') && (
      <Button variant="info"
      onClick={() => openModal('Add Achievement', 
      <EditAchievementModal achievement={null}/>, 'modal-pane-3')}
      >New Honor Roll
      </Button>       
    )}
      {achievements.slice().sort((a, b) => a.date < b.date ? 1 : -1)
      .map((achievement, idx) => 
        <Card className="mt-3 mb-2" key={idx}>
          <Card.Header>
            <Card.Body>
              <Card.Title className="active-title">
                {achievement.title}            
                {user && user.roles.includes('Admin') && (
                <i className="fa fa-edit ml-3 mt-2 mb-2 text-info clickable" 
                  onClick={() => openModal("Update honor roll (Text)", <EditAchievementModal achievement={achievement}/>, 'modal-pane-3')}>          
                </i>
                )}
              </Card.Title>
              {parse(achievement.description)}
            </Card.Body>
          </Card.Header>
        </Card>      
      )}
    </Container>
  )
}

export default observer(AdminAchievements)