import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { ICourseTopicItem } from '../../app/models/courseTopic';
import {Container, Row, Col, Form, Table, Button, Spinner} from 'react-bootstrap';
import SplitPane from 'react-split-pane';

import "../classes.css";

interface CourseTopicParams {
  courseTopicId: string;
  topicId: string;
}

const initialItems: ICourseTopicItem[] = [];

const SetCourseTopicItems: React.FC<RouteComponentProps<CourseTopicParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);   
  const {loadCourseTopicItems, addCourseTopicItem, removeCourseTopicItem, toggleShowConcepts, toggleShowAnalysis, toggleShowCode} = rootStore.courseTopicStore;
  const {topicItems, loadTopicItems} = rootStore.topicStore;
  const [courseTopicItems, setCourseTopicItems] = useState(initialItems);
  const [searchTerm, setSearchTerm] = useState('')
  const [btmHeight, setBtmHeight] = useState(100);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadValues = async() => {
      var items = await loadCourseTopicItems(match.params.courseTopicId);
      if(items){
        setCourseTopicItems(items);
      }
      await loadTopicItems(match.params.topicId);
    }
    if(match.params.courseTopicId && match.params.topicId){
      loadValues();
    }
  }, [loadCourseTopicItems, loadTopicItems, match.params.courseTopicId, match.params.topicId]);

  const toggleBtmHeight = (topPaneHeight: any) => {
    const maxHeight = 500;
    const padding = 25;
    const btmPaneHeight = maxHeight - topPaneHeight - padding;
    setBtmHeight(btmPaneHeight);
  }
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  }
  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
  const addAll = async(e: any) => {
    setLoading(true); 
    topicItems.forEach(async (topicItem) => {
      if(!courseTopicItems.some(x => x.id === topicItem.id)) {
        await addCourseTopicItem(match.params.courseTopicId, topicItem.id);      
      }
    })
    // temporarily delay for 3 seconds
    await delay(3000);
    var items = await loadCourseTopicItems(match.params.courseTopicId);
    if(items){
      setCourseTopicItems(items);
    }  
    setLoading(false)

  }
  const addItem = async (topicItemId: any, e: any) => {
    addCourseTopicItem(match.params.courseTopicId, topicItemId);
    var topicItem = topicItems.find(x=>x.id === topicItemId);
    if(topicItem) {
      var courseTopicItem = {
        id : topicItem.id,
        category: topicItem.category,
        seqNo : topicItem.seqNo,
        title : topicItem.title,
        showConcepts : true,
        showAnalysis: false,
        showCode : false,
        topicId : match.params.topicId
      }
      setCourseTopicItems([...courseTopicItems, courseTopicItem]);
    }
  }

  const removeItem = (topicItemId: any, e: any) => {
    removeCourseTopicItem(match.params.courseTopicId, topicItemId);
    setCourseTopicItems(courseTopicItems.filter(x=>x.id !== topicItemId));
  }

  const handleShowConcepts = (topicItemId: any) => {
    toggleShowConcepts(match.params.courseTopicId, topicItemId, true);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showConcepts = true;
      setCourseTopicItems(newItems);
    }
  }

  const handleHideConcepts = (topicItemId: any) => {
    toggleShowConcepts(match.params.courseTopicId, topicItemId, false);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showConcepts = false;
      setCourseTopicItems(newItems);
    }
  }

  const handleShowAnalysis = (topicItemId: any) => {
    toggleShowAnalysis(match.params.courseTopicId, topicItemId, true);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showAnalysis = true;
      setCourseTopicItems(newItems);
    }
  }

  const handleHideAnalysis = (topicItemId: any) => {
    toggleShowAnalysis(match.params.courseTopicId, topicItemId, false);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showAnalysis = false;
      setCourseTopicItems(newItems);
    }
  }

  const handleShowCode = (topicItemId: any) => {
    toggleShowCode(match.params.courseTopicId, topicItemId, true);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showCode = true;
      setCourseTopicItems(newItems);
    }
  }

  const handleHideCode = (topicItemId: any) => {
    toggleShowCode(match.params.courseTopicId, topicItemId, false);
    var newItems = [...courseTopicItems];
    var courseTopicItem = newItems.find(x=>x.id === topicItemId);
    if(courseTopicItem) {
      courseTopicItem.showCode = false;
      setCourseTopicItems(newItems);
    }
  }
  return (
    <Container fluid style={{ marginTop: '2em'}} >
      <div className="mb-3">
        <Row>
          <Col lg={2}>
          <Form.Control type="text" value={searchTerm} placeholder="Search..." onChange={handleChange}>
            </Form.Control>
          </Col>
          <Button className="mr-3" 
            onClick={() => history.goBack()}>Back to Class
          </Button>
          {!loading && 
          <Button className="ml-auto mr-3"
            onClick={(e)=>addAll(e)}>Add All          
          </Button>}
          {loading && <Button variant="secondary" className="ml-auto mr-3" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"/>
                    Updating...</Button>}
        </Row>

      </div>

      <SplitPane split="horizontal" defaultSize="50%" className="split-pane" style={{position: "relative"}} onChange={size=>toggleBtmHeight(size)}>
      <Table striped bordered hover className="mb-3 split-pane-1">
        <thead>
          <tr>
            <th style={{width: "20rem"}}>Id</th>
            <th style={{width: "5rem"}}>Category</th>
            <th style={{width: "5rem"}}>SeqNo</th>
            <th style={{width: "20rem"}}>Title</th>
            <th style={{width: "5rem"}}>Add</th>
          </tr>
        </thead>
        <tbody>
          {topicItems?.slice()      
            .filter(topicItem => 
              !courseTopicItems.some(x => x.id === topicItem.id)
            )
            .filter(topicItem => {
              if(searchTerm === ""){
                return true;
              } else if(topicItem.title.toLowerCase().includes(searchTerm.toLowerCase())){
                return true;
              } else{
                return false;
              }
            }) 
            .sort((a, b) => {
              if (a.category > b.category) return 1;
              else if(a.category < b.category) return -1;
              else if(a.seqNo > b.seqNo) return 1;
              else return -1;
            })           
            .map((topicItem, index) => 
            <tr key={topicItem.id}>
            <td>{topicItem.id}</td>
            <td>{topicItem.category}</td>
            <td>{topicItem.seqNo}</td>
            <td>{topicItem.title}</td>
            <td><i className="fa fa-plus-circle fa-2x mr-3 text-info clickable" onClick={(e) => addItem(topicItem.id, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
      <Table striped bordered hover className="mb-3 split-pane-2" style={{height: {btmHeight}+"px"}}>
        <thead>
          <tr>
          <th style={{width: "20rem"}}>Id</th>
            <th style={{width: "5rem"}}>Category</th>
            <th style={{width: "5rem"}}>SeqNo</th>
            <th style={{width: "20rem"}}>Title</th>
            <th style={{width: "10rem"}}>Toggle Concepts</th>
            <th style={{width: "10rem"}}>Toggle Analysis</th>
            <th style={{width: "10rem"}}>Toggle Code</th>          
            <th style={{width: "5rem"}}>Remove</th>
          </tr>
        </thead>
        <tbody>
          {courseTopicItems?.slice()    
            .sort((a, b) => {
              if (a.category > b.category) return 1;
              else if(a.category < b.category) return -1;
              else if(a.seqNo > b.seqNo) return 1;
              else return -1;
            })                 
          .map((topicItem, index) => 
          <tr key={topicItem.id}>
          <td>{topicItem.id}</td>
          <td>{topicItem.category}</td>
          <td>{topicItem.seqNo}</td>
          <td>{topicItem.title}</td>
          <td>{topicItem.showConcepts && (
              <Fragment>
              <i className="fa fa-minus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleHideConcepts(topicItem.id)}></i>                  
              <i className="fa fa-plus-circle fa-2x ml-2 text-info"></i>                     
              </Fragment>            
            )}
              {!topicItem.showConcepts && (
              <Fragment>
              <i className="fa fa-minus-circle fa-2x ml-2 text-warning"></i>                     
              <i className="fa fa-plus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleShowConcepts(topicItem.id)}></i>  
              </Fragment>            
            )}            
          </td>
          <td>{topicItem.showAnalysis && (
            <Fragment>
            <i className="fa fa-minus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleHideAnalysis(topicItem.id)}></i>               
            <i className="fa fa-plus-circle fa-2x ml-2 text-info"></i>                      
            </Fragment>            
          )}
            {!topicItem.showAnalysis && (
            <Fragment>
            <i className="fa fa-minus-circle fa-2x ml-2 text-warning"></i>                     
            <i className="fa fa-plus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleShowAnalysis(topicItem.id)}></i>  
            </Fragment>            
          )}            
          </td>
          <td>{topicItem.showCode && (
            <Fragment>
            <i className="fa fa-minus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleHideCode(topicItem.id)}></i>                
            <i className="fa fa-plus-circle fa-2x ml-2 text-info"></i>                     
            </Fragment>            
          )}
            {!topicItem.showCode && (
            <Fragment>
            <i className="fa fa-minus-circle fa-2x ml-2 text-warning"></i>                     
            <i className="fa fa-plus-circle fa-2x ml-2 text-secondary clickable" onClick={() => handleShowCode(topicItem.id)}></i>  
            </Fragment>            
          )}            
          </td>
          <td><i className="fa fa-trash fa-2x mr-3 text-warning clickable" onClick={(e) => removeItem(topicItem.id, e)}></i></td>
          </tr>
          )}       
        </tbody>
      </Table>
    </SplitPane>

    </Container>
  )
}

export default observer(SetCourseTopicItems)
