import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import {Container, Card, Button} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser'
import EditAnnouncementModal from './EditAnnouncementModal';

const AdminAnnouncements = () => {
  const rootStore = useContext(RootStoreContext);
  const {announcements, getAnnouncements, setActive} = rootStore.announcementStore; 
  const {openModal} = rootStore.modalStore;

  useEffect(() => {
    const loadValues = async() => {
      await getAnnouncements();
    }
    loadValues();
  }, [getAnnouncements])
  const handleSetActive = (id: string, active: boolean) => {
    setActive(id, active);
  }
  return (
    <Container>
      <Button variant="info"
      onClick={() => openModal('Add Announcement', <EditAnnouncementModal announcement={null}/>, 'modal-pane-3')}
      >New Announcement
      </Button>       
      {announcements.slice().sort((a, b) => a.date < b.date ? 1 : -1)
      .map((announcement, idx) => 
        <Card className="mt-3 mb-2" key={idx}>
          <Card.Header>
            <Card.Body>
              <Card.Title className="active-title">
                {announcement.title}            
                <i className="fa fa-edit ml-3 mt-2 mb-2 text-info clickable" 
                  onClick={() => openModal("Update Announcement (Text)", <EditAnnouncementModal announcement={announcement}/>, 'modal-pane-3')}>          
                </i>
                {announcement.active  && (
                  <i className="fa fa-minus-circle ml-2 text-secondary clickable icon-size" onClick={() => handleSetActive(announcement.id, false)}></i>
                )}
                {!announcement.active  && (
                  <i className="fa fa-plus-circle ml-2 text-primary clickable icon-size" onClick={() => handleSetActive(announcement.id, true)}></i>      
                )}  
              </Card.Title>
              {parse(announcement.description)}
            </Card.Body>
          </Card.Header>
        </Card>      
      )}
    </Container>
  )
}

export default observer(AdminAnnouncements)