export interface ICourseLesson {
  id: string;
  seqNo: number;
  topic: string;
  date: Date;
  timePeriod: string,
  url: string,
  content: string,
  courseCode: string,
  courseName: string,
  numOfAttendances: number,
  numOfAssignments: number
}

export class CourseLesson implements ICourseLesson {
  id: string = '';
  seqNo: number = 0;
  topic: string = '';
  date: Date = new Date();
  timePeriod: string = '';
  url: string = '';
  content: string = '';
  courseCode: string = '';
  courseName: string = '';
  numOfAttendances: number = 0;
  numOfAssignments: number = 0
};
