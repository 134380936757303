import React, {useEffect, useContext}  from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Table, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

const StudentReportModal = ({courseCode, userName}) => {
  const rootStore = useContext(RootStoreContext);    
  const {studentReport, getStudentReport} = rootStore.courseStore;

  useEffect(() => {
    getStudentReport(courseCode, userName);
  }, [getStudentReport, courseCode, userName]);

  const renderSwitch = (param) => {
    switch(param) {
      case 1: 
        return 'On Time';
      case 2:
        return 'Late';
      case 3:
        return 'Absent with notice';
      case 4:
        return 'Absent';
      default:
        return '';
    }
  };

  return (
    <Container className="mt-3">
      <Row>
        <h4 className={'mr-auto text-primary'}>Note: Attendance is not accurate and the student may switch course sessions</h4>
      </Row>
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>TimePeriod</th>
            <th>Attendance</th>
            <th>Assignments</th>
            <td>Submissions</td>
            <td><i className="fa fa-star fa-2x ml-3 mt-2 mb-2 text-primary"/></td>      
            <td><i className="fa fa-check-circle fa-2x ml-3 mt-2 mb-2 text-info"/></td>  
            <td><i className="fa fa-bug fa-2x ml-3 mt-2 mb-2 text-warning"/></td>  
            <td><span className="red-cross">X</span></td>  
            <td><span>Other</span></td>                                                                      
          </tr>
        </thead>
        <tbody>
          {studentReport?.slice().sort((a, b) => a.seqNo > b.seqNo ? 1 : -1)
          .map((studentLesson, index) => 
            <tr key={index}>
              <td>{studentLesson.seqNo}</td>
              <td>{(new Date(studentLesson.date).toISOString()).substring(0, 10)}</td>
              <td>{studentLesson.timePeriod}</td>
              <td>{renderSwitch(studentLesson.attendingCode)}</td>
              <td> {studentLesson.numOfAssignments}</td>
              <td> {studentLesson.numOfSubmissions}</td>
              <td> {studentLesson.numOfGreat}</td>
              <td> {studentLesson.numOfAcceptable}</td>
              <td> {studentLesson.numOfProblematic}</td>
              <td> {studentLesson.numOfUnacceptable}</td>
              <td> {studentLesson.numOfUngraded}</td>                                                                                    
            </tr>
          )}
        </tbody>
      </Table>

      <Row>
      <h4 className={'mr-auto text-secondary'}>        
        total attended: {studentReport.filter(x=>x.attendingCode===1 || x.attendingCode===2).length}, 
        total assignments: {studentReport.reduce((prev, next) => prev + next.numOfAssignments, 0)},       
        total submissions: {studentReport.reduce((prev, next) => prev + next.numOfSubmissions, 0)} 
      </h4>
      </Row>      
    </Container>
  )
}

export default observer(StudentReportModal)
