import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { IRole } from '../models/role';

export default class RoleStore {
    rootStore: RootStore;    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable loading = false;

    @observable roles: IRole[] = [];

    @action loadRoles = async () => {
        this.loading = true;
        try {
            this.roles = await agent.Roles.list();
            runInAction(() => {
                this.loading = false;
            });
            return this.roles;
        } catch (error){
            runInAction(() => {
                this.loading = false;
            });
        }
    };
    
}