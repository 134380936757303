import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';

const TopicsForm = () => {
  const history = useHistory();
  const rootStore = useContext(RootStoreContext); 
  const {subjects, loadSubjects } = rootStore.subjectStore;  
  const {topics, loadTopics} = rootStore.topicStore;  
  const [searchSubject, setSearchSubject] = useState('');

  useEffect(() => {
    loadSubjects();
    loadTopics();
  }, [loadSubjects, loadTopics]);



  const handleSubjectSearch = (event: any) => {
    setSearchSubject(event.target.value);
  };

  return (
    <Container fluid style={{ marginTop: '2em'}}>
      <Row className="mb-3">
      <Col lg={1} md={1} sm={1} className="search-item">
          <Form.Label>
            Subject
          </Form.Label>
          </Col>
          <Col lg={3} md={3} sm={3}>
          <Form.Control 
            required 
            as="select" 
            name="subject" 
            value={searchSubject}
            onChange={handleSubjectSearch}
            >
            <option></option>
            {subjects.map((subject)=> {
              return (<option key={subject.code}>{subject.name}</option>)
            })}              
          </Form.Control>
        </Col>
        <Col lg={{span:2, offset:2}} className="mr-auto search-item">
        <Link to={`/topics/newtopic`}>
          <Button variant="primary">New Topic</Button>
        </Link>
      </Col>          
      </Row>  
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Subject Name</th>
            <th>Has Concepts?</th>
            <th>Has Analysis?</th>
            <th>Has Code?</th>
            <th>Is Separate Page?</th>
            <th>Is Workbook?</th>            
          </tr>
        </thead>
        <tbody>
          {topics && 
            topics.filter(topic => {
              if(searchSubject === "") {
                return true;
              } else {
                return topic.subjectName === searchSubject;
              }
            })
            .map(topic => (
              <tr key={topic.id} className="clickable" onClick={(e: any) => {
                history.push(`/topics/${topic.id}`);
              }}>
                <td>{topic.name}</td>
                <td>{topic.subjectName}</td>
                <td>{topic.hasConcepts ? "Yes" : "No"}</td>
                <td>{topic.hasAnalysis ? "Yes" : "No"}</td>
                <td>{topic.hasCode ? "Yes" : "No"}</td>
                <td>{topic.isSeparatePage ? "Yes" : "No"}</td>
                <td>{topic.isWorkbook ? "Yes" : "No"}</td>                                                
              </tr>
            ))          
          }
        </tbody>
      </Table>
    </Container> 
  )
}

export default observer(TopicsForm)
