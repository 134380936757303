import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { DiscussionInfo } from '../models/discussion';

export default class DiscussionStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable discussions : DiscussionInfo[] = [];

  @action getDiscussions = async(courseTopicId: string) => {
    this.loading = true;
    try {
      var dis = await agent.Discussion.discussions(courseTopicId);
      runInAction(() => {
        this.discussions = dis;
        this.loading =false;
      });  
      return dis;         
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }    
  }

  @action getCourseDiscussions = async(courseId: string) => {
    this.loading = true;
    try {
      var dis = await agent.Discussion.courseDiscussions(courseId);
      runInAction(() => {
        this.discussions = dis;
        this.loading =false;
      });  
      return dis;         
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });       
    }    
  }  
}