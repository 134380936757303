import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Container, Table, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { ICourseTopic } from '../../app/models/courseTopic';

interface CourseParams {
  courseCode: string;
}

const initialValues: ICourseTopic[] = [];

const SetCourseTopicsForm: React.FC<RouteComponentProps<CourseParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);  
  const {loadCourseTopics, setCourseTopic} = rootStore.courseTopicStore;
  const [newCourseTopics, setNewCourseTopics] = useState(initialValues);

  useEffect(() => { 
    const loadValues = async() => {
      var ct = await loadCourseTopics(match.params.courseCode);
      if(ct) {
        setNewCourseTopics(ct);
      }
    }
    if(match.params.courseCode) {
      loadValues();
    }
  }, [loadCourseTopics, match.params.courseCode, setNewCourseTopics]);

  const HandleAddCourseTopic = (topicId: any) => {
    setCourseTopic(match.params.courseCode, topicId, true);
    if(newCourseTopics && topicId) {
      var newValues = newCourseTopics.map(x => 
        x.topicId === topicId ? 
        {...x, isAdded: true} : x
        );
      setNewCourseTopics(newValues);
    }
  }

  const HandleRemoveCourseTopic = (topicId: any) => {
    setCourseTopic(match.params.courseCode, topicId, false);
    if(newCourseTopics && topicId) {
      var newValues = newCourseTopics.map(x => 
        x.topicId === topicId ? 
        {...x, isAdded: false} : x
        );
      setNewCourseTopics(newValues);
    }
  }

  return (
    <Container className="mt-3">
      <Row>
        <h3 className="mr-auto"><span style={{color: 'red'}}>Course Topics</span></h3>
        <Link to={`/t-classes/${match.params.courseCode}`}>
          <Button >Back to Class</Button>
        </Link>          
      </Row>
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th className="hidden-column">Id</th>          
            <th>Name</th>
            <th>Has Concepts?</th>            
            <th>Has Analysis?</th>
            <th>Has Code?</th>
            <th>Is Separate Page?</th>
            <th>Is Workbook?</th>
            <th>Added?</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {newCourseTopics?.slice()
          .map((courseTopic, index) => 
            <tr key={index} className={`${courseTopic.isAdded ? "text-primary" : "text-secondary"}`}>
              <td className="hidden-column">{courseTopic.topicId}</td>
              <td>{courseTopic.name}</td>
              <td>{courseTopic.hasConcepts ? "Yes" : "No"}</td>
              <td>{courseTopic.hasAnalysis ? "Yes" : "No"}</td>
              <td>{courseTopic.hasCode ? "Yes" : "No"}</td>
              <td>{courseTopic.isSeparatePage ? "Yes" : "No"}</td>
              <td>{courseTopic.isWorkbook ? "Yes" : "No"}</td>              
              <td>{courseTopic.isAdded ? "Yes" : "No"}</td>
              <td>
                {courseTopic.isAdded && (
                  <i className="fa fa-minus-square fa-2x ml-2 text-danger clickable" onClick={() => HandleRemoveCourseTopic(courseTopic.topicId)}></i>
                )}
                {!courseTopic.isAdded && (
                  <i className="fa fa-plus-square fa-2x ml-2 text-primary clickable" onClick={() => HandleAddCourseTopic(courseTopic.topicId)}></i>      
                )}               
              </td>
            </tr>            
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default SetCourseTopicsForm
