import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { IAssignment, IAssignmentContent, IStudentAssignment, IStudentAssignmentContent} from '../models/assignment';
import { IMessageInfo } from '../models/message';

export default class AssignmentStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable assignmentContent: IAssignmentContent | null = null;  
  @observable currentProblem: IAssignment | null = null;
  @observable studentAssignmentContent: IStudentAssignmentContent | null = null;
  @observable discussions : IMessageInfo[] = [];  
  @observable studentSubmissions: IStudentAssignment[] = [];

  @action loadAssignmentContent = async (lessonId: string) => {
    this.loading = true;
    try {
      var ac = await agent.Assignments.assignmentContent(lessonId);
      runInAction(() => {
        this.assignmentContent = ac;
        this.loading = false;
      });
      return ac;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });      
    }
  };

  @action addProblem = async(lessonId: string, title: string, url: string, description: string, 
    seqNo: string, deadline: string, submitMode: string) => 
  {
    this.loading = true;
    try{
      var problem = await agent.Assignments.addProblem(lessonId, title, url, description, seqNo, deadline, submitMode);
      runInAction(() => {
        if(this.assignmentContent){
          this.assignmentContent.assignments.push(problem);
        }
        this.loading = false;
      });
      return problem;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action addPickProblem = async(lessonId: string, title: string, 
    courseTopicId: string, topicItemId: string, seqNo: string, deadline: string, submitMode: string) => 
  {
    this.loading = true;
    try{
      var problem = await agent.Assignments.addPickProblem(lessonId, title, courseTopicId, 
        topicItemId, seqNo, deadline, submitMode);
      runInAction(() => {
         if(this.assignmentContent){
            this.assignmentContent.assignments.push(problem);
         }
        this.loading = false;
      });
      return problem;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action addProblemAttach = async(lessonId: string, title: string, seqNo: 
    string, deadline: string, submitMode: string, file: Blob) => 
  {
    this.loading = true;
    try{
      var problem = await agent.Assignments.addProblemAttach(lessonId, title, 
        seqNo, deadline, submitMode, file);
      runInAction(() => {
        if(this.assignmentContent){
          this.assignmentContent.assignments.push(problem.data);
        }
        this.loading = false;
      });
      return problem;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action updateProblem = async(problemId: string, title: string, url: string, 
    description: string, seqNo: string, deadline: string, submitMode: string) => 
  {
    this.loading = true;
    try{
      await agent.Assignments.updateProblem(problemId, title, url, description, seqNo, deadline, submitMode);
      runInAction(() => {
        if(this.assignmentContent){
          // update problem
        }
        this.loading = false;
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }  

  @action updatePickProblem = async(problemId: string, title: string, 
    courseTopicId: string, topicItemId: string, seqNo: string, 
    deadline: string, submitMode: string) => 
  {
    this.loading = true;
    try{
      await agent.Assignments.updatePickProblem(problemId, title, 
        courseTopicId, topicItemId, seqNo, deadline, submitMode);
      runInAction(() => {
        if(this.assignmentContent){
          // update problem
        }
        this.loading = false;
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }  

  @action removeProblem = async(problemId: string) => 
  {
    this.loading = true;
    try{
      await agent.Assignments.removeProblem(problemId);
      runInAction(() => {
        if(this.assignmentContent){
          this.assignmentContent.assignments = this.assignmentContent.assignments.filter(x=>x.id !== problemId);
        }
        this.loading = false;
      }); 
      this.rootStore.modalStore.closeModal();          
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action removeResource= async(lessonId: string, resourceId: string) => 
  {
    this.loading = true;
    try{
      await agent.Assignments.removeResource(lessonId, resourceId);
      runInAction(() => {      
        if(this.assignmentContent && this.assignmentContent.id === lessonId){
          this.assignmentContent.resources = this.assignmentContent.resources.filter(x=>x.id !== resourceId);
        }
        this.loading = false;
      });    
      this.rootStore.modalStore.closeModal();       
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }    
  }

  @action submitCode= async(problemId: string, remark: string, code: string) => 
  {
    this.loading = true;
    try{
      await agent.Assignments.submitCode(problemId, remark, code);
      if(this.studentAssignmentContent){
        var assignment = this.studentAssignmentContent.studentAssignments.find(x=>x.id === problemId);
        if(assignment){
          assignment.code = code;
          assignment.studentRemark = remark;
        }
      }
      this.rootStore.modalStore.closeModal();      
    } finally {
      this.loading = false;
    }
  }

  @action submitAttach= async(problemId: string, remark: string, file: Blob) => 
  {
    this.loading = true;
    try{
      var attach = await agent.Assignments.submitAttach(problemId, remark, file);
      if(this.studentAssignmentContent){
        var assignment = this.studentAssignmentContent.studentAssignments.find(x=>x.id === problemId);
        if(assignment){
          assignment.attachment = attach.data.attachment;
          assignment.attachmentId = attach.data.attachmentId;
          assignment.attachmentUrl = attach.data.attachmentUrl;
          assignment.studentRemark = remark;
        }
      }
      this.rootStore.modalStore.closeModal();
    } finally {
      this.loading = false;
    }
  }

  // for student
  @action loadStudentAssignmentContent = async (lessonId: string) => {
    this.loading = true;
    try{
      var studentAssign = await agent.Assignments.studentAssignmentContent(lessonId);
      runInAction(() => {
        this.studentAssignmentContent = studentAssign;
        this.loading = false;
      });
      return studentAssign;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  }

  // for teachers
  @action loadStudentSubmissions = async (problemId: string) => {
    this.loading = true;
    try{
      var submissions = await agent.Assignments.getStudentSubmissions(problemId);
      runInAction(() => {
        this.studentSubmissions = submissions;
        this.loading = false;
      });
      return submissions;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  }

  @action getProblem = async (problemId: string) => {
    this.loading = true;
    try {
      var problem = await agent.Assignments.getProblem(problemId);
      runInAction(() => {
        this.loading = false;
        this.currentProblem = problem;
      });
      return problem;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    } 
  }

  @action setTeacherGrade = async (problemId: string, userName: string, grade: string, teacherRemark: string) => {
    this.loading = true;
    try {
      await agent.Assignments.setTeacherGrade(problemId, userName, grade, teacherRemark);
      runInAction(() => {
        this.loading = false;
        if(this.studentSubmissions){
          var submission = this.studentSubmissions.find(x=>x.userName === userName);
          if(submission){
            submission.grade = grade;
            submission.teacherRemark = teacherRemark;
          }
        }        
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    } 
  }

  @action addAssignmentResourceText= async(lessonId: string, title: string, content: string) => {
    this.loading = true;
    try {
      var url = '';
      var assignmentResource = await agent.Assignments.addResource(lessonId, title, url, content);
      runInAction(() => {
        this.loading = false;
        if(this.assignmentContent && this.assignmentContent.id === lessonId)
        {
          this.assignmentContent.resources.push(assignmentResource);
        }
        this.loading = false;         
      });              
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };  

  @action addAssignmentResourceLink= async(lessonId: string, title: string, url: string) => {
    this.loading = true;
    try {
      var content = '';
      var assignmentResource = await agent.Assignments.addResource(lessonId, title, url, content);
      runInAction(() => {
        this.loading = false;
        if(this.assignmentContent && this.assignmentContent.id === lessonId)
        {
          this.assignmentContent.resources.push(assignmentResource);
        }
        this.loading = false;              
      });              
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action addAssignmentResourceAttach= async(lessonId: string, filename: string,  blob: Blob) => {
    this.loading = true;
    try {
      var assignmentResource = await agent.Assignments.addResourceAttach(lessonId, filename, blob);
      runInAction(() => {
        this.loading = false;
        if(this.assignmentContent && this.assignmentContent.id === lessonId)
        {
          this.assignmentContent.resources.push(assignmentResource.data);
        }
        this.loading = false;   
      });     
      this.rootStore.modalStore.closeModal();         
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action setSeqNo = async(assignmentId: string, seqNo: number) => {
    this.loading = true;
    try {
      await agent.Assignments.setSeqNo(assignmentId, seqNo);
      runInAction(() => {
        if (this.assignmentContent) {
          var assignment = this.assignmentContent.assignments.find(x=>x.id === assignmentId);
          if(assignment){
            assignment.seqNo = seqNo;
          }
        }
        this.loading = false;
      });      
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  }

  @action setDeadline = async(assignmentId: string, deadline: string) => {
    this.loading = true;
    try {
      await agent.Assignments.setDeadline(assignmentId, deadline);
      runInAction(() => {
        if (this.assignmentContent) {
          var assignment = this.assignmentContent.assignments.find(x=>x.id === assignmentId);
          if(assignment){
            assignment.deadline = new Date(deadline);
          }
        }
        this.loading = false;
      });      
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  }

  @action setAnalysisUrl = async(assignmentId: string, analysisUrl: string) => {
    this.loading = true;
    try {
      await agent.Assignments.setAnalysisUrl(assignmentId, analysisUrl);
      runInAction(()=>{
        if(this.assignmentContent) {
          var assignment = this.assignmentContent.assignments.find(x=>x.id === assignmentId);
          if(assignment) {
            assignment.analysisUrl = analysisUrl;
          }
        }
        this.loading = false;
      })
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  }

  @action setResourceSeqNo = async(resourceId: string, seqNo: number) => {
    this.loading = true;
    try {
      await agent.Assignments.setResourceSeqNo(resourceId, seqNo);
      runInAction(() => {
        if (this.assignmentContent) {
          var resource = this.assignmentContent.resources.find(x=>x.id === resourceId);
          if(resource){
            resource.seqNo = seqNo;
          }
        }
        this.loading = false;
      });      
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  } 
  
  @action loadDiscussions = async(assignmentId: string) => {
    this.loading = true;
    try {
      var dis = await agent.Assignments.getDiscussions(assignmentId);
      runInAction(() => {
        this.loading = false;
        this.discussions = dis;
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }    
  }

  @action addDiscussion = async(assignmentId: string, message: string) => {
    this.loading = true;    
    try {
      var discussion = await agent.Assignments.addDiscussion(assignmentId, message);    
      runInAction(() => {
        this.loading = false;
        this.discussions.push(discussion);
        this.rootStore.modalStore.closeModal();        
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }  
}