import React, { useContext, useState, useEffect } from 'react';
import { IDigitInfo } from './DigitInfo';
import { RootStoreContext } from '../../app/stores/rootStore';
import { keyImages} from '../../app/common/lists/puzzleImages';
import {Container, Row, Col, Image} from 'react-bootstrap';
import './puzzle.css';
const imageStyle = {
  width: '64px',
  height: '64px'
};
export const NumberPuzzleSolve = () => {
  const [valid, setValid] = useState(true);
  const [expression, setExpression] = useState(' ');
  const [digits, setDigits] = useState<IDigitInfo[]>([]);
  const rootStore = useContext(RootStoreContext);
  const { loadNumberPuzzle, numberPuzzle, solvePuzzle } = rootStore.numberPuzzleStore;
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    loadNumberPuzzle().then(numberPuzzle => {
      if (!numberPuzzle!.digits.includes(',')) {
        setCompleted(true);
      }
      else {
        let dis: IDigitInfo[] = [];
        numberPuzzle!.digits.split(',').forEach(digit => {
          dis.push({
            digit: digit,
            available: true
          });
        });
        setDigits(dis);
      }
    });
  }, [loadNumberPuzzle, setCompleted]);

  const getFileName = (di: IDigitInfo) => {
    switch (di.digit) {
      case '0':
        return di.available ? keyImages[1] : keyImages[0];
      case '1':
        return di.available ? keyImages[4] : keyImages[3];
      case '2':
        return di.available ? keyImages[7] : keyImages[6];
      case '3':
        return di.available ? keyImages[10] : keyImages[9];
      case '4':
        return di.available ? keyImages[13] : keyImages[12];
      case '5':
        return di.available ? keyImages[16] : keyImages[15];
      case '6':
        return di.available ? keyImages[19] : keyImages[18];
      case '7':
        return di.available ? keyImages[22] : keyImages[21];
      case '8':
        return di.available ? keyImages[25] : keyImages[24];
      case '9':
        return di.available ? keyImages[28] : keyImages[27];
    }
  };

  const checkExpression = (expression: string) => {
    var result = validateExpression(expression, numberPuzzle!.result);
    if(result === -1){
      setValid(false);
      setExpression(expression);
    } else if(result === 0){
      setValid(true);
      setExpression(expression);
    } else {
      setValid(true);
      setExpression(expression);
      if(!digits.some(x=>x.available)) {
        solvePuzzle(expression);       
      }
    }
  }

  const validateExpression = (expression: string, result: string) => {
    if (expression === '') return 0;
    while (expression.includes(')')) {
      var bEnd = expression.indexOf(')');
      var bStart = bEnd - 1;
      while (bStart >= 0) {
        var char = expression[bStart];
        if (char === '(') {
          break;
        }
        bStart--;
      }
      if (expression[bStart] !== '(') {
        return -1; // invalid
      }
      var answer = calcExpression(expression.substring(bStart + 1, bEnd));
      if (answer === '') {
        return -1; // invalid
      }
      expression =
        expression.substring(0, bStart) +
        answer +
        expression.substring(bEnd + 1, expression.length);
    }
    answer = calcExpression(expression);
    if(answer === '') {
      return -1;
    }
    else if (answer === result) {
      return 1;
    } else {
      return 0;
    }
  };

  const calcExpression = (expression: string) => {
    var list = convert2List(expression);
    var stack: string[] = [];
    var index = 0;
    while (index < list.length) {
      var item = list[index];
      if (item === '*') {
        if (index + 1 === list.length) return '';
        if (stack.length === 0) return '';
        var strNum1 = stack.pop();
        var strNum2 = list[index + 1];
        if (Number.isNaN(Number(strNum1)) || Number.isNaN(Number(strNum2))) {
          return '';
        }
        var num1 = parseInt(strNum1!);
        var num2 = parseInt(strNum2);
        stack.push((num1 * num2).toString());
        index += 2;
      } else if (item === '/') {
        if (index + 1 === list.length) return '';
        if (stack.length === 0) return '';
        strNum1 = stack.pop();
        strNum2 = list[index + 1];
        if (Number.isNaN(Number(strNum1)) || Number.isNaN(Number(strNum2))) {
          return '';
        }
        num1 = parseInt(strNum1!);
        num2 = parseInt(strNum2);
        if (num1 % num2 > 0) {
          return '';
        }
        stack.push((num1 / num2).toString());
        index += 2;
      } else {
        stack.push(item);
        index++;
      }
    }
    var strResult = stack.shift();
    if (Number.isNaN(Number(strResult))) {
      return '';
    }
    var result = parseInt(strResult!);
    while (stack.length > 0) {
      var op = stack.shift();
      if (op === '+') {
        if (stack.length === 0) return '';
        var strNum = stack.shift();
        if (Number.isNaN(Number(strNum))) {
          return '';
        }
        var num = parseInt(strNum!);
        result += num;
      } else if (op === '-') {
        if (stack.length === 0) return '';
        strNum = stack.shift();
        if (Number.isNaN(Number(strNum))) {
          return '';
        }
        num = parseInt(strNum!);
        result -= num;
      } else {
        return '';
      }
    }
    return result.toString();
  };

  const convert2List = (expression : string) => {
    var list = [];
    var strNum = '';
    var index = 0;
    while (index < expression.length) {
      var char = expression[index];
      if (
        char === '*' ||
        char === '/' ||
        char === '+' ||
        char === '-' ||
        Number.isNaN(Number(char))
      ) {
        if (strNum !== '') {
          list.push(strNum);
          strNum = '';
        }
        list.push(char);
      } else {
        strNum = strNum + char;
      }
      index++;
    }
    if (strNum !== '') {
      list.push(strNum);
    }
    return list;
  };

  const handleOnClick1 = () => {
    if (digits[0].available) {
      digits[0].available = false;
      const newExpr = expression + digits[0].digit;
      checkExpression(newExpr);
    }
  };

  const handleOnClick2 = () => {
    if (digits[1].available) {
      digits[1].available = false;
      const newExpr = expression + digits[1].digit;
      checkExpression(newExpr);
    }
  };

  const handleOnClick3 = () => {
    if (digits[2].available) {
      digits[2].available = false;
      const newExpr = expression + digits[2].digit;
      checkExpression(newExpr);
    }
  };

  const handleOnClick4 = () => {
    if (digits[3].available) {
      digits[3].available = false;
      const newExpr = expression + digits[3].digit;
      checkExpression(newExpr);
    }
  };

  const handleOnClick_plus = () => {
    const newExpr = expression + '+';
    checkExpression(newExpr);
  };

  const handleOnClick_minus = () => {
    const newExpr = expression + '-';
    checkExpression(newExpr);
  };

  const handleOnClick_multiply = () => {
    const newExpr = expression + '*';
    checkExpression(newExpr);
  };

  const handleOnClick_divide = () => {
    const newExpr = expression + '/';
    checkExpression(newExpr);
  };

  const handleOnClick_lBracket = () => {
    const newExpr = expression + '(';
    checkExpression(newExpr);
  };

  const handleOnClick_rBracket = () => {
    const newExpr = expression + ')';
    checkExpression(newExpr);
  };

  const handleOnClick_delete = () => {
    if (expression.length > 0) {
      var char = expression[expression.length - 1];
      if (
        char === '+' ||
        char === '-' ||
        char === '*' ||
        char === '/' ||
        char === '(' ||
        char === ')'
      ) {
      } else {
        if (digits[0].digit === char && !digits[0].available) {
          digits[0].available = true;
        } else if (digits[1].digit === char && !digits[1].available) {
          digits[1].available = true;
        } else if (digits[2].digit === char && !digits[2].available) {
          digits[2].available = true;
        } else if (digits[3].digit === char && !digits[3].available) {
          digits[3].available = true;
        }
        setDigits(digits);
      }
      checkExpression(expression.slice(0, expression.length - 1));
    }
  };

  const handleOnClick_clean = () => {
    digits[0].available = true;
    digits[1].available = true;
    digits[2].available = true;
    digits[3].available = true;
    checkExpression('');
  };

  return (
    <Container>
    {completed ?  
      (
        <Row className="title pl-2 mt-3">
          Congratulations! You have passed all levels!
        </Row>
      ) :  ( 
      <>
      <Row className="title pl-2">
        Puzzle # {numberPuzzle?.seqNo}
      </Row>
      <Row className="result pl-2">
        Result: <span className="ml-2">{numberPuzzle?.result}</span>        
      </Row>  
      <Row className="expr mb-3 pl-2">
        <span style={{color: "green"}} className="mr-3">Expression:</span> 
        <span style={{color: valid ? 'black' : 'red'}}>{expression}</span>
      </Row>
      <Row className="solve pl-2 input-key">
        <h5 style={{color: "purple"}}>Input Keyboard</h5>
      </Row>
      {digits.length === 4 && (
        <Row className="mt-4">
        <Col lg={2} md={2} sm={3} xs={3}>
            <Image
              src={`/assets/NumberPuzzle/${getFileName(digits[0])}.png`}
              fluid
              style={imageStyle}
              onClick={handleOnClick1}
            />
          </Col>
          <Col lg={2} md={2} sm={3} xs={3}>
            <Image
              src={`/assets/NumberPuzzle/${getFileName(digits[1])}.png`}
              fluid
              style={imageStyle}
              onClick={handleOnClick2}
            />
          </Col>
          <Col lg={2} md={2} sm={3} xs={3}>
            <Image
              src={`/assets/NumberPuzzle/${getFileName(digits[2])}.png`}
              fluid
              style={imageStyle}
              onClick={handleOnClick3}
            />
          </Col>
          <Col lg={2} md={2} sm={3} xs={3}>
            <Image
              src={`/assets/NumberPuzzle/${getFileName(digits[3])}.png`}
              fluid
              style={imageStyle}
              onClick={handleOnClick4}
            />
          </Col>
        </Row>        
      )}  
      <Row className="mt-4">
      <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/plus_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_plus}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/minus_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_minus}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/multiply_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_multiply}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/divide_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_divide}
          />
        </Col>
      </Row>
      <Row className="mt-4">
      <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/lBracket_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_lBracket}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/rBracket_b.png"
            onClick={handleOnClick_rBracket}
            fluid
            style={imageStyle}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/bDelete_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_delete}
          />
        </Col>
        <Col lg={2} md={2} sm={3} xs={3}>
          <Image
            src="/assets/NumberPuzzle/clean_b.png"
            fluid
            style={imageStyle}
            onClick={handleOnClick_clean}
          />
        </Col>
      </Row> 
      </>  
    ) }     
    </Container>
  );
};
