import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Container, Table} from 'react-bootstrap'
import { useHistory} from 'react-router-dom';

const StudentClassesform = () => {
  const history = useHistory();
  const rootStore = useContext(RootStoreContext);    
  const {courses, loadStudentCourses} = rootStore.userStore;

  useEffect(() => {
    loadStudentCourses();
  }, [loadStudentCourses])

  return (
    <Container fluid style={{ marginTop: '2em'}}> 
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Level</th>
            <th>Sart Date</th>
            <th>End Date</th>
            <th>Scheduled Time</th>
            <th>Teacher</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {courses && 
          courses.map(course => (
            <tr key={course.courseCode} className="clickable" onClick={(e: any) => {
              history.push(`/s-classes/${course.courseCode}`); }
            }>
              <td>{course.courseCode}</td>
              <td>{course.courseName}</td>
              <td>{course.subject!.level}</td>
              <td>{new Date(course.startDate).toISOString().substring(0, 10)}</td>
              <td>{new Date(course.endDate).toISOString().substring(0, 10)}</td>
              <td>{course.dayOfTheWeek + ' ' + course.timePeriod}</td>
              <td>{course.teacher!.firstName + ' ' + course.teacher!.lastName}</td>
              <td>{course.active ? 'Yes' : 'No'}</td>                                                          
            </tr>
          ))
          }
        </tbody>
      </Table>      
    </Container>      
  )  
}

export default observer(StudentClassesform)
