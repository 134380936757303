import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import Parser from 'html-react-parser';
import {Container, Button, Card, Row, Col, ListGroup} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import {
  PythonProblemValues,
  IPythonTestCase
} from '../../app/models/pythonProblem';

interface DetailParams {
  id: string;
}

const PythonTestCaseList: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadPythonProblem,
    deletePythonTestCase
  } = rootStore.pythonProblemStore;
  const [pythonProblem, setPythonProblem] = useState(new PythonProblemValues());
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (match.params.id) {
      loadPythonProblem(match.params.id).then(pythonProblem => {
        setPythonProblem(pythonProblem!);
      });
    }
  }, [loadPythonProblem, match.params.id]);

  const handleConfirm = () => {
      setOpen(false);
      if(target){
          deletePythonTestCase(target);
      }
  }

  return (
    <Container style={{ marginTop: '1rem' }}>
      <Card>
        <Card.Header>Problem # {pythonProblem?.seqNo}</Card.Header>
          <Row>
            <Col lg={12}>
              <h3>{pythonProblem?.title}</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {pythonProblem &&
                pythonProblem.description &&
                Parser(pythonProblem.description)}
            </Col>
          </Row>
      </Card>
      <Card>
        <Card.Header>Test cases</Card.Header>
        <Link to={`/newpythontestcase/${pythonProblem.id}`}>
          <Button variant="primary">Add Test Case</Button>
        </Link>
        <ListGroup>
          {pythonProblem &&
            pythonProblem.pythonTestCases &&
            pythonProblem.pythonTestCases.map((testcase: IPythonTestCase) => (
              <ListGroup.Item key={testcase.id}>
                <Card>
                  <Card.Header>Input: {testcase.input}</Card.Header>
                  <Card.Header>Output: {testcase.output}</Card.Header>
                  <Card.Text>
                    TestCode: {testcase.testCode}
                  </Card.Text>
                  <Card.Text>
                    ResultType: {testcase.resultType}
                  </Card.Text>
                  <Link
                    to={`/editpythontestcase/${pythonProblem.id}/${testcase.id}`}
                  >
                    <i className="fa fa-edit" style={{color: "teal"}}/>
                  </Link>

                  <Button style={{backgroundColor: 'white', color: 'red'}}
                    name={testcase.id}
                    onClick={(e:any)=>{
                        setTarget(e.currentTarget.name);
                        setOpen(true);
                    }}>
                        <i className="fa fa-trash" style={{color: "teal"}}/>
                    </Button>

                </Card>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Card>
      <Button onSubmit={handleConfirm}>Confirm</Button>
      {open && (
        <p>Open</p>
      )}
    </Container>
  );
};

export default observer(PythonTestCaseList);
