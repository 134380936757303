import React, { useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button} from 'react-bootstrap';
import '../classes.css';
import StudentClassInfo from './StudentClassInfo';
import ContentModal from '../../app/common/modals/ContentModal';
import CodeModal from '../../app/common/modals/CodeModal';
import parse from 'html-react-parser';

interface ClassParams {
  courseCode: string;
}

const StudentClassForm: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {courseContent, loadCourseStudentContent} = rootStore.courseStore;
  const { openModal} = rootStore.modalStore;

  useEffect(() => {
    const loadValues = async() => {
      await loadCourseStudentContent(match.params.courseCode);
    }
    if(match.params.courseCode){
      loadValues();
    }
  }, [loadCourseStudentContent, match.params.courseCode])

  return (
    <div className="content">
      <div className="flex-display">
        <h2 className={`mr-auto ${courseContent?.active ? "active-title" : "inactive-title"}`}>Course: {courseContent?.courseName}</h2>
        <Link to={`/coursediscussions/${courseContent?.courseId}`}>
          <Button variant="warning" size="lg" className="mr-3">Latest Discussions</Button>
        </Link>
        {courseContent?.active &&
        <Link to={`/s-class/qa/${match.params.courseCode}`}>
          <Button variant="warning" size="lg" className="mr-3">Q&A</Button>
        </Link>  
        }      
        <Button variant="info" className="mr-3 mb-2" size="sm"
        onClick={() => openModal("Course Info", 
        <StudentClassInfo courseContent={courseContent} />, 'modal-pane-3')}
        >Course Info</Button>
      </div>
      
      <Row className="form-field" >
        <Col lg={3} md={3} sm={3}>
          <h3 className='darkblue-color'>
            Scheduled time
          </h3>
        </Col>
        <Col lg={9} md={9} sm={9}>
          <h3 className='maroon-color'>
            {courseContent?.dayOfTheWeek + ' ' + courseContent?.timePeriod}
          </h3>
        </Col>        
      </Row>

      <div className="mt-3 flex-display">
          <h3 className="mr-auto magenta-color">Notice
          </h3>
      </div>  
      <Row className="form-field" >
        <Col>
        <p className="wrap-line">{courseContent?.notice  && parse(courseContent?.notice)}</p>
        </Col>
      </Row>
    
      <div className='flex-display'>
        <h3 className='mr-auto darkgreen-color'>Lessons</h3>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Topic</th>
            <th>Date</th>
            <th>Time Period</th>
            <th>Video</th>
            <th>Assignment</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.lessons?.slice().sort((a, b) => {
            if(a.date > b.date) return 1;
            else if(a.date < b.date) return -1;
            else if(a.timePeriod > b.timePeriod) return 1;
            else return -1;
          }).map((lesson, index) => 
            <tr key={lesson.id}>
            <td>{lesson.seqNo}</td>
            <td>
              <Link to={`/lesson/s-content/${lesson.id}`}>
              {lesson.topic}
              </Link>              
            </td> 
            <td>{new Date(lesson.date).toISOString().substring(0, 10)}</td>
            <td>{lesson.timePeriod}</td>
            <td>
              {lesson.url && (
                <a href={lesson.url} target="_blank" rel="noopener noreferrer">video</a>
              )}
            </td>
            <td>
              {lesson.numOfAssignments > 0 && 
                <Link to={`/lesson/s-assignment/${lesson.id}`}>
                  Assignment
                </Link>
              }
            </td>
          </tr> 
          )}     
        </tbody>
      </Table>

      <div className='mt-3 flex-display'>
        <h3 className='mr-auto darkcyan-color'>Resources</h3>
      </div>
      <Table striped bordered hover className="mb-b">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.courseResources?.map((courseResource, index) =>
            <tr key={courseResource.id}>
            <td>{index+1}</td>
            <td>
              {courseResource.url && (
                <a href={courseResource.url} target="_blank" rel="noopener noreferrer">{courseResource.title}</a>
              )}
              {!courseResource.url && (
                <span className="clickable text-primary"
                onClick={() => openModal(`resource ${courseResource.title}`, <ContentModal content={courseResource.content}/>, 'modal-pane-1')}>
                  {courseResource.title}
                </span>
              )}
            </td>
          </tr>  
          )}     
        </tbody>
      </Table>

      {courseContent?.nonSepTopics?.map((topic, idx) => 
        <React.Fragment>
          <div className='mt-3 flex-display'>
              <h3 className='mr-auto darkcyan-color'>{topic.name}</h3> 
          </div>
          <Table striped bordered hover className="mb-3">
          <thead>            
            <tr>
              <th>Category</th>
                <th>SeqNo</th>
                <th>Title</th>
                {topic.hasConcepts &&
                  (<th>Concepts</th>)
                }
                {topic.hasAnalysis &&
                  (<th>Analysis</th>)
                }
                {topic.hasCode &&
                  (<th>Code</th>)
                }                        
          </tr>  
          </thead>
          <tbody>
            {topic.topicItems.map(topicItem => 
            <tr key={topicItem.id}>
              <td>{topicItem.category}</td>
              <td>{topicItem.seqNo}</td>
              <td>
              {topicItem.url && (
                <a href={topicItem.url} target="_blank" rel="noopener noreferrer">{topicItem.title}</a>
              )}               
              </td>
              {topic.hasConcepts && (
                <td>{topicItem.concepts}</td>
              )}
              {topic.hasAnalysis && (
                 (<td> 
                  {topicItem.analysisUrl && (
                  <a href={topicItem.analysisUrl} target="_blank" rel="noopener noreferrer">Analysis</a>
                  )}                
                  </td>)               
              )}
              {topic.hasCode &&
                (<td>
                {topicItem.code  && (
                    <span className="clickable text-primary"
                    onClick={() => openModal(`Code for ${topicItem.title}`, 
                    <CodeModal code={topicItem.code} />, 'modal-pane-1')}>
                      Code
                    </span>           
                )}                                    
                </td>)
              }              
            </tr>
            )}
          </tbody>                   
          </Table>
        </React.Fragment>
      )}

      {courseContent?.sepTopics?.map((topic, idx) =>
        <div className='mt-3 flex-display' key={topic.id}>
        {topic.isWorkbook && (
        <Link to={`/coursetopics/showstudentitems/${topic.id}`} className='mr-auto' >
          <h3 className='blue-color'>{topic.name}</h3>  
        </Link>
        )} 
        {!topic.isWorkbook && (
        <Link to={`/coursetopics/showitems/${topic.id}`} className='mr-auto' >
          <h3 className='blue-color'>{topic.name}</h3>  
        </Link>
        )}                
        </div>            
      )}      
    </div>
  );
}

export default observer(StudentClassForm)
