import { RootStore } from './rootStore';
import { observable, runInAction, action} from 'mobx';
import agent from '../api/agent';
import { ICourseContent} from '../models/course';
import { IStudentLesson } from '../models/studentLesson';

export default class CourseStore {
  rootStore: RootStore;    
  constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
  }
  @observable loading = false;
  @observable courseContent: ICourseContent | null = null;
  @observable studentReport: IStudentLesson[] = [];

  @action loadCourseStudentContent = async (courseCode: string) => {
    this.loading = true;
    try{
      var ct = await agent.Course.studentcontent(courseCode);
      runInAction(() => {
        this.courseContent = ct;
        this.loading = false;
      });
      return ct;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };

  @action loadCourseContent = async (courseCode: string) => {
    this.loading = true;
    try{
      var ct = await agent.Course.content(courseCode);
      runInAction(() => {
        this.courseContent = ct;
        this.loading = false;
      });
      return ct;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };
  
  @action saveDayOfTheWeek = async (courseCode: string, dayOfTheWeek: string) => {
    this.loading = true;
    try{
      await agent.Course.setDayOfTheWeek(courseCode, dayOfTheWeek);
      runInAction(() => {
        if(this.courseContent){
          this.courseContent.dayOfTheWeek = dayOfTheWeek;
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };

  @action saveTimePeriod = async (courseCode: string, timePeriod: string) => {
    this.loading = true;
    try{
      await agent.Course.setTimePeriod(courseCode, timePeriod);
      runInAction(() => {
        if(this.courseContent){
          this.courseContent.timePeriod = timePeriod;
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };

  @action saveNotice = async (courseCode: string, notice: string) => {
    this.loading = true;
    try{
      await agent.Course.setNotice(courseCode, notice);
      runInAction(() => {
        if(this.courseContent){
          this.courseContent.notice = notice;
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  }; 

  @action addCourseResource= async(courseCode: string, title: string, url: string, content: string) => {
    this.loading = true;
    try {
      var courseResource = await agent.Course.addResource(courseCode, title, url, content);
      runInAction(() => {
        this.loading = false;
        if(this.courseContent && this.courseContent.courseCode === courseCode)
        {
          this.courseContent.courseResources.push(courseResource);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action addCourseResourceAttach= async(courseCode: string, title: string, file: Blob) => {
    this.loading = true;
    try {
      var courseResource = await agent.Course.addResourceAttach(courseCode, title, file);
      runInAction(() => {
        this.loading = false;
        if(this.courseContent && this.courseContent.courseCode === courseCode)
        {
          this.courseContent.courseResources.push(courseResource.data);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action removeResource= async(courseCode: string, resourceId: string) => {
    this.loading = true;
    try {
      await agent.Course.removeResource(courseCode, resourceId);
      runInAction(() => {
        if(this.courseContent && this.courseContent.courseCode === courseCode)
        {
          this.courseContent.courseResources = this.courseContent.courseResources.filter(x=>x.id !== resourceId);
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  }; 

  @action addCourseLesson= async(courseCode: string, topic: string, date: Date, timePeriod: string) => {
    this.loading = true;
    try {
      var lesson = await agent.Course.addLesson(courseCode, topic, date, timePeriod);
      runInAction(() => {
        this.loading = false;
        if(this.courseContent && this.courseContent.courseCode === courseCode)
        {
          this.courseContent.lessons.push(lesson);
        }
        this.rootStore.modalStore.closeModal();
      });      
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });      
    }   
  };

  @action setResourceSeqNo = async(resourceId: string, seqNo: number) => {
    this.loading = true;
    try {
      await agent.Course.setResourceSeqNo(resourceId, seqNo);
      runInAction(() => {
        if (this.courseContent) {
          var resource = this.courseContent.courseResources.find(x=>x.id === resourceId);
          if(resource){
            resource.seqNo = seqNo;
          }
        }
        this.loading = false;
        this.rootStore.modalStore.closeModal();
      });      
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });        
    }
  }

  @action getStudentReport = async (courseCode: string, userName: string) => {
    this.loading = true;
    try{
      var ct = await agent.Course.studentReport(courseCode, userName);
      runInAction(() => {
        this.studentReport = ct;        
        this.loading = false;
      });
      return ct;
    } catch(error){
      runInAction(() => {
        this.loading = false;
      });          
    }      
  };  
}