export const ChallengingClassTree = 
[
  {
    courseId: 401,
    courseName: 'Senior Algorithm',
    courseLevel: 'Advanced',
    Recommendation: 'Recommended for kids ages 12+',
    Preview: 
        "Wanting a challenge? Prepare your child for Canadian Computing Competition (CCC) - Senior Level.",
    Description:
        " use a text document (?)",
    image: '/images/home/algorithm_2.png',
    link: '/algorithm-senior'
  },
  {
      courseId: 402,
      courseName: 'Math Challange',
      courseLevel: 'Advanced',
      Recommendation: 'Recommended for kids ages 12+',
      Preview: 
        `If you want to show your math talent, this course is right for you. Based on the math knowledge taught 
        in school, this course prepares you for the advanced math competitions such as COMC 
        (Canadian Open Mathematics Challenge)`,
      Description:
          " use a text document (?)",
      image: '/images/home/math_1.png',
      link: '/math-challange'
  },
];