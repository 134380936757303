import React from 'react'
import { Container, Form, Col, Row } from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";

const LastSubmitModal = ({studentRemark, grade, teacherRemark, code, attachment, attachmentUrl}) => {
  return (
    <Container>
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Remark:
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <div className="student-remark">
            <p className="mt-2 ml-2">{studentRemark}</p>
          </div>
        </Col>
      </Row>
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Teacher's Remark:
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <div className="teacher-remark">
            <p className="mt-2 ml-2">{teacherRemark}</p>
          </div>
        </Col>
      </Row>  
      {code && (
      <Row className="form-field">
        <Col lg={2} md={2} sm={2}>
          <Form.Label className="mt-3">
            Code 
            {grade === "Great" && <i className="fa fa-star fa-2x ml-3 mt-2 mb-2 text-primary"/>}           
            {grade === "Acceptable" && <i className="fa fa-check-circle fa-2x ml-3 mt-2 mb-2 text-info"/>}   
            {grade === "Problematic" && <i className="fa fa-bug fa-2x ml-3 mt-2 mb-2 text-warning"/>}
            {grade === "Unacceptable" && <span className="red-cross">X</span>}                            
          </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
          <Editor value={code}
            onValueChange = {() => {}}
            highlight={code => highlight(code, languages.js)}
            padding={5}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: 'aliceblue'
            }}
          />
        </Col>
      </Row>
      )}
      {attachment && (
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Attachment
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">{attachment}</a>            
          </Col>
        </Row>
      )}        
    </Container>
  )
}

export default LastSubmitModal
