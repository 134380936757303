import React, { useContext, useEffect, Fragment } from 'react';
import {
  RouteComponentProps,
  Route,
  withRouter,
  Switch
} from 'react-router-dom';
import { RootStoreContext } from '../stores/rootStore';
import { LoadingComponent } from './LoadingComponent';
import { ToastContainer } from 'react-toastify';
import ModalContainer from '../common/modals/ModalContainer';
import { HomePage } from '../../features/home/HomePage';
import { observer } from 'mobx-react-lite';
import { Courses } from '../../features/courses/Courses';
import NotFound from './NotFound';
import { Staff } from '../../features/staff/Staff';
import Achievements from '../../features/achievements/Achievements';
import AdminAchievements from '../../features/achievements/AdminAchievements';
import AdminAnnouncements from '../../features/announcements/AdminAnnouncements';
import { Contact } from '../../features/contact/Contact';
import PrivateRoute from './PrivateRoute';
import UsersForm from '../../features/users/UsersForm';
import UserForm from '../../features/users/UserForm';
import ClassesForm from '../../features/classes/ClassesForm';
import NavBar from '../../features/nav/NavBar';
import NumberPuzzleRanks from '../../features/numberPuzzles/NumberPuzzleRanks';
import { NumberPuzzleSolve } from '../../features/numberPuzzles/NumberPuzzleSolve';
import ProfileForm from '../../features/user/ProfileForm';
import SubjectsForm from '../../features/subject/SubjectsForm';
import AdminClassesForm from '../../features/adminClasses/AdminClassesForm';
import AdminClassForm from '../../features/adminClasses/AdminClassForm';
import EditClassForm from '../../features/adminClasses/EditClassForm';
import NewClassForm from '../../features/adminClasses/NewClassForm';
import ClassAssistants from '../../features/adminClasses/ClassAssistants';
import ClassStudents from '../../features/adminClasses/ClassStudents';
import AdminClassInfo from '../../features/adminClasses/AdminClassInfo';
import TeacherClassesForm from '../../features/teacherClasses/TeacherClassesForm';
import TeacherClassInfo from '../../features/teacherClasses/TeacherClassInfo';
import TeacherClassForm from '../../features/teacherClasses/TeacherClassForm';
import AssistantClassesForm from '../../features/assistants/AssistantClassesForm';
import AssistantClassForm from '../../features/assistants/AssistantClassForm';
import AssistantTopicsForm from '../../features/assistants/AssistantTopicsForm';
import AssistantTopicForm from '../../features/assistants/AssistantTopicForm';
import PythonProblemDashboard from '../../features/pythonProblems/dashboard/PythonProblemDashboard';
import PythonProblemSolution from '../../features/pythonProblems/solution/PythonProblemSolution';
import PythonProblemForm from '../../features/pythonProblems/form/PythonProblemForm';
import PythonTestCaseList from '../../features/pythonTestCases/PythonTestCaseList';
import { PythonTestCaseForm } from '../../features/pythonTestCases/PythonTestCaseForm';
import AttendanceForm from '../../features/lesson/AttendanceForm';
import AssistantAttendanceForm from '../../features/lesson/AssistantAttendanceForm';
import AssignmentContentForm from '../../features/assignments/AssignmentContentForm';
import AdminAssignmentForm from '../../features/assignments/AdminAssignmentForm';
import AssistantAssignmentForm from '../../features/assignments/AssistantAssignmentForm';
import StudentClassesform from '../../features/studentClasses/StudentClassesform';
import StudentClassForm from '../../features/studentClasses/StudentClassForm';
import ParentClassesform from '../../features/parentClasses/ParentClassesForm'
import StudentAssignmentForm from '../../features/assignments/StudentAssignmentForm';
import InquiriesForm from '../../features/users/InquiriesForm';
import StudentSubmissions from '../../features/lesson/StudentSubmissions';
import TeacherLessonContentForm from '../../features/lesson/TeacherLessonContentForm';
import AssistantLessonContentForm from '../../features/lesson/AssistantLessonContentForm';
import StudentLessonContentForm from '../../features/lesson/StudentLessonContentForm';
import AdminLessonContentForm from '../../features/lesson/AdminLessonContentForm';
import TeacherQAForm from '../../features/qa/TeacherQAForm';
import AssistantQAForm from '../../features/qa/AssistantQAForm';
import StudentQAForm from '../../features/qa/StudentQAForm';
import TeacherQuestionForm from '../../features/qa/TeacherQuestionForm';
import AssistantQuestionForm from '../../features/qa/AssistantQuestionForm';
import StudentQuestionForm from '../../features/qa/StudentQuestionForm';
import TopicsForm from '../../features/topics/TopicsForm';
import NewTopicForm from '../../features/topics/NewTopicForm';
import TopicForm from '../../features/topics/TopicForm';
import SetCourseTopicsForm from '../../features/teacherClasses/SetCourseTopicsForm';
import SetCourseTopicItems from '../../features/teacherClasses/SetCourseTopicItems';
import ShowCourseTopicItems from '../../features/topics/ShowCourseTopicItems';
import ShowStudentCourseTopicItems from '../../features/topics/ShowStudentCourseTopicItems';
import ShowTeacherCourseTopicItems from '../../features/topics/ShowTeacherCourseTopicItems';
import QuestionsForm from '../../features/qa/QuestionsForm';
import TAQuestionsForm from '../../features/qa/TAQuestionsForm';
import TopicItemDiscussForm from '../../features/topics/TopicItemDiscussForm';
import TopicItemDiscussTeacherForm from '../../features/topics/TopicItemDiscussTeacherForm';
import DiscussionsForm from '../../features/discussions/DiscussionsForm';
import CourseDiscussionsForm from '../../features/discussions/CourseDiscussionsForm';
import AssignmentDiscussForm from '../../features/assignments/AssignmentDiscussForm';

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token]);

  if (!appLoaded) return <LoadingComponent content="loading app..." />;

  const children = (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/courses" component={Courses} />      
        <Route exact path="/classes" component={ClassesForm}/>
        <Route exact path="/staff" component={Staff} />
        <Route exact path="/achievements" component={Achievements}/>  
        <Route exact path="/a-achievements" component={AdminAchievements}/>          
        <Route exact path="/a-announcements" component={AdminAnnouncements}/>     
        <Route exact path="/contact" component={Contact} />
        <PrivateRoute exact path="/pythonproblems" component={PythonProblemDashboard} />
        <PrivateRoute path="/pythonproblems/:id" component={PythonProblemSolution} />
        <PrivateRoute key={location.key} 
          path={['/newpythonproblem', '/editpythonproblem/:id']}
          component={PythonProblemForm}/>
        <PrivateRoute path="/pythontestcases/:id" component={PythonTestCaseList} />
        <PrivateRoute key={location.key}
          path={['/newpythontestcase/:probId', '/editpythontestcase/:probId/:caseId']}
          component={PythonTestCaseForm} />        
        <PrivateRoute exact path="/numberpuzzle/ranks" component={NumberPuzzleRanks}/>
        <PrivateRoute exact path="/numberpuzzle/solve" component={NumberPuzzleSolve}/>
        <PrivateRoute exact path="/users" component={UsersForm} />
        <PrivateRoute exact path="/subjects" component={SubjectsForm}/>
        <PrivateRoute exact path="/a-classes" component={AdminClassesForm}/>
        <PrivateRoute exact path="/a-classes/newclass" component={NewClassForm}/> 
        <PrivateRoute exact path="/a-classes/info/:courseCode" component={AdminClassInfo}/>  
        <PrivateRoute exact path="/a-classes/editclass/:courseCode" component={EditClassForm}/>
        <PrivateRoute exact path="/a-classes/classassistants/:courseCode" component={ClassAssistants}/>        
        <PrivateRoute exact path="/a-classes/classstudents/:courseCode" component={ClassStudents}/>
        <PrivateRoute exact path="/a-classes/:courseCode" component={AdminClassForm}/>   
        <PrivateRoute exact path="/t-classes" component={TeacherClassesForm}/>
        <PrivateRoute exact path="/t-classes/:courseCode" component={TeacherClassForm}/>
        <PrivateRoute exact path="/h-classes" component={AssistantClassesForm}/>
        <PrivateRoute exact path="/h-classes/:courseCode" component={AssistantClassForm}/>        
        <PrivateRoute exact path="/users/profile" component={ProfileForm} />
        <PrivateRoute exact path="/users/:userName" component={UserForm} />
        <PrivateRoute exact path="/lesson/attendance/:lessonId" component={AttendanceForm}/>
        <PrivateRoute exact path="/lesson/h-attendance/:lessonId" component={AssistantAttendanceForm}/>     
        <PrivateRoute exact path="/lesson/a-assignment/:lessonId" component={AdminAssignmentForm}/>
        <PrivateRoute exact path="/lesson/h-assignment/:lessonId" component={AssistantAssignmentForm}/>       
        <PrivateRoute exact path="/lesson/a-content/:lessonId" component={AdminLessonContentForm}/>
        <PrivateRoute exact path="/lesson/assignment/:lessonId" component={AssignmentContentForm}/>  
        <PrivateRoute exact path="/lesson/t-content/:lessonId" component={TeacherLessonContentForm}/>
        <PrivateRoute exact path="/lesson/h-content/:lessonId" component={AssistantLessonContentForm}/>        
        <PrivateRoute exact path="/s-classes" component={StudentClassesform}/>   
        <PrivateRoute exact path="/s-classes/:courseCode" component={StudentClassForm}/>
        <PrivateRoute exact path="/p-classes" component={ParentClassesform}/>         
        <PrivateRoute exact path="/lesson/s-assignment/:lessonId" component={StudentAssignmentForm}/> 
        <PrivateRoute exact path="/lesson/s-content/:lessonId" component={StudentLessonContentForm}/>
        <PrivateRoute exact path="/inquiries" component={InquiriesForm} />
        <PrivateRoute exact path="/lesson/problem/:problemId" component={StudentSubmissions}/>
        <PrivateRoute exact path="/t-class/info/:courseCode" component={TeacherClassInfo}/>        
        <PrivateRoute exact path="/t-class/qa/:courseCode" component={TeacherQAForm}/>
        <PrivateRoute exact path="/h-class/qa/:courseCode" component={AssistantQAForm}/>    
        <PrivateRoute exact path="/s-class/qa/:courseCode" component={StudentQAForm}/>        
        <PrivateRoute exact path="/t-class/question/:questionId" component={TeacherQuestionForm}/>
        <PrivateRoute exact path="/h-class/question/:questionId" component={AssistantQuestionForm}/>        
        <PrivateRoute exact path="/s-class/question/:questionId" component={StudentQuestionForm}/>
        <PrivateRoute exact path="/topics" component={TopicsForm}/>
        <PrivateRoute exact path="/topics/newtopic" component={NewTopicForm}/>
        <PrivateRoute exact path="/topics/:topicId" component={TopicForm}/>
        <PrivateRoute exact path="/h-topics" component={AssistantTopicsForm} />
        <PrivateRoute exact path="/h-topics/:topicId" component={AssistantTopicForm}/>
        <PrivateRoute exact path="/t-class/set-course-topics/:courseCode" component={SetCourseTopicsForm}/> 
        <PrivateRoute exact path="/coursetopics/setitems/:courseTopicId/:topicId" component={SetCourseTopicItems}/>
        <PrivateRoute exact path="/coursetopics/showitems/:courseTopicId" component={ShowCourseTopicItems}/>  
        <PrivateRoute exact path="/coursetopics/showstudentitems/:courseTopicId" component={ShowStudentCourseTopicItems}/> 
        <PrivateRoute exact path="/coursetopics/showteacheritems/:courseTopicId" component={ShowTeacherCourseTopicItems}/>         
        <PrivateRoute exact path="/questions" component={QuestionsForm}/>
        <PrivateRoute exact path="/h-questions" component={TAQuestionsForm}/>
        <PrivateRoute exact path="/coursetopics/discuss/:courseTopicId/:topicItemId/:title" component={TopicItemDiscussForm}/>
        <PrivateRoute exact path="/coursetopics/t-discuss/:courseTopicId/:topicItemId/:title" component={TopicItemDiscussTeacherForm}/>        
        <PrivateRoute exact path="/discussions/:courseTopicId" component={DiscussionsForm}/>
        <PrivateRoute exact path="/coursediscussions/:courseId" component={CourseDiscussionsForm}/>  
        <PrivateRoute exact path="/lesson/discuss/:assignmentId/:title" component={AssignmentDiscussForm} />   
        <Route component={NotFound} />
      </Switch>
    </>
  )

  return (
    <Fragment>
      <ModalContainer  />
      <ToastContainer position="bottom-right" />

      <NavBar children={children} />
    </Fragment>
  );
};

export default withRouter(observer(App));
