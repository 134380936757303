import React, {useContext, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Row, Col, Form, Table, Button} from 'react-bootstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import StudentReportModal from '../adminClasses/StudentReportModal';
import "../classes.css";
interface ClassParams {
  courseCode: string;
}

const TeacherClassInfo: React.FC<RouteComponentProps<ClassParams>> = ({
  match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {courseContent, loadCourseContent} = rootStore.courseStore;  
  const { openModal} = rootStore.modalStore;  

  useEffect(() => {
    loadCourseContent(match.params.courseCode);
  }, [loadCourseContent, match.params.courseCode])

  const getLevel = () => {
    if(courseContent){
      switch(courseContent.subject!.level){
        case 2:
          return 'Intermediate';
        case 3:
          return 'Advanced';
        case 4:
          return 'Challenging';
        default:
          return 'Beginner';  
      }      
    }
    else return '';
  };

  return (
    <div className="content">
      <div style={{display: 'flex'}}>
        <h2 className={`ml-3 mr-auto ${courseContent?.active ? "active-title" : "inactive-title"}`}>Course: {courseContent?.courseName}</h2>
        <Button className="mr-3" 
          onClick={() => history.goBack()}
        >Back to Class</Button>
      </div>

      <Form className="mt-3">
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Name
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseContent?.subject?.name} readOnly>            
          </Form.Control>
          </Col>
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Subject Code
                </Form.Label>
          </Col>
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={courseContent?.subject?.code} readOnly>            
          </Form.Control>
          </Col>
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
                <Form.Label className="mt-3">
                  Level
                </Form.Label>
          </Col> 
          <Col lg={4} md={4} sm={4}>
          <Form.Control type="text" value={getLevel()} readOnly>            
          </Form.Control>
          </Col> 
          <Col lg={2} md={2} sm={2}>
                <Form.Check type="checkbox" label="Active" checked={courseContent?.active} 
                name="active" readOnly/>
              </Col>           
        </Row>
        <Row className="form-field">
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Course Code
            </Form.Label>
            </Col>
            <Col lg={4} md={4} sm={4}>
            <Form.Control
              type="text"
              value = {courseContent?.courseCode}
              readOnly
            />
          </Col>
          <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Session
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent?.session}
                readOnly
              />
              </Col>          
        </Row>         
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Start Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent ? new Date(courseContent.startDate).toISOString().substring(0, 10) : ''}
                readOnly
              />
              </Col>
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                End Date
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent? new Date(courseContent.endDate).toISOString().substring(0, 10) : ''}
                readOnly
              />
              </Col>
          </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Day of the week
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent?.dayOfTheWeek || ''}
                readOnly
              />
              </Col> 
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Time period
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent?.timePeriod || ''}
                readOnly
              />
              </Col>                          
        </Row>  
        <Row className="form-field">
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                # of Lessons
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control
                type="text"
                value = {courseContent?.numOfLessons}
                readOnly
              />
              </Col> 
              <Col lg={2} md={2} sm={2}>
              <Form.Label className="mt-3">
                Teacher
              </Form.Label>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <Form.Control 
                type="text"
                value = {courseContent?.teacher?.firstName + ' ' + courseContent?.teacher?.lastName}
                readOnly
              />
              </Col>                                            
        </Row> 
        <Row>
          <Col lg={2} md={2} sm={2}>
            <Form.Label className="mt-3">
              Description
            </Form.Label>
          </Col>
          <Col lg={10} md={10} sm={10}>
              <Form.Control as="textarea" rows={5} className="full-control" value={courseContent?.description} readOnly
              >                
              </Form.Control>
          </Col>   
        </Row>                                                                
      </Form>
      <div className='mt-5' style={{display: 'flex'}}>
        <h3 className='mr-auto'>Assistants</h3>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.assistants
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })
          .map((assistant, index) => 
            <tr key={assistant.userName}>
            <td>{index+1}</td>
            <td>{assistant.firstName+' ' + assistant.lastName}</td>
            <td>{assistant.email}</td>
          </tr>
          )}       
        </tbody>
      </Table>       
      <div className='mt-5' style={{display: 'flex'}}>
        <h3 className='mr-auto'>Students</h3>
      </div>
      <Table striped bordered hover className="mb-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Age</th>
            <th>Parent</th>
            <th>Phone</th>
            <th>Wechat</th>
            <th>City</th>
            <th>Email</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody>
          {courseContent?.students
          .slice().sort((a, b) => {
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            else if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
            else {
                if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                else return -1;
            }
          })
          .map((student, index) => 
            <tr key={student.userName}>
            <td>{index+1}</td>
            <td>{student.firstName+' ' + student.lastName}</td>
            <td>{student.age}</td>
            <td>{(student.parentFirstName == null ? '' : student.parentFirstName) + ' ' 
                  + (student.parentLastName == null ? '' : student.parentLastName)}</td>
            <td>{student.phone}</td>
            <td>{student.wechat}</td>
            <td>{student.city}</td>
            <td>{student.email}</td>
            <td>
            <i className="fa fa-file fa-2x ml-2 text-primary clickable"
            onClick={() => openModal(`report of ${student.firstName} ${student.lastName}`, <StudentReportModal courseCode={courseContent.courseCode} userName={student.userName}/>, 'modal-pane-1')}></i>              
            </td>            
          </tr>
          )}       
        </tbody>
      </Table>
    </div>
  );
}

export default observer(TeacherClassInfo)
