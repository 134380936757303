import React, { useContext, useEffect} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Table} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {convertUTCDateToLocalDataString } from '../helper/DateHelper';
import "./qa.css";

const QuestionsForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {questions, getQuestions} = rootStore.qaStore;

  useEffect(() => {
    const loadValues = async() => {
      await getQuestions();
    }
    loadValues();
  }, [getQuestions])

  return (
    <div className="qa-page mt-3 p-3">
      <Table striped bordered className="mt-3">
        <thead>
          <tr>
            <th>Course</th>
            <th>Student</th>
            <th>Date</th>
            <th>Private?</th>
            <th>Topic</th>
            <th>Last Replied</th>
            <th>Replied By</th> 
            <th>Viewed</th>                   
          </tr>
        </thead>
        <tbody>
        {questions.slice().sort((a, b) => a.date < b.date ? 1 : -1)
        .map((question, index) => 
        <tr key={index}>
          <td>{question.courseName}</td>
          <td>{question.userFullName}</td>
          <td>{convertUTCDateToLocalDataString(question.date)}</td>
          <td>{question.isPrivate ? "Yes" : "No"}</td>
          <td>
            <Link to={`/t-class/question/${question.questionId}`}>
            {question.topic}
            </Link>
          </td>
          <td>
            {question.lastDateReplied ? convertUTCDateToLocalDataString(question.lastDateReplied) : ""}
          </td>
          <td>
            {question.lastReplier}
          </td>  
          <td>
            {question.viewed ? <i className="fa fa-check ml-3 mt-2 mb-2 text-info"/> : ''}
          </td>                  
        </tr> 
        )}         
        </tbody>
      </Table>    
    </div>
  )
};

export default observer(QuestionsForm);
