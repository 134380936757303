import React from 'react';
import HomeBanner from './HomeBanner';
import { Container } from 'react-bootstrap';
import Announcements from './Announcements';
import HomeAbout from './HomeAbout';
import "./home.css";

export const HomePage = () => {
  return (
    <Container fluid className="home"> 
      <HomeBanner />
      <Announcements />
      <HomeAbout />
  </Container>
  );
};
